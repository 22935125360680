import styled from 'styled-components'

export const Generic = styled.div`
  width: 358px;
  height: ${(props) => (props.closable ? 'auto' : '297px')};
  background-color: #414141;
  border-radius: 15px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: #e5e5e5;
  }
`

export const Wrapper = styled.div`
  flex: 1 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.1;
  color: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > button {
    width: 100%;

    &:nth-child(2) {
      border-left-color: #414141;
    }
  }
`

export const Button = styled.button`
  background-color: ${(props) =>
    props.danger ? '#9B4141' : 'rgba(90, 89, 89, 0.84)'};
  height: 54px;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.1;
  color: #e5e5e5;
  transition: background-color 0.25s ease-in, color 0.25s ease-in;

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    border-top-color: rgba(230, 230, 230, 0.09);
    color: rgba(229, 229, 229, 0.19);
  }
`
