import styled from 'styled-components'

export const Inner = styled.div`
  max-height: calc(100vh - 75px);
  width: 100%;
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @media screen and (max-width: 1280px) {
    padding: 27px 0;
  }
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 30px;
  line-height: 1.1;
  color: #e5e5e5;
  text-transform: uppercase;
  text-align: center;
  margin: 24px 0;

  @media screen and (max-width: 1280px) {
    font-size: 22.5px;
    margin: 18px 0;
  }
`

export const ContentWrapper = styled.div`
  flex: 1 0;
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
`

export const Line = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  text-align: justify;
  color: #e5e5e5;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`
