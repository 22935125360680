const getStorageLocation = () => {
  if (
    Object.keys(sessionStorage).some((key) => key.includes('firebase:authUser'))
  ) {
    const key = Object.keys(sessionStorage).find((key) =>
      key.includes('firebase:authUser')
    )
    return JSON.parse(sessionStorage.getItem(key)).isAnonymous
      ? window.localStorage
      : window.sessionStorage
  }
  return window.localStorage
}

export default getStorageLocation
