import { createStore } from 'redux'
import reducer from '../reducers'

const initialState = {
  lang: 'en',
  currentUser: null,
  renderPopup: false,
  isUserLoggedIn: null,
  pageIndex: 1,
  pagesLength: 6,
  pipeSizeInput: {
    pipeSizeIn: {
      isActive: null,
      currentValue: null,
      isValid: null,
    },
    pipeSizeMm: {
      isActive: null,
      currentValue: null,
      isValid: null,
    },
  },
  deviceWidth: null,
  helper: {
    parentPosition: null,
    helperPosition: null,
    current: null,
    shouldRender: false,
  },
  showDropdownScreen: false,
  roofPitchDropdown: {
    isOpen: false,
    currentValue: null,
  },
  query: {
    pipeSizeMm: null,
    pipeSizeIn: null,
    pipeType: null,
    roofType: null,
    roofPitch: null,
    weatherProtection: null,
    applicationType: null,
  },
  currentQuery: {},
  isRepeatingSearch: false,
  logCardId: null,
  isLoading: null,
  result: {},
  errorMessage: '',
  images: {},
  imagesCount: 0,
  deviceType: '',
}

export const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
