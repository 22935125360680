// This component can be used to display larger messages and other complex stuff (like instructions
// or the pipe shape images). Text blocks inside this component can have scrollbars. This component is
// activated when the user hovers over some text that is wrapped by the Tooltip.js component. Get
// to Tooltip.js if you want to understand better how this works.

import React, { useRef } from 'react'
import './Tooltip.css'
import { CSSTransition } from 'react-transition-group'
import { store } from '../../store'
import { shouldRenderHelper, setCurrentHelper } from '../../actions'

const Helper = ({ content, id }) => {
  const ref = useRef()
  const handleClickOutside = (e) => {
    if (ref.current.contains(e.target)) return
    store.dispatch(shouldRenderHelper())
    store.dispatch(setCurrentHelper(null))
    document.removeEventListener('mousedown', handleClickOutside)
  }
  const handleEnter = () =>
    document.addEventListener('mousedown', handleClickOutside)
  const setCondition = () => {
    if (store.getState().deviceWidth > 768) {
      return store.getState().helper.current === id
    }
    return (
      store.getState().helper.shouldRender &&
      store.getState().helper.current === id
    )
  }

  return (
    <span className='bubble-wrapper bubble-helper' ref={ref}>
      <CSSTransition
        in={setCondition()}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames='tool'
        onEntered={store.getState().deviceWidth <= 768 ? handleEnter : null}
      >
        <span className='bubble bubble-helper-inner'>
          <span className='bubble-inner'>
            <span className={`${id === 'shape' ? 'shape-' : 'text-'}wrapper`}>
              {typeof content === 'string' ? (
                <span>{content}</span>
              ) : (
                content.map((text, i) =>
                  id === 'shape' ? text : <span key={`line-${i}`}>{text}</span>
                )
              )}
            </span>
          </span>
        </span>
      </CSSTransition>
    </span>
  )
}

export default Helper
