import React from 'react'
import { CardWrapper } from '../../../components/PageCard/styles'
import PageCard from '../../../components/PageCard'
import ComponentWrapper from '../../../components/PageComponent/ComponentWrapper'
import { i18n } from '../../../i18n'

const WeatherProtectionScreen = () => {
  const { title, text, labelEPDM, labelSilicone } = i18n().weatherProtection

  return (
    <ComponentWrapper title={title} textLine={text}>
      <CardWrapper>
        <PageCard
          img='protection-epdm'
          label={labelEPDM}
          name='weatherProtection'
          value='isEpdm'
        />
        <PageCard
          img='protection-silicone'
          label={labelSilicone}
          name='weatherProtection'
          value='isSilicone'
        />
      </CardWrapper>
    </ComponentWrapper>
  )
}

export default WeatherProtectionScreen
