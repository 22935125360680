import React, { useEffect } from 'react'
import { Results, ResultsWrapper, TitleArea, ResultsArea } from './styles'
import Loading from '../../../components/Loading'
import NoResults from './NoResults'
import ResultCard from '../../../components/ResultCard'
import { store } from '../../../store'
import {
  searchFlashings,
  logEvents,
} from '../../../services/firebase/firestore'
import { i18n } from '../../../i18n'

const ResultsScreen = () => {
  const { title, success } = i18n().results

  useEffect(() => {
    const { query, currentUser } = store.getState()
    searchFlashings(query, currentUser && currentUser.uid)
    logEvents(query)
  }, [])

  const { result, isLoading } = store.getState()

  const returnResultsComponent = () => {
    if (isLoading === false && result.length === 0) {
      return <NoResults />
    }
    return result.length
      ? result.map((prod) => <ResultCard key={prod.uid} data={prod} />)
      : null
  }

  return (
    <Results>
      <ResultsWrapper>
        {result.length > 0 && (
          <TitleArea>
            <p>{title}</p>
            <p>{success}</p>
          </TitleArea>
        )}
        <ResultsArea isEmpty={result.length === 0}>
          {isLoading ? <Loading full={false} /> : returnResultsComponent()}
        </ResultsArea>
      </ResultsWrapper>
    </Results>
  )
}

export default ResultsScreen
