import styled from 'styled-components'

export const Dropdown = styled.div`
  background-color: #414141;
  border-radius: ${(props) => (props.open ? '2px 2px 0 0' : '2px')};
  border-bottom: ${(props) => (props.open ? 'none' : 'auto')};
  width: 100%;
  height: 100%;
  padding: 20px 13px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 1280px) {
    padding: 15px 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 13px;
  }

  & > span {
    flex: 1 0 auto;
  }

  & > svg {
    width: 22px;
    height: auto;
    transform: ${(props) => (props.open ? 'rotate(-180deg)' : 'rotate(0)')};
    transition: transform 0.15s ease-in;

    @media screen and (max-width: 1280px) {
      width: 16px;
    }

    @media screen and (max-width: 768px) {
      width: 22px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.open ? '#3C3B3B' : 'transparent')};
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 20px 20px 0 0;
  }
`

export const DropdownWrapper = styled.div`
  width: 207px;
  height: 41px;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.1;
  color: #e5e5e5;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    width: 155px;
    height: 30px;
    font-size: 11px;
  }

  @media screen and (max-width: 768px) {
    width: 207px;
    height: 41px;
    font-size: 15px;
  }
`

export const List = styled.div`
  position: absolute;
  top: 41px;
  width: 100%;
  height: 130px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 2px 2px;
  overflow: hidden;
  background-color: #3c3b3b;

  @media screen and (max-width: 1280px) {
    top: 30px;
  }

  @media screen and (max-width: 768px) {
    top: 41px;
  }

  ul {
    list-style: none;
    overflow: auto;
    height: inherit;
    width: inherit;
  }
`

export const ListItem = styled.li`
  background-color: #3c3b3b;
  padding: 0px 13px;
  height: 41px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(229, 229, 229, 0.2);

  @media screen and (max-width: 1280px) {
    padding: 0 10px;
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 13px;
    height: 41px;
  }

  &:nth-child(1) {
    border-top: none;
  }

  &:hover {
    background-color: #767575;
  }
`
