import styled from 'styled-components'

export const Area = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isLoggedIn ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: #414141;
  box-shadow: ${(props) =>
    props.active ? '1px 1px 8px 1px rgba(26, 25, 25, 0.3)' : 'none'};
  border-radius: ${(props) => (props.active ? '4px' : '0')};

  @media screen and (max-width: 1280px) {
    padding: 10px;
  }

  & > div {
    width: 100%;
    font-size: 15px;

    @media screen and (max-width: 1280px) {
      font-size: 11.25px;
    }

    @media screen and (min-width: 1600px) {
      font-size: 18px;
    }
  }

  #upper {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    cursor: pointer;

    & > div {
      flex-shrink: 0;
      width: 36px;
      height: 36px;
      border: 1px solid rgba(229, 229, 229, 0.52);
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1280px) {
        width: 27px;
        height: 27px;
      }

      div.blank-avatar {
        font-size: 18px;

        @media screen and (max-width: 1280px) {
          font-size: 13px;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    span {
      margin: 0 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .caret {
      transition: transform 0.05s linear;
      transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0)')};
      width: 20px;
      height: 20px;

      @media screen and (min-width: 1600px) {
        width: 24px;
        height: 24px;
      }

      @media screen and (max-width: 1280px) {
        width: 15px;
        height: 15px;
      }
    }
  }

  #lower {
    display: ${(props) => (props.active ? 'flex' : 'none')};
    flex-direction: column;
    margin-top: 14px;

    @media screen and (max-width: 1280px) {
      margin-top: 10px;
    }

    hr {
      margin-bottom: 20px;

      @media screen and (max-width: 1280px) {
        margin-bottom: 15px;
      }
    }

    & > a {
      color: #e5e5e5;
      font-family: Roboto;
      font-weight: 300;
      line-height: 1.1;
      align-self: flex-start;
      display: inline-flex;
      opacity: 0.5;
      text-decoration: none;
      outline: none;

      &:hover,
      &.active-menu {
        opacity: 1;
      }

      &:nth-last-child(2) {
        margin: 10px 0 15px;

        @media screen and (max-width: 1280px) {
          margin: 7.5px 0 11.25px;
        }
      }

      svg {
        height: 15px;
        width: 15px;

        @media screen and (max-width: 1280px) {
          height: 11.25px;
          width: 11.25px;
        }

        @media screen and (min-width: 1600px) {
          height: 18px;
          width: 18px;
        }
      }

      span {
        margin-left: 8px;
      }
    }

    #logout {
      background-color: #343434;
      border: 1px solid #343434;
      border-radius: 4px;
      align-self: center;
      color: #e5e5e5;
      opacity: 0.5;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.1;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      padding: 8px 16px;
      cursor: pointer;

      @media screen and (max-width: 1280px) {
        font-size: 9px;
        padding: 6px 12px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 16px;
      }

      &:hover {
        opacity: 1;
      }

      svg {
        height: 14px;
        width: 14px;

        @media screen and (max-width: 1280px) {
          height: 10px;
          width: 10px;
        }

        @media screen and (min-width: 1600px) {
          height: 16px;
          width: 16px;
        }
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .signin-button {
    color: inherit;
    font-weight: 400;
    line-height: 1.1;
    cursor: pointer;
    border: 0;
    white-space: nowrap;

    &:nth-child(1) {
      font-size: 16px;
      background-color: transparent;

      @media screen and (max-width: 1280px) {
        font-size: 12px;
      }
    }

    &:nth-child(2) {
      font-size: 15px;
      background: rgba(229, 229, 229, 0.4);
      border-radius: 4px;
      padding: 8px 16px;
      margin-left: 30px;

      @media screen and (max-width: 1280px) {
        font-size: 12px;
        margin-left: 20px;
      }
    }
  }
`

export const AuthWrapper = styled.div`
  max-width: ${({ isLoggedIn }) => (isLoggedIn ? '185px' : 'unset')};
  /* max-width: 240px;
    width: 100%; */
  /* flex: 1 1; */
  align-self: flex-start;
  margin-top: 5.5px;
  margin-left: 6px;

  & > div {
    position: relative;
    /* position: absolute;
        top: -32px;
        left: 0;
        right: 0; */
    z-index: 3;
  }
`
