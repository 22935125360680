import styled from 'styled-components'

export const Input = styled.input`
  width: 193px;
  height: 64px;
  background: #414141;
  border-radius: 4px;
  outline: none;
  border: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.1;
  color: #e5e5e5;
  text-align: center;
  font-family: inherit;

  @media screen and (max-width: 1280px) {
    width: 145px;
    height: 48px;
    font-size: 16px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InputLabel = styled.label`
  font-size: 25px;
  line-height: 1.1;
  color: #e5e5e5;
  margin-bottom: 6px;
  font-weight: 400;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
    margin-bottom: 4px;
  }
`
