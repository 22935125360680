import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 75px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  @media screen and (min-width: 1440px) {
    padding-top: 110px;
  }

  @media screen and (max-width: 1280px) {
    top: 56px;
    padding-top: 40px;
  }

  @media screen and (max-width: 768px) {
    bottom: 60px;
    left: 30px;
    right: 30px;
  }
`

export const List = styled.ul`
  padding-right: 18px;
  width: 270px;

  @media screen and (max-width: 1280px) {
    padding-right: 15px;
    width: 202px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const Line = styled.div`
  height: 100%;
  width: 3px;
  background: linear-gradient(
    180deg,
    rgba(87, 87, 87, 0) 0%,
    #575757 29.38%,
    #575757 69.56%,
    rgba(87, 87, 87, 0) 100%
  );
  position: absolute;
  top: 0;
  right: 22.5px;

  @media screen and (max-width: 1280px) {
    right: 17.5px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavigationButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 26px 40px 0 0;

  @media screen and (max-width: 1280px) {
    margin: 18px 30px 0 0;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    bottom: 0;
    margin: 0;
    width: 100%;
    justify-content: space-around;
  }

  & > button {
    &:nth-last-child(1) {
      margin-left: 10px; // arbitrary

      @media screen and (max-width: 1280px) {
        margin-left: 6px;
      }
    }
  }
`
