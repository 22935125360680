import styled from 'styled-components'

export const Button = styled.button`
  height: 40px;
  border: 3px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 15px;
  line-height: 1.1;
  color: #c4c4c4;
  background-color: transparent;
  cursor: pointer;
  display: block;
  outline: none;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in,
    color 0.1s ease-in;

  @media screen and (min-width: 1440px) {
    height: 60px;
  }

  @media screen and (max-width: 1280px) {
    height: 30px;
  }

  &:hover {
    background-color: #414141;
    border-color: #767575;
  }

  &:active {
    background-color: #282727;
    border-color: rgba(229, 229, 229, 0.7);
  }

  &:disabled {
    border-color: rgba(229, 229, 229, 0.3);
    color: rgba(229, 229, 229, 0.3);
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
      border-color: rgba(229, 229, 229, 0.3);
      color: rgba(229, 229, 229, 0.3);
    }
  }
`
