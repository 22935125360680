import styled from 'styled-components'

export const ResultCard = styled.div`
  width: 250px;
  height: 250px;
  background-color: #414141;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 16px 21px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    width: 187px;
    height: 187px;
    padding: 12px 15.75px;
  }

  & > svg {
    position: absolute;
    color: #c4c4c4;
    width: 25px;
    height: 25px;
    bottom: 26px;
    right: 22px;
    fill: ${(props) => (props.isFavorite ? '#C4C4C4' : 'none')};

    @media screen and (max-width: 1280px) {
      height: 18px;
      width: 18px;
      bottom: 19.5px;
      right: 16.5px;
    }
  }
`

export const ImageWrapper = styled.div`
  height: 163px;
  width: 208px;
  align-self: center;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1280px) {
    height: 122px;
    width: 156px;
  }

  img {
    height: auto;
    max-height: 163px;
    max-width: 208px;
    display: block;
    margin: 0 auto;

    @media screen and (max-width: 1280px) {
      max-height: 122px;
      max-width: 156px;
    }
  }
`

export const InfoWrapper = styled.div`
  margin-top: 11px;
  width: 100%;
  max-width: 180px;

  @media screen and (max-width: 1280px) {
    margin-top: 8px;
    max-width: 135px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: #e5e5e5;
    display: block;
  }
`

export const CardTitle = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.1;

  @media screen and (max-width: 1280px) {
    font-size: 15px;
  }
`

export const CardSKU = styled.span`
  line-height: 1.1;
  font-weight: 300;
  font-size: 16px;

  @media screen and (max-width: 1280px) {
    font-size: 12px;
  }
`
