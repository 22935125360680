import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 36px;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr calc(100vh - 192px);

  @media screen and (max-width: 1280px) {
    padding-top: 27px;
    grid-template-rows: 1fr calc(100vh - 144px);
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.1;
    color: #e5e5e5;
    text-transform: uppercase;
    text-align: center;
    margin: 24px 0;

    @media screen and (max-width: 1280px) {
      font-size: 22.5px;
      margin: 18px 0;
    }
  }

  #scrollableDiv {
    overflow: auto;
    position: relative;

    & > button {
      position: absolute;
      top: 0;
      right: 27px;
      width: 55px;
      height: 55px;
      background-color: #414141;
      border-radius: 10px;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
    }
  }

  .listArea {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-template-rows: repeat(auto-fill, 250px);
    gap: 32px 21px;
    justify-content: center;
    overflow: hidden;
    max-width: 1068px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(auto-fill, 187px);
      grid-template-rows: repeat(auto-fill, 187px);
      gap: 24px 15.75px;
      max-width: 820px;
    }

    & > p {
      font-weight: 300;
      font-size: 20px;
      line-height: 1.1;
      color: #e5e5e5;
    }
  }
`

export const NoMoreResults = styled.p`
  grid-column: 1 / -1;
  text-align: center;
  margin-bottom: 15.75px;
`
