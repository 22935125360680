import styled from 'styled-components'
import Button from '../../components/Button'

import trianglesBackground from '../../images/bg-triangles.svg'

export const AuthScreen = styled.div`
  display: flex;
  flex: 1 0;
`

export const Image = styled.div`
  padding: 0 100px;
  flex: 1 0;
  background-image: url(${trianglesBackground});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1280px) {
    padding: 0 75px;
  }

  div {
    max-width: 700px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const Title = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 1.1;
  color: #e5e5e5;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (min-width: 1440px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 22.5px;
    margin-bottom: 15px;
  }
`

export const FormArea = styled.div`
  width: 40%;
  max-width: 593px;
  background-color: #2b2b2b;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    padding: 0 75px;
  }

  p {
    font-weight: 300;
    font-size: 13.5px;
    line-height: 1.1;
    color: #e5e5e5;

    @media screen and (min-width: 1440px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1280px) {
      font-size: 10px;
    }

    &:nth-child(4) > span,
    &:nth-child(5) {
      color: #cfb26c;
      cursor: pointer;
    }

    &:nth-child(5) {
      margin: 12px 0 37px;

      @media screen and (max-width: 1280px) {
        margin: 7px 0 27.75px;
      }
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  height: 462px;
  overflow: hidden;

  @media screen and (min-width: 1440px) {
    height: 595px;
  }

  @media screen and (max-width: 1280px) {
    height: 346.5px;
  }

  h2 {
    text-align: left;
  }

  form {
    margin: 32px 0 26px;

    @media screen and (min-width: 1440px) {
      margin: 42px 0 38px;
    }

    @media screen and (max-width: 1280px) {
      margin: 24px 0 19.5px;
    }
  }

  .area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.15s ease-in;
  }
`

export const Login = styled.div`
  transform: ${(props) =>
    props.isHidden ? 'translateX(-100%)' : 'translateX(0)'};

  form > div:nth-child(2) {
    margin-bottom: 27px;

    @media screen and (min-width: 1440px) {
      margin-bottom: 34px;
    }

    @media screen and (max-width: 1280px) {
      margin-bottom: 20px;
    }
  }
`

export const SignUp = styled.div`
  transform: ${(props) =>
    props.isHidden ? 'translateX(100%)' : 'translateX(0)'};

  form > div:nth-child(3) {
    margin-bottom: 27px;

    @media screen and (min-width: 1440px) {
      margin-bottom: 34px;
    }

    @media screen and (max-width: 1280px) {
      margin-bottom: 20px;
    }
  }

  div:nth-last-child(1) {
    display: flex;
    justify-content: space-between;

    button {
      &:nth-child(1) {
        width: 55px;
        background-color: rgba(65, 65, 65, 0.72);
        border-radius: 20px;
        color: #e5e5e5;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;

        @media screen and (max-width: 1280px) {
          width: 41.25px;
          border-radius: 4px;
        }

        svg {
          height: 24px;
          width: 24px;

          @media screen and (min-width: 1440px) {
            height: 30px;
            width: 30px;
          }

          @media screen and (max-width: 1280px) {
            height: 18px;
            width: 18px;
          }
        }

        @media screen and (min-width: 1440px) {
          width: 65px;
        }
      }
      &:nth-child(2) {
        margin: 0;
      }
    }
  }
`

export const Submit = styled(Button)`
  width: 122px;
  margin: 18px auto 0;
  font-size: 16px;

  @media screen and (min-width: 1440px) {
    width: 180px;
    font-size: 25px;
    margin: 29px auto 0;
  }

  @media screen and (max-width: 1280px) {
    width: 91.5px;
    font-size: 12px;
    margin: 13.5px auto 0;
    border-radius: 7.5px;
    border-width: 2px;
  }
`

export const Providers = styled.div`
  display: flex;
  flex-direction: column;

  div:nth-child(1) {
    display: inline-flex;
    align-items: center;

    &::before,
    &::after {
      content: '';
      border: 1px solid #464646;
      flex: 1 0;
    }

    p {
      margin: 0 26px;

      @media screen and (max-width: 1280px) {
        margin: 0 19.5px;
      }
    }
  }

  div:nth-child(2) {
    display: block;
    margin: 18px auto 0;

    @media screen and (max-width: 1280px) {
      margin: 13.5px auto 0;
    }

    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 18px;

        @media screen and (max-width: 1280px) {
          margin-left: 13.5px;
        }
      }

      img {
        @media screen and (max-width: 1280px) {
          width: 27px;
          height: 27px;
        }
      }
    }
  }
`
