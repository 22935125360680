const returnLanguageString = (lang) => {
  switch (lang) {
    case 'es':
      return 'es-MX'
    default:
      return 'en-US'
  }
}

export default returnLanguageString
