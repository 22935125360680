import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PageWithResults, Scrollable } from './styles'
import Loading from '../Loading'

import { store } from '../../store'
import { showErrorMessage } from '../../utils'

const PWRComponent = ({ title, children, isLoading, handlers, content }) => {
  const [hasReachedEnd, setHasReachedEnd] = useState(false)

  const ref = useRef()

  const setContent = handlers.content
  const setIsLoading = handlers.loading
  const getContent = handlers.getter

  const initialFetching = useCallback(() => {
    const fetch = async () => {
      try {
        const res = await getContent(store.getState().currentUser.uid)
        setContent(res)
      } catch (error) {
        showErrorMessage(error)
      }
      setIsLoading(false)
    }
    fetch()
  }, [getContent, setContent, setIsLoading])

  useEffect(() => {
    if (store.getState().currentUser) {
      initialFetching()
    } else {
      setIsLoading(false)
    }
    return () => {
      setContent([])
    }
  }, [initialFetching, setContent, setIsLoading])

  const scrollHandler = useCallback(
    (e) => {
      if (hasReachedEnd) return
      const nextFetchings = async (lastResult) => {
        try {
          let results
          if (!store.getState().currentUser) {
            results = JSON.parse(sessionStorage.getItem('rpc_search_history'))
            setContent(results)
          } else {
            results = await getContent(
              store.getState().currentUser.uid,
              lastResult
            )
            if (!results.length) {
              setHasReachedEnd(true)
            } else {
              setContent([...content, ...results])
            }
          }
        } catch (error) {
          showErrorMessage(error)
        }
        setIsLoading(false)
      }
      if (
        e.target.scrollHeight - e.target.scrollTop ===
        e.target.clientHeight
      ) {
        nextFetchings(content[content.length - 1])
      }
    },
    [content, hasReachedEnd, getContent, setIsLoading, setContent]
  )

  useEffect(() => {
    const node = ref.current
    if (node && store.getState().currentUser) {
      node.addEventListener('scroll', scrollHandler)
    }
    return () => {
      if (node) node.removeEventListener('scroll', scrollHandler)
    }
  }, [scrollHandler])

  return (
    <>
      <PageWithResults>
        <h2>{title}</h2>
        <Scrollable ref={ref}>
          <div>{children}</div>
          {isLoading && <Loading />}
        </Scrollable>
      </PageWithResults>
    </>
  )
}

export default PWRComponent
