import React from 'react'
import { Page } from '../../components/PageComponent/styles'
import { Inner, Title, ContentWrapper, Line } from './styles'
import { i18n } from '../../i18n'

const Settings = () => {
  const { privacyPolicy } = i18n().aboutModal

  return (
    <Page>
      <Inner>
        <Title>{privacyPolicy.title}</Title>
        <ContentWrapper>
          {privacyPolicy.content.map((text, i) => (
            <Line key={`privacy_policy_line_${i}`}>{text}</Line>
          ))}
        </ContentWrapper>
      </Inner>
    </Page>
  )
}

export default Settings
