import React from 'react'
import { Button } from './styles'

const NavigationButton = ({ text, onClick, disabled }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    onMouseDown={(e) => e.preventDefault()}
  >
    {text}
  </Button>
)

export default NavigationButton
