import styled from 'styled-components'

export const Checkbox = styled.div`
  height: 25px;
  width: 25px;
  border: 2px solid #767575;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.15s ease-in;

  @media screen and (max-width: 1280px) {
    height: 18.75px;
    width: 18.75px;
    border-radius: 3px;
  }
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > input[type='checkbox'] {
    display: none;

    &:disabled + div {
      border-color: #414141;
      cursor: not-allowed;
    }

    &:checked + div {
      border-color: transparent;
      background-color: #f9a826;

      & > svg {
        display: initial;
      }
    }
  }

  & > div > svg {
    display: none;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  & > span {
    color: #e5e5e5;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: -0.035px;
    margin-left: 6px;

    @media screen and (max-width: 1280px) {
      font-size: 11.25px;
    }
  }
`
