import styled from 'styled-components'

export const PageWithResults = styled.div`
  padding-top: 36px;
  display: grid;
  grid-template-rows: auto calc(100vh - 207px);
  width: 100%;

  @media screen and (max-width: 1280px) {
    padding-top: 27px;
    grid-template-rows: auto calc(100vh - 155px);
  }

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.1;
    color: #e5e5e5;
    text-transform: uppercase;
    text-align: center;
    margin: 24px 0;

    @media screen and (max-width: 1280px) {
      font-size: 22.5px;
      margin: 18px 0;
    }
  }

  #filter {
    margin: 0 124px 40px;
    display: flex;
    align-items: center;

    p {
      flex: 1 0;
    }

    button {
      width: 55px;
      height: 55px;
      background-color: #414141;
      border-radius: 10px;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
    }
  }
`

export const Scrollable = styled.div`
  overflow: auto;
  position: relative;
  padding: 0 78px;

  & > div {
    &:nth-child(1) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(auto-fit, minmax(auto, 100px));
      gap: 18px 25px;
    }

    &:nth-child(2) {
      margin-top: 18px;
      grid-row: -1 / -2;
      position: relative;
      grid-column: 1 / -1;
    }
  }
`
