import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;

  > p {
    text-align: center;
    letter-spacing: -0.035em;

    &:nth-child(1) {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 18px;
    }

    &:nth-child(2) {
      font-weight: 300;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 13px;
    }
  }
`

export const List = styled.ul`
  list-style: none;
  max-height: 188px;
  overflow: auto;
`

export const Item = styled.li`
  border-top: 1px solid rgba(229, 229, 229, 0.3);
  padding: 10px 0;
  cursor: pointer;
  color: ${({ active }) => (active ? '#cfb26c' : '#e5e5e5')};

  & + li {
    border-bottom: 1px solid rgba(229, 229, 229, 0.3);
  }

  &:hover {
    background-color: rgba(229, 229, 229, 0.1);
  }
`
