import styled from 'styled-components'

export const HomeWrapper = styled.div`
  flex: 1 0;
  display: flex;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 110px);
  }
`

export const HomeInner = styled.div`
  flex: 1 0;
  display: flex;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    margin: 0 30px;
  }
`
