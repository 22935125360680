import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 36px;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.1;
    color: #e5e5e5;
    text-transform: uppercase;
    text-align: center;
    margin: 24px 0;

    @media screen and (max-width: 1280px) {
      font-size: 22.5px;
      margin: 18px 0;
    }
  }
`

export const Inner = styled.div`
  text-align: center;

  & > p {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.1;
    color: #e5e5e5;
  }
`
