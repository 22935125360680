import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { FiX } from 'react-icons/fi'
import { useHelpTips } from '../../contexts/HelpTipsContext'
import config from '../../rpc.config'

const { TIMEOUT_VALUES } = config

const Tip = ({ text, show, id }) => {
  const { hideTip } = useHelpTips()

  return (
    <CSSTransition
      key={id}
      in={show}
      timeout={TIMEOUT_VALUES}
      classNames='dissolve'
      unmountOnExit
    >
      <div className='tip-animation-area'>
        <div className='bubble tip'>
          <span>💡 {text}</span>
          <span className='tip-close' onClick={() => hideTip(id)}>
            <FiX />
          </span>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Tip
