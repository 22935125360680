import React from 'react'
import defractionate from 'defractionate'
import { Input, InputWrapper, InputLabel } from './styles'
import {
  isMillimeterValid,
  isInchesValid,
  filterInputCharacter,
} from '../../utils'
import { store } from '../../store'
import {
  setPipeSize,
  setActiveInput,
  clearActiveInput,
  validateActiveInput,
  setCurrentValueWarning,
  closeTooltip,
} from '../../actions'
import Dialog from '../Tooltips/Dialog'

const PipeSizeInput = ({ label, name, id, dialog }) => {
  const handleChange = (e) => {
    const { name, value } = e.target
    let mm
    let inches = null
    switch (name) {
      case 'pipeSizeIn':
        if (isInchesValid(value)) {
          inches = value
          mm = defractionate(value)
          store.dispatch(setPipeSize(mm, inches))
        } else {
          showWarning(name, value)
        }
        break
      case 'pipeSizeMm':
        if (isMillimeterValid(value)) {
          mm = value
          if (value >= 1.5875) inches = +(value / 25.4).toFixed(4)
          store.dispatch(setPipeSize(mm, inches))
        } else {
          showWarning(name, value)
        }
        break
      default:
    }
  }

  const showWarning = (name, value) => {
    const character = filterInputCharacter(value)
    store.dispatch(setCurrentValueWarning(name, character))
    store.dispatch(validateActiveInput(name, false))
    setTimeout(() => {
      store.dispatch(closeTooltip(name))
    }, 2500)
  }

  const clickInput = () => {
    store.dispatch(setActiveInput(name))
  }

  const leaveInput = () => {
    store.dispatch(clearActiveInput(name))
  }

  const { query } = store.getState()
  const condition =
    store.getState().pipeSizeInput[name].isActive === true
      ? store.getState().pipeSizeInput[name].isValid === false
      : false

  return (
    <InputWrapper>
      <InputLabel htmlFor={id}>{label}:</InputLabel>
      <Input
        type='text'
        name={name}
        id={id}
        value={query[name] || ''}
        onChange={handleChange}
        onFocus={clickInput}
        onBlur={leaveInput}
      />
      <Dialog relative={name} content={dialog} trigger={condition} />
    </InputWrapper>
  )
}

export default PipeSizeInput
