import styled from 'styled-components'

export const Button = styled.button`
  width: 110px;
  height: 40px;
  background-color: #2e2e2e;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;

  @media screen and (max-width: 1280px) {
    width: 82.5px;
    height: 30px;
    font-size: 8px;
  }

  @media screen and (max-width: 768px) {
    width: 110px;
    height: 40px;
    font-size: 12px;
  }

  &:disabled {
    background-color: #393939;
    color: #525151;
    cursor: not-allowed;

    &:hover {
      background-color: #393939;
      color: #525151;
    }
  }

  &:hover {
    background-color: #626262;
  }
`
