import styled from 'styled-components'

export const Avatar = styled.div`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1em;
`
