import React from 'react'
import { CardWrapper } from '../../../components/PageCard/styles'
import PageCard from '../../../components/PageCard'
import ComponentWrapper from '../../../components/PageComponent/ComponentWrapper'
import { i18n } from '../../../i18n'

const RoofTypeScreen = () => {
  const { title, text, labelMetal, labelResidential } = i18n().roofType

  return (
    <ComponentWrapper title={title} textLine={text}>
      <CardWrapper>
        <PageCard
          img='rooftype-commercial'
          label={labelMetal}
          name='roofType'
          value='isMetal'
        />
        <PageCard
          img='rooftype-residential'
          label={labelResidential}
          name='roofType'
          value='isNonMetal'
        />
      </CardWrapper>
    </ComponentWrapper>
  )
}

export default RoofTypeScreen
