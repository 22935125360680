import React from 'react'
import { AlgoliaSearchContextProvider } from './contexts/AlgoliaSearchContext'
import { HelpTipsContextProvider } from './contexts/HelpTipsContext'
import { FavoritesContextProvider } from './contexts/FavoritesContext'
import GlobalStyle from './globalStyles'
import PreMain from './screens/PreMain'

const App = () => (
  <AlgoliaSearchContextProvider>
    <HelpTipsContextProvider>
      <GlobalStyle />
      <FavoritesContextProvider>
        <PreMain />
      </FavoritesContextProvider>
    </HelpTipsContextProvider>
  </AlgoliaSearchContextProvider>
)

export default App
