import styled from 'styled-components'

export const ProfilePage = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 62px auto 115px;

  @media screen and (max-width: 1280px) {
    padding: 0 30px;
    margin: 46.5px auto 86.25px;
  }
`

export const PictureArea = styled.div`
  display: flex;
  align-items: center;
`

export const PictureWrapper = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1280px) {
    height: 150px;
    width: 150px;
  }

  div.blank-avatar {
    font-size: 90px;

    @media screen and (max-width: 1280px) {
      font-size: 67.5px;
    }
  }

  label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    color: #e5e5e5;
    display: none;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.1;
      margin-top: 4px;

      @media screen and (max-width: 1280px) {
        font-size: 12px;
      }
    }
  }

  &:hover label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(54, 54, 54, 0.81);
  }

  input[type='file'] {
    display: none;
  }

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const TextArea = styled.p`
  margin-left: 40px;
  color: #e5e5e5;
  line-height: 1.1;
  font-weight: 500;
  font-size: 40px;

  @media screen and (max-width: 1280px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 30px;
  }
`

export const InfoCard = styled.div`
  background-color: #414141;
  border-radius: 4px;
  color: rgba(229, 229, 229, 0.7);
  padding: 35px 120px 35px 40px;
  line-height: 1.1;

  @media screen and (max-width: 1280px) {
    padding: 26px 90px 26px 30px;
  }

  &:nth-child(2) {
    margin-top: 50px;

    @media screen and (max-width: 1280px) {
      margin-top: 37.5px;
    }
  }

  &:nth-child(3) {
    margin-top: 60px;

    @media screen and (max-width: 1280px) {
      margin-top: 45px;
    }
  }

  p {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 40px;

    @media screen and (max-width: 1280px) {
      font-size: 22.5px;
      margin-bottom: 30px;
    }
  }
`

export const NotificationsSwitcher = styled.div`
  height: 26px;
  width: 50px;
  background-color: ${(props) =>
    props.active ? 'rgba(229, 229, 229, 0.7)' : 'rgba(26, 25, 25, 0.78)'};
  position: absolute;
  right: 0;
  border-radius: 15px;
  transition: background-color 0.25s ease-in;

  @media screen and (max-width: 1280px) {
    height: 19.5px;
    width: 37.5px;
    border-radius: 11.25px;
  }

  &:before {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    background-color: ${(props) => (props.active ? '#414141' : '#343434')};
    content: '';
    position: absolute;
    right: 5px;
    top: 3px;
    transform: ${(props) =>
      props.active ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.1s ease-in;

    @media screen and (max-width: 1280px) {
      height: 15px;
      width: 15px;
      right: 4px;
      top: 2px;
    }
  }
`

export const DeleteProfileButton = styled.button`
  width: 191px;
  height: 60px;
  background-color: #9b4141;
  border-radius: 15px;
  margin: 24px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.1;
  color: #e5e5e5;
  border: 0;
  outline: none;

  & > svg {
    margin-right: 9px;
    height: 22px;
    width: 22px;
  }
`
