import React from 'react'
import { Card, ImageWrapper, TextWrapper } from './styles'

import { store } from '../../store'
import { setValue, clickNext } from '../../actions'

const PageCard = ({ img, label, name, value }) => {
  const { images, pageIndex } = store.getState()
  const isActive = store.getState().query[name] === value

  const handleClick = () => {
    store.dispatch(setValue(name, value))
    store.dispatch(clickNext(pageIndex))
  }

  return (
    <Card active={isActive} onClick={handleClick}>
      <ImageWrapper>
        {img && <img src={images[`${img}.png`]} alt={name} />}
      </ImageWrapper>
      <TextWrapper>
        <span>{label}</span>
      </TextWrapper>
    </Card>
  )
}

export default PageCard
