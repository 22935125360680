export const clickToGoToPage = (pageIndex) => ({
  type: 'CLICK_TO_GO_TO_PAGE',
  newIndex: pageIndex,
})

export const clickNext = (pageIndex) => ({
  type: 'CLICK_NEXT',
  newIndex: pageIndex < 8 ? pageIndex + 1 : pageIndex,
})

export const clickPrevious = (pageIndex) => ({
  type: 'CLICK_PREVIOUS',
  newIndex: pageIndex > 1 ? pageIndex - 1 : pageIndex,
})

export const setPagesLength = (value) => ({
  type: 'SET_PAGES_LENGTH',
  pagesLength: value,
})

export const clickReset = () => ({
  type: 'CLICK_RESET',
  payload: {
    newIndex: 1,
    query: {
      pipeSizeMm: null,
      pipeSizeIn: null,
      pipeType: null,
      roofType: null,
      roofPitch: null,
      weatherProtection: null,
      applicationType: null,
    },
    roofPitchDropdownValue: null,
    result: {},
  },
})

export const setValue = (name, value) => ({
  type: 'SET_VALUE',
  payload: {
    name,
    value,
  },
})

export const setPipeSize = (mm, inches) => ({
  type: 'SET_PIPE_SIZE',
  payload: {
    pipeSizeMm: mm,
    pipeSizeIn: inches,
  },
})

export const toggleDropdown = (isOpen) => ({
  type: 'TOGGLE_DROPDOWN',
  isOpen: !isOpen,
})

export const selectDropdown = (value) => ({
  type: 'SET_DROPDOWN',
  payload: {
    isOpen: false,
    currentValue: value,
  },
})

export const queryResults = (flashing) => ({
  type: 'QUERY_RESULTS',
  result: flashing,
})

export const setLoading = (isLoading) => ({
  type: 'SET_LOADING',
  isLoading,
})

export const saveCurrentQuery = (currentQuery) => ({
  type: 'SAVE_CURRENT',
  currentQuery,
})

export const clearResults = () => ({
  type: 'CLEAR_RESULTS',
  result: [],
})

export const setResultScroll = (boolean) => ({
  type: 'SET_RESULT_SCROLL',
  resultScroll: boolean,
})

export const updateResults = (flashing, position) => ({
  type: 'UPDATE_RESULTS',
  payload: {
    flashing,
    position,
  },
})

export const removeFromResult = (flashingId) => ({
  type: 'REMOVE_FLASHING',
  id: flashingId,
})

export const renderPopup = (boolean) => ({
  type: 'RENDER_POPUP',
  renderPopup: boolean,
})

export const checkLogIn = (isLogged) => ({
  type: 'CHECK_LOGIN',
  isUserLoggedIn: isLogged,
})

export const setActiveInput = (name) => ({
  type: 'SET_ACTIVE_INPUT',
  payload: {
    input: name,
    isActive: true,
  },
})

export const clearActiveInput = (name) => ({
  type: 'CLEAR_ACTIVE_INPUT',
  payload: {
    input: name,
    isActive: null,
  },
})

export const validateActiveInput = (name, isValid) => ({
  type: 'VALIDATE_ACTIVE_INPUT',
  payload: {
    input: name,
    isValid,
  },
})

export const closeTooltip = (name) => ({
  type: 'CLOSE_TOOLTIP',
  input: name,
})

export const setCurrentValueWarning = (name, value) => ({
  type: 'SET_CURRENT_VALUE_WARNING',
  payload: {
    input: name,
    value,
  },
})

export const setCurrentHelper = (helperId) => ({
  type: 'SET_CURRENT_HELPER',
  id: helperId,
})

export const shouldRenderHelper = () => ({
  type: 'SHOULD_RENDER_HELPER',
})

export const getParentCoordinate = (value) => ({
  type: 'GET_PARENT_COORDINATE',
  position: value,
})

export const getHelperCoordinate = (value) => ({
  type: 'GET_HELPER_COORDINATE',
  position: value,
})

export const getDeviceWidth = (value) => ({
  type: 'GET_DEVICE_WIDTH',
  width: value,
})

export const addImageToStore = (image) => ({
  type: 'ADD_IMG_TO_STORE',
  incomingImg: image,
})

export const increaseImgCount = (count) => ({
  type: 'INCREASE_IMG_COUNT',
  count,
})

export const setlang = (lang) => {
  const language = lang.split('-')
  return {
    type: 'SET_LANG',
    lang: language[0],
  }
}

export const setCurrentUser = (data) => ({
  type: 'SET_CURRENT_USER',
  data,
})

export const clearCurrentUser = () => ({
  type: 'CLEAR_CURRENT_USER',
  data: null,
})

export const setSearchText = (searchText) => ({
  type: 'SET_SEARCH_TEXT',
  searchText,
})

export const setSearchResult = (result) => ({
  type: 'SET_SEARCH_RESULT',
  searchResult: result,
})

export const clearSearchResult = () => ({
  type: 'CLEAR_SEARCH_RESULT',
})

export const setChip = (value) => ({
  type: 'SET_CHIP',
  chip: value,
})

export const removeChip = (value) => ({
  type: 'REMOVE_CHIP',
  chip: value,
})

export const setFavorites = (flashings) => ({
  type: 'SET_FAVORITES',
  flashings,
})

export const setErrorMessage = (message) => ({
  type: 'SET_ERROR_MESSAGE',
  message,
})

export const setRepeatingSearch = () => ({
  type: 'SET_REPEATING_SEARCH',
  state: true,
})

export const setLogCardId = (id) => ({
  type: 'SET_LOG_CARD_ID',
  id,
})

export const setDropdownScreen = (bool) => ({
  type: 'SHOW_DROPDOWN_SCREEN',
  payload: bool,
})

export const changeLanguage = (lang) => ({
  type: 'CHANGE_LANGUAGE',
  lang,
})

export const setChipsOnPageLoad = (chips) => ({
  type: 'SET_CHIPS_ON_PAGE_LOAD',
  chips,
})

export const resetChips = () => ({
  type: 'RESET_CHIPS',
})

export const setDeviceTypeRedux = (deviceType) => ({
  type: 'SET_DEVICE_TYPE',
  deviceType,
})
