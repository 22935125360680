import React, { createContext, useContext, useState } from 'react'
import { chipsSearch, textSearch } from '../services/algolia/algolia'
import { saveSearchQueryOnLocalStorage } from '../utils'

const AlgoliaSearchContext = createContext([])

export const AlgoliaSearchContextProvider = ({ children }) => {
  const [results, setResults] = useState([])
  const [searchText, setSearchText] = useState('')
  const [availableChips, setAvailableChips] = useState([])
  const [selectedChips, setSelectedChips] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const [hasMoreToShow, setHasMoreToShow] = useState(true)

  const doChipsSearch = async () => {
    const chipsSearchResult = await chipsSearch(selectedChips, pageIndex)
    if (!chipsSearchResult) {
      setHasMoreToShow(false)
      return
    }
    setResults([...results, ...chipsSearchResult])
    setPageIndex(pageIndex + 1)
  }

  const addChip = (chipBeingAdded) => {
    clearResults()
    if (searchText) setSearchText('')
    const chipToAdd = availableChips.find(
      (_chip) => _chip.value === chipBeingAdded
    )
    const updatedAvailableChipsArray = availableChips.filter(
      (_chip) => _chip.value !== chipBeingAdded
    )
    setAvailableChips(updatedAvailableChipsArray)
    setSelectedChips([...selectedChips, chipToAdd])
  }

  const removeChip = (chipBeingRemoved) => {
    clearResults()
    const chipToRemove = selectedChips.find(
      (_chip) => _chip.value === chipBeingRemoved
    )
    const updatedSelectedChipsArray = selectedChips.filter(
      (_chip) => _chip.value !== chipBeingRemoved
    )
    setAvailableChips([...availableChips, chipToRemove])
    setSelectedChips(updatedSelectedChipsArray)
  }

  const setChipsOnPageLoad = (listOfChips) => {
    setAvailableChips(listOfChips)
  }

  const resetChips = () => {
    setAvailableChips([...availableChips, ...selectedChips])
    setSelectedChips([])
  }

  const clearResults = () => {
    setResults([])
    setPageIndex(0)
    setHasMoreToShow(true)
  }

  const doTextSearch = async () => {
    const textSearchResult =
      searchText.length >= 3 ? await textSearch(searchText, pageIndex) : null
    if (!textSearchResult) {
      setHasMoreToShow(false)
      return
    }
    saveSearchQueryOnLocalStorage(searchText)

    setResults([...results, ...textSearchResult])
    setPageIndex(pageIndex + 1)
  }

  const clearSearchBox = () => {
    setSearchText('')
  }

  const handleTextSearch = (value) => {
    clearResults()
    setSearchText(value)
  }

  return (
    <AlgoliaSearchContext.Provider
      value={{
        doChipsSearch,
        doTextSearch,
        results,
        clearResults,
        pageIndex,
        hasMoreToShow,
        selectedChips,
        availableChips,
        addChip,
        removeChip,
        resetChips,
        setChipsOnPageLoad,
        searchText,
        handleTextSearch,
        clearSearchBox,
      }}
    >
      {children}
    </AlgoliaSearchContext.Provider>
  )
}

export const useAlgolia = () => {
  const contextFunctions = useContext(AlgoliaSearchContext)
  return { ...contextFunctions }
}
