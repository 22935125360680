import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import {
  AuthScreen,
  Image,
  Title,
  FormArea,
  Wrapper,
  Login,
  SignUp,
  Providers,
  Submit,
} from './styles'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
import Checkbox from '../../components/Checkbox'

import ForgotPassword from '../../components/Modals/ForgotPassword'

import {
  googleLogin,
  signUpWithEmail,
  loginWithEmail,
  facebookLogin,
} from '../../services/firebase/auth'
import { store } from '../../store'
import { i18n } from '../../i18n'
import { validateEmail, showErrorMessage } from '../../utils'

import useLoginState from '../../hooks/useLoginState'

const SignUpComponent = () => {
  // Signup screen hooks
  const [signupEmail, setSignupEmail] = useState('')
  const [signupEmailErrorMsg, setSignupEmailErrorMsg] = useState('')

  const [signupPassword, setSignupPassword] = useState('')
  const [signupPasswordErrorMsg, setSignupPasswordErrorMsg] = useState('')

  const [signupPasswordRepeat, setSignupPasswordRepeat] = useState('')
  const [signupPasswordRepeatErrorMsg, setSignupPasswordRepeatErrorMsg] =
    useState('')
  //

  // Login screen hooks
  const { isOnLoginScreen, setisOnLoginScreen } = useLoginState()

  const [loginEmail, setLoginEmail] = useState('')
  const [loginEmailErrorMsg, setLoginEmailErrorMsg] = useState('')

  const [loginPassword, setLoginPassword] = useState('')
  const [loginPasswordErrorMsg, setLoginPasswordErrorMsg] = useState('')

  const [shouldRememberUser, toggleRemember] = useState(true)

  const [showPasswordRecoveryModal, togglePasswordRecoveryModal] =
    useState(false)
  //

  const [isLoading, setIsLoading] = useState(false)

  const { images } = store.getState()
  const history = useHistory()

  const handleSubmitLogin = async (e) => {
    setIsLoading(true)
    e.preventDefault()
    try {
      await loginWithEmail(loginEmail, loginPassword, shouldRememberUser)
      if (store.getState().currentUser) history.push('/')
    } catch (error) {
      showErrorMessage(error)
    }
    setIsLoading(false)
  }

  const handleLoginEmail = (e) => {
    setLoginEmailErrorMsg('')
    if (e.type === 'blur' && !validateEmail(loginEmail))
      setLoginEmailErrorMsg(i18n().login.errorMessages.email)
    if (e.type === 'change') setLoginEmail(e.target.value)
  }

  const handleLoginPassword = (e) => {
    setLoginPasswordErrorMsg('')
    if (e.type === 'blur' && loginPassword.length < 6)
      setLoginPasswordErrorMsg(i18n().login.errorMessages.password)
    if (e.type === 'change') setLoginPassword(e.target.value)
  }

  const handleSignupEmail = (e) => {
    setSignupEmailErrorMsg('')
    if (e.type === 'blur' && !validateEmail(signupEmail))
      setSignupEmailErrorMsg(i18n().login.errorMessages.email)
    if (e.type === 'change') setSignupEmail(e.target.value)
  }

  const handleSignupPassword = (e) => {
    setSignupPasswordErrorMsg('')
    if (e.type === 'blur' && signupPassword.length < 6)
      setSignupPasswordErrorMsg(i18n().login.errorMessages.password)
    if (e.type === 'change') setSignupPassword(e.target.value)
  }

  useEffect(() => {
    if (signupPasswordRepeat && signupPasswordRepeat !== signupPassword) {
      setSignupPasswordRepeatErrorMsg(i18n().login.errorMessages.passwordRepeat)
    } else {
      setSignupPasswordRepeatErrorMsg('')
    }
  }, [signupPasswordRepeat, signupPassword])

  const handleSignup = async (e) => {
    e.preventDefault()
    if (signupPassword !== signupPasswordRepeat) return
    setIsLoading(true)
    try {
      await signUpWithEmail(signupEmail, signupPassword)
      if (store.getState().currentUser) history.push('/')
    } catch (error) {
      showErrorMessage(error)
    }
    setIsLoading(false)
  }

  const handleLoginWithProvider = async (provider) => {
    setIsLoading(true)
    try {
      switch (provider) {
        case 'google':
          await googleLogin()
          break
        case 'fb':
          await facebookLogin()
          break
        default:
          break
      }
      if (store.getState().currentUser) history.push('/')
    } catch (error) {
      showErrorMessage(error)
    }
    setIsLoading(false)
  }

  return (
    <AuthScreen>
      <ForgotPassword
        show={showPasswordRecoveryModal}
        toggle={togglePasswordRecoveryModal}
      />
      {isLoading && <Loading showBackground />}
      <Image>
        <div>
          <Title>{i18n().login.welcome}</Title>
          <img src={images['signup-image.svg']} alt='' />
        </div>
      </Image>
      <FormArea>
        <Wrapper>
          <Login className='area' isHidden={!isOnLoginScreen}>
            <Title>{i18n().login.loginForm.signIn}</Title>
            <p>{i18n().login.loginForm.text}</p>
            <form onSubmit={handleSubmitLogin}>
              <Input
                label={i18n().inputTexts.email.label}
                id='input-email'
                placeholder={i18n().inputTexts.email.placeholder}
                type='email'
                onChange={handleLoginEmail}
                onBlur={handleLoginEmail}
                error={loginEmailErrorMsg}
              />
              <Input
                label={i18n().inputTexts.password.label}
                id='input-password'
                placeholder={i18n().inputTexts.password.placeholder}
                type='password'
                onChange={handleLoginPassword}
                onBlur={handleLoginPassword}
                error={loginPasswordErrorMsg}
              />
              <Checkbox
                id='rememberMe'
                checked={shouldRememberUser}
                name={i18n().login.loginForm.rememberMe}
                onChange={() => toggleRemember(!shouldRememberUser)}
              />
              <Submit
                disabled={
                  !(
                    loginEmail.length &&
                    validateEmail(loginEmail) &&
                    loginPassword.length >= 6
                  )
                }
              >
                {i18n().login.loginForm.signIn}
              </Submit>
            </form>
            <p>
              {i18n().login.loginForm.dontHaveAnAccount}{' '}
              <span onClick={() => setisOnLoginScreen(false)}>
                {i18n().login.signupForm.signUp}
              </span>
            </p>
            <p onClick={() => togglePasswordRecoveryModal(true)}>
              {i18n().login.loginForm.forgotPassword}
            </p>
          </Login>
          <SignUp className='area' isHidden={isOnLoginScreen}>
            <Title>{i18n().login.signupForm.signUp}</Title>
            <p>{i18n().login.signupForm.text}</p>
            <form onSubmit={handleSignup}>
              <Input
                label={i18n().inputTexts.email.label}
                id='input-email'
                placeholder={i18n().inputTexts.email.placeholder}
                type='email'
                onChange={handleSignupEmail}
                onBlur={handleSignupEmail}
                error={signupEmailErrorMsg}
              />
              <Input
                label={i18n().inputTexts.password.label}
                id='input-password'
                placeholder={i18n().inputTexts.password.placeholder}
                type='password'
                onChange={handleSignupPassword}
                onBlur={handleSignupPassword}
                error={signupPasswordErrorMsg}
              />
              <Input
                label={i18n().inputTexts.password.repeatYourPassword}
                id='input-password-repeat'
                placeholder={i18n().inputTexts.password.placeholder}
                type='password'
                onChange={(e) => setSignupPasswordRepeat(e.target.value)}
                error={signupPasswordRepeatErrorMsg}
              />
              <div>
                <button type='button' onClick={() => setisOnLoginScreen(true)}>
                  <FiArrowLeft />
                </button>
                <Submit
                  type='submit'
                  disabled={
                    !(
                      signupEmail.length &&
                      validateEmail(signupEmail) &&
                      signupPassword.length >= 6 &&
                      signupPasswordRepeat === signupPassword
                    )
                  }
                >
                  {i18n().login.signupForm.signUp}
                </Submit>
              </div>
            </form>
          </SignUp>
        </Wrapper>
        <Providers>
          <div>
            <p>{i18n().login.orContinueWith}</p>
          </div>
          <div>
            <button
              type='button'
              onClick={() => handleLoginWithProvider('google')}
            >
              <img
                src={images['google-icon.svg']}
                alt={`${i18n().login.loginWith} Google`}
              />
            </button>
            {/* <button onClick={null}>
                            <img src={images['apple-icon.svg']} alt={`${i18n().login.loginWith} Apple`} />
                        </button> */}
            <button type='button' onClick={() => handleLoginWithProvider('fb')}>
              <img
                src={images['fb-icon.svg']}
                alt={`${i18n().login.loginWith} Facebook`}
              />
            </button>
          </div>
        </Providers>
      </FormArea>
    </AuthScreen>
  )
}

export default SignUpComponent
