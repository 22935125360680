import React, { createContext, useContext, useEffect, useState } from 'react'
import { store } from '../store'
import {
  fetchFavorites,
  saveFavorites,
  syncFavorites,
  fetchAllFavoritesUid,
} from '../services/firebase/firestore'
import { getStorageLocation } from '../utils'
import config from '../rpc.config'

const { FAVORITES_STORAGE_KEY } = config
const storage = getStorageLocation()

const FavoritesContext = createContext([])

export const FavoritesContextProvider = ({ children }) => {
  const [favoriteFlashings, setFavoriteFlashings] = useState([])
  const [hasMoreToShow, setHasMoreToShow] = useState(true)
  const [favoriteFlashingsUidList, setFavoriteFlashingsUidList] = useState([])
  const [lastFavoriteOfList, setLastFavoriteOfList] = useState({})

  const getUids = (flashingsArray) =>
    flashingsArray.map((flashing) => flashing.uid || flashing.objectId)

  useEffect(() => {
    setFavoriteFlashingsUidList(getUids(favoriteFlashings))
  }, [favoriteFlashings.length])

  const getFavorites = async (lastResult, isFirstFetch = true) => {
    try {
      const userId = store.getState().currentUser
        ? store.getState().currentUser.uid
        : null
      const { results, lastFavorite } = await fetchFavorites(userId, lastResult)
      if (!results.length) {
        setHasMoreToShow(false)
        setLastFavoriteOfList({})
      } else {
        if (isFirstFetch && !hasMoreToShow) setHasMoreToShow(true)
        setFavoriteFlashings(
          isFirstFetch ? results : [...favoriteFlashings, ...results]
        )
        setLastFavoriteOfList(lastFavorite)
      }
    } catch (error) {
      setHasMoreToShow(false)
      throw error
    }
  }

  const getNextFavorites = () => getFavorites(lastFavoriteOfList, false)

  const saveSingleFavorite = async (userId, flashing, isFavorite) => {
    if (!userId) {
      const favorites = JSON.parse(storage.getItem(FAVORITES_STORAGE_KEY)) || []
      !isFavorite
        ? favorites.splice(
            favorites.findIndex(
              (currentFlashing) => currentFlashing === flashing
            ),
            1
          )
        : favorites.push({ uid: flashing, dateAdded: new Date() })
      !favorites.length
        ? storage.removeItem(FAVORITES_STORAGE_KEY)
        : storage.setItem(FAVORITES_STORAGE_KEY, JSON.stringify(favorites))
    } else {
      const updatedFavoritesUidList = await saveFavorites(
        flashing,
        isFavorite,
        userId,
        favoriteFlashingsUidList
      )
      setFavoriteFlashingsUidList(updatedFavoritesUidList)
    }
  }

  const syncFavoritesWithDB = async (userId) => {
    const items = JSON.parse(storage.getItem(FAVORITES_STORAGE_KEY))
    if (items) {
      await syncFavorites(userId)
      storage.removeItem(FAVORITES_STORAGE_KEY)
    }
    const flashingsUid = await fetchAllFavoritesUid(userId)
    setFavoriteFlashingsUidList(flashingsUid)
  }

  const clearFavorites = () => setFavoriteFlashings([])

  return (
    <FavoritesContext.Provider
      value={{
        favoriteFlashings,
        getFavorites,
        getNextFavorites,
        hasMoreToShow,
        saveSingleFavorite,
        favoriteFlashingsUidList,
        syncFavoritesWithDB,
        clearFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  )
}

export const useFavorites = () => {
  const contextFunctions = useContext(FavoritesContext)
  return { ...contextFunctions }
}
