import React from 'react'
import { Wrapper, List, Line, NavigationButtonsWrapper } from './styles'
import Item from './Item'
import NavigationButton from './Button'
import {
  clickNext,
  clickPrevious,
  clickReset,
  setLogCardId,
} from '../../../actions'
import { store } from '../../../store'
import { i18n } from '../../../i18n'
import { isNavigationAllowed } from '../../../utils'

const NavigationScroll = () => {
  const currentPage = store.getState().pageIndex

  const handleNext = () => {
    if (currentPage === 7) {
      store.dispatch(clickReset())
      store.dispatch(setLogCardId(null))
    } else {
      store.dispatch(clickNext(currentPage))
    }
  }
  const handlePrevious = () => store.dispatch(clickPrevious(currentPage))

  const { back, next, newSearch } = i18n().navigation

  return (
    <Wrapper>
      <List>
        <Item text={i18n().pipeSize.title} position={1} />
        <Item text={i18n().pipeType.title} position={2} />
        <Item text={i18n().roofType.title} position={3} />
        <Item text={i18n().roofPitch.title} position={4} />
        <Item text={i18n().weatherProtection.title} position={5} />
        <Item text={i18n().applicationType.title} position={6} />
        <Item text={i18n().results.results} position={7} />
      </List>
      <NavigationButtonsWrapper>
        {currentPage > 1 && (
          <NavigationButton
            text={back}
            onClick={handlePrevious}
            disabled={currentPage === 1}
          />
        )}
        <NavigationButton
          text={currentPage === 7 ? newSearch : next}
          onClick={handleNext}
          disabled={!isNavigationAllowed(currentPage)}
        />
      </NavigationButtonsWrapper>
      <Line />
    </Wrapper>
  )
}

export default NavigationScroll
