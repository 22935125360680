import styled from 'styled-components'

export const RoofPitchScreenWrapper = styled.div`
  flex: 1 0;
  width: 100%;
`

export const RoofPitchScreenContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  height: 417px;
  position: relative;

  @media screen and (max-width: 1280px) {
    max-width: 500px;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-direction: column;
    margin-top: 60px;
    height: 300px;
  }

  & > div {
    &:nth-child(1) {
      max-width: 400px;
      margin: 0;
      position: absolute;
      top: -20%;
      left: 0;

      @media screen and (max-width: 1280px) {
        max-width: 300px;
      }

      @media screen and (max-width: 768px) {
        position: relative;
      }
    }

    &:nth-child(2) {
      margin-bottom: 90px;
      margin-right: 90px;

      @media screen and (max-width: 1280px) {
        margin-bottom: 100px;
        margin-right: 60px;
      }

      @media screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }
  }
`
