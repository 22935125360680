import { FiChevronLeft } from 'react-icons/fi'
import React from 'react'
import { ComponentWrapper, RoofPitchTitle, TextLine } from './styles'
import { i18n } from '../../i18n'

const Wrapper = ({ title, textLine, hasButton, onClick, step, children }) => (
  <ComponentWrapper>
    <RoofPitchTitle>
      <div>
        {hasButton && (
          <button type='button' onClick={onClick}>
            <FiChevronLeft />
          </button>
        )}
        <h2>{title}</h2>
        <span>
          {i18n().roofPitch.step} {step}/2
        </span>
      </div>
    </RoofPitchTitle>
    <TextLine>{textLine}</TextLine>
    {children}
  </ComponentWrapper>
)

export default Wrapper
