const config = {
  STORAGE_KEY_NAME: 'rpcCurrentUser',
  FAVORITES_STORAGE_KEY: 'rpc_favorite_flashings',
  PROVIDER_STORAGE_KEY: 'rpcCurrentUsercredential',
  FLASHINGS_COLLECTION_NAME: 'flashings',
  USERS_CALCULATOR_COLLECTION_NAME: 'usersCalculator',
  FAVORITE_FLASHINGS_SUBCOLLECTION_NAME: 'favoriteFlashings',
  SEARCH_HISTORY_COLLECTION_NAME: 'searchHistory',
  FIRESTORE_PROFILE_IMAGES_PATH: 'images/profiles/',
  TIMEOUT_VALUES: { appear: 100, enter: 100, exit: 85 },
  RECENT_SEARCHES_STORAGE_KEY: 'rpcRecentSearches',
  ITEMS_PER_FETCH: 20,
}

export default config
