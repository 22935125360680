import { useRef } from 'react'

const useDebounce = (incomingFunction, delay = 500) => {
  const timemoutRef = useRef(null)

  const debouncedFunction = (...args) => {
    clearTimeout(timemoutRef.current)
    timemoutRef.current = setTimeout(() => {
      incomingFunction(...args)
    }, delay)
  }
  return debouncedFunction
}

export default useDebounce
