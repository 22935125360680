import React from 'react'

const Translate = ({ color = 'currentColor' }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M17.5243 14.4517C17.4565 14.2258 17.1779 14.1201 16.8917 14.1201C16.613 14.1201 16.3343 14.2258 16.2665 14.4517L14.8129 19.189C14.8054 19.2192 14.7979 19.2493 14.7979 19.2643C14.7979 19.5054 15.1518 19.671 15.4155 19.671C15.5812 19.671 15.7092 19.6184 15.7469 19.4752L16.0329 18.4734H17.7578L18.0441 19.4752C18.0817 19.6184 18.2097 19.671 18.3754 19.671C18.639 19.671 18.993 19.4979 18.993 19.2643C18.993 19.2418 18.9855 19.2191 18.978 19.189L17.5243 14.4517ZM16.2363 17.7051L16.8915 15.3931L17.547 17.7051H16.2363Z'
        fill={color}
      />
      <path
        d='M21.4142 9.79065H14.2092V6.71338L16.5507 5.04089C16.7355 4.90887 16.8452 4.69592 16.8452 4.46887C16.8452 4.24164 16.7355 4.02869 16.5507 3.89667L14.181 2.20404C13.9958 0.958557 12.9194 0 11.6234 0H2.586C1.15997 0 0 1.15997 0 2.586V11.6235C0 13.0494 1.15997 14.2094 2.586 14.2094H9.79083V17.2866L7.44928 18.9591C7.26453 19.0911 7.15485 19.3043 7.15485 19.5313C7.15485 19.7584 7.26453 19.9715 7.44928 20.1033L9.81903 21.796C10.0042 23.0414 11.0806 24 12.3766 24H21.4142C22.84 24 24 22.8398 24 21.414V12.3766C24 10.9506 22.84 9.79065 21.4142 9.79065ZM2.586 12.8031C1.93542 12.8031 1.40625 12.2739 1.40625 11.6234V2.58582C1.40625 1.93542 1.93542 1.40625 2.586 1.40625H11.6234C12.2737 1.40625 12.8029 1.93542 12.8029 2.58582C12.8029 2.81305 12.9126 3.02618 13.0974 3.15802L14.9323 4.46869L13.0974 5.77936C12.9126 5.91138 12.8029 6.12451 12.8029 6.35156V9.79065H12.3766C10.9508 9.79065 9.79083 10.9506 9.79083 12.3765V12.8031H2.586ZM22.5938 21.414C22.5938 22.0646 22.0646 22.5938 21.4142 22.5938H12.3766C11.7263 22.5938 11.1971 22.0646 11.1971 21.414C11.1971 21.187 11.0874 20.9738 10.9026 20.842L9.06775 19.5313L10.9026 18.2206C11.0874 18.0886 11.1971 17.8755 11.1971 17.6484V12.3766C11.1971 11.7261 11.7263 11.1969 12.3766 11.1969H13.5042H13.506H13.5079H21.4142C22.0646 11.1969 22.5938 11.7261 22.5938 12.3766V21.414Z'
        fill={color}
      />
      <path
        d='M9.26501 6.48169C9.48383 6.48169 9.66144 6.30408 9.66144 6.08508C9.66144 5.86609 9.48383 5.68848 9.26501 5.68848H7.50116V4.72571C7.50116 4.50671 7.32355 4.3291 7.10455 4.3291C6.88574 4.3291 6.70813 4.50671 6.70813 4.72571V5.68848H4.94427C4.72546 5.68848 4.54785 5.86609 4.54785 6.08508C4.54785 6.30408 4.72546 6.48169 4.94427 6.48169H5.49084C5.57819 7.31903 5.93927 8.07581 6.48236 8.66193C6.0321 8.93146 5.50623 9.0871 4.94427 9.0871C4.72546 9.0871 4.54785 9.26453 4.54785 9.48352C4.54785 9.70251 4.72546 9.88013 4.94427 9.88013C5.74664 9.88013 6.49097 9.62927 7.10455 9.20264C7.71832 9.62927 8.46265 9.88013 9.26501 9.88013C9.48383 9.88013 9.66144 9.70251 9.66144 9.48352C9.66144 9.26453 9.48383 9.0871 9.26501 9.0871C8.70306 9.0871 8.17719 8.93182 7.72693 8.66193C8.27002 8.07581 8.6311 7.31903 8.71844 6.48169H9.26501ZM7.10455 8.16644C6.67078 7.71619 6.37616 7.13153 6.28992 6.48169H7.91937C7.83313 7.13153 7.53851 7.71619 7.10455 8.16644Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default Translate
