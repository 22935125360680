import styled from 'styled-components'

import trianglesBackground from '../../../images/bg-triangles.svg'

export const ForgotPassword = styled.div`
  width: 976px;
  height: 654px;
  border-radius: 15px;
  display: flex;
  overflow: hidden;
  background-color: #414141;

  & > div {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;

    &:nth-child(1) {
      height: 100%;
      display: inherit;
      align-items: center;
      flex-direction: column;
      background-color: #363636;
      padding: 0 60px;
      border-radius: 15px;
      width: 499px;

      & > div {
        top: unset;
        bottom: unset;

        &.recovery-response {
          p:nth-child(1) {
            display: inline-flex;
            align-items: center;

            svg {
              height: 52px;
              width: 52px;
            }
          }

          button {
            &.recovery-success {
              border-color: #76c439;
              color: #76c439;
            }

            &.recovery-fail {
              border-color: #ee4f4f;
              color: #ee4f4f;
            }
          }
        }
      }

      p {
        line-height: 1.1;
        color: #e5e5e5;

        &:nth-child(1) {
          font-weight: 500;
          font-size: 40px;
          width: 220px;
        }

        &:nth-child(2) {
          font-weight: 400;
          font-size: 22px;
          margin: 18px 0;
        }

        &:nth-child(3) {
          font-weight: 300;
          font-size: 16px;
          margin-bottom: 25px;
          width: 325px;
        }
      }

      form {
        input {
          height: 50px;
        }

        button {
          width: 132px;
          height: 44px;
          font-size: 20px;
          margin-top: 28px;
          border-radius: 15px;
        }
      }
    }

    &:nth-child(2) {
      align-items: center;
      padding: 0 50px;
      background-image: url(${trianglesBackground});
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      svg {
        position: absolute;
        top: 25px;
        right: 30px;
        cursor: pointer;
      }

      img {
        max-width: 379px;
        max-height: 264px;
        width: 100%;
        height: 100%;
      }
    }
  }
`
