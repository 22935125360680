import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/storage'

import { store } from '../../store'
import {
  setLoading,
  clearResults,
  saveCurrentQuery,
  queryResults,
  setCurrentUser,
  setLogCardId,
} from '../../actions'
import { result } from '../../firebase-config.json'
import {
  getStorageLocation,
  errorHandler,
  renameKey,
  fixNumber,
  isQueryEqual,
} from '../../utils'
import config from '../../rpc.config'

import { i18n } from '../../i18n'

const {
  STORAGE_KEY_NAME,
  FAVORITES_STORAGE_KEY,
  USERS_CALCULATOR_COLLECTION_NAME,
  FAVORITE_FLASHINGS_SUBCOLLECTION_NAME,
  FLASHINGS_COLLECTION_NAME,
  SEARCH_HISTORY_COLLECTION_NAME,
  FIRESTORE_PROFILE_IMAGES_PATH,
  ITEMS_PER_FETCH,
} = config
const storage = getStorageLocation()

// Initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(result.sdkConfig)
} else {
  firebase.app()
}

const isFlashingWithin = (preParseQuery, preParseFlashing) => {
  const queryValue = parseInt(preParseQuery, 10)
  const flashingValue = parseInt(preParseFlashing, 10)

  return queryValue <= flashingValue && flashingValue > 0
}

export const searchFlashings = async (originalQuery, userId) => {
  const query = { ...originalQuery }
  delete query.flashingType
  query.pipeSizeMm = fixNumber(query.pipeSizeMm)
  try {
    store.dispatch(setLoading(true))
    store.dispatch(clearResults())
    const ref = firebase.firestore().collection(FLASHINGS_COLLECTION_NAME)
    await ref
      .where('isDraft', '==', false)
      .where(query.pipeType, '==', true)
      .where(query.roofType, '==', true)
      .where(query.weatherProtection, '==', true)
      .where(query.applicationType, '==', true)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const flashing = doc.data()
          if (
            query.pipeSizeMm >= flashing.minPipeSizeMm &&
            query.pipeSizeMm <= flashing.maxPipeSizeMm &&
            (query.roofPitch === '0/12'
              ? flashing.roofPitch === query.roofPitch
              : isFlashingWithin(query.roofPitch, flashing.roofPitch))
          ) {
            store.dispatch(queryResults(flashing))
          }
        })
      })
    if (Object.keys(store.getState().result).length && userId)
      await getArrayOfFavorites(userId)
    await logFunction(userId, query)
    store.dispatch(saveCurrentQuery(originalQuery))
    store.dispatch(setLoading(false)) //
  } catch (error) {
    throw errorHandler(error)
  }
}

const logFunction = async (userId, query) => {
  if (!store.getState().currentUser) return
  if (!Object.values(store.getState().currentQuery).length)
    await getLastSearch()
  if (isQueryEqual()) return
  const dateAdded = firebase.firestore.FieldValue.serverTimestamp()
  const { logCardId } = store.getState()
  const ref = firebase
    .firestore()
    .collection(USERS_CALCULATOR_COLLECTION_NAME)
    .doc(userId)
    .collection(SEARCH_HISTORY_COLLECTION_NAME)
  if (!logCardId) {
    const doc = ref.doc()
    await doc.set({ query, dateAdded, uid: doc.id, type: 'calculationLog' })
  } else {
    const doc = ref.doc(logCardId)
    await doc.update({ dateAdded })
    store.dispatch(setLogCardId(''))
  }
}

const getLastSearch = async () => {
  const logs = await firebase
    .firestore()
    .collection(USERS_CALCULATOR_COLLECTION_NAME)
    .doc(store.getState().currentUser.uid)
    .collection(SEARCH_HISTORY_COLLECTION_NAME)
    .orderBy('dateAdded', 'desc')
    .limit(1)
    .get()
  if (logs.size > 0) {
    let lastItem
    logs.forEach((item) => (lastItem = item.data().query))
    store.dispatch(saveCurrentQuery(lastItem))
  }
}

export const retrieveUser = async (uid) => {
  const ref = firebase
    .firestore()
    .collection(USERS_CALCULATOR_COLLECTION_NAME)
    .doc(uid)
  const user = await ref.get()
  const favorites = await ref
    .collection(FAVORITE_FLASHINGS_SUBCOLLECTION_NAME)
    .get()
  const favoriteFlashings = []
  favorites.forEach((fav) => favoriteFlashings.push(fav.data().uid))
  return { ...user.data(), favoriteFlashings }
}

export const logEvents = (query) => {
  firebase.analytics().logEvent('view_search_results', { search_term: query })
  firebase
    .analytics()
    .logEvent('pipe_size_in_inches', { value: query.pipeSizeIn })
  firebase
    .analytics()
    .logEvent('pipe_size_in_millimeters', { value: query.pipeSizeMm })
  firebase
    .analytics()
    .logEvent('pipe_type', { type: query.pipeType.substring(2) })
  firebase
    .analytics()
    .logEvent('roof_type', { value: query.roofType.substring(2) })
  firebase.analytics().logEvent('roof_pitch', { value: query.roofPitch })
  firebase.analytics().logEvent('weather_protection', {
    value: query.weatherProtection.substring(2),
  })
  firebase
    .analytics()
    .logEvent('application_type', { type: query.applicationType.substring(2) })
}

export const getSearchHistory = async (uid, startAfter = undefined) => {
  try {
    let searches
    if (startAfter) {
      searches = await firebase
        .firestore()
        .collection('usersCalculator')
        .doc(uid)
        .collection('searchHistory')
        .orderBy('dateAdded', 'desc')
        .startAfter(startAfter.dateAdded)
        .limit(10)
        .get()
    } else {
      searches = await firebase
        .firestore()
        .collection('usersCalculator')
        .doc(uid)
        .collection('searchHistory')
        .orderBy('dateAdded', 'desc')
        .limit(10)
        .get()
    }
    const resultDisplay = []
    searches.forEach((search) => resultDisplay.push(search.data()))
    return resultDisplay
  } catch (error) {
    throw errorHandler(error)
  }
}

const addToSessionOrLocalStorage = (content) => {
  const isUserIsOnSessionStorage = Object.keys(sessionStorage).some((key) =>
    key.includes('firebase:authUser')
  )
  if (isUserIsOnSessionStorage) {
    const previous = JSON.parse(sessionStorage.getItem(STORAGE_KEY_NAME))
    sessionStorage.setItem(
      STORAGE_KEY_NAME,
      JSON.stringify({ ...previous, ...content })
    )
  } else {
    const previous = JSON.parse(localStorage.getItem(STORAGE_KEY_NAME))
    localStorage.setItem(
      STORAGE_KEY_NAME,
      JSON.stringify({ ...previous, ...content })
    )
  }
}

// Update a value on user's both Firestore and Auth entries
export const updateProfileValue = async (newContent) => {
  // keyName is used when the key value on Firebase has a different name than it has on our app,
  // e.g. displayName and photoURL (which are called name and photoUrl) respectively
  const user = firebase.auth().currentUser
  try {
    await firebase.firestore().runTransaction(async (transaction) => {
      const docRef = firebase
        .firestore()
        .collection('usersCalculator')
        .doc(user.uid)
      return await transaction.get(docRef).then(async (doc) => {
        if (!doc.exists)
          throw new Error({
            message: i18n().errorHandlerMessages.documentDoesntExit,
          })
        transaction.update(docRef, newContent)
        const contentForAuth = renameKey(newContent)
        await user.updateProfile(contentForAuth)
        store.dispatch(setCurrentUser(newContent))
        addToSessionOrLocalStorage(newContent)
      })
    })
  } catch (error) {
    throw errorHandler(error)
  }
}

// Update a value on user's Firestore entry only
export const updateUserInfoOnFirestore = async (id, newContent) => {
  await firebase
    .firestore()
    .collection('usersCalculator')
    .doc(id)
    .update(newContent)
  store.dispatch(setCurrentUser(newContent))
  addToSessionOrLocalStorage(newContent)
}

// Update profile picture
export const uploadProfilePicture = async (file, userId) => {
  try {
    const content = await firebase
      .storage()
      .ref(FIRESTORE_PROFILE_IMAGES_PATH + userId)
      .put(file)
    const imageUrl = await content.ref.getDownloadURL()
    const newDataObj = { photoUrl: imageUrl }
    await updateProfileValue(newDataObj)
    addToSessionOrLocalStorage(newDataObj)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const saveFavorites = async (
  flashing,
  isFavorite,
  userId,
  incomingFlashings
) => {
  try {
    const ref = firebase
      .firestore()
      .collection(USERS_CALCULATOR_COLLECTION_NAME)
      .doc(userId)
      .collection(FAVORITE_FLASHINGS_SUBCOLLECTION_NAME)
      .doc(flashing)
    const favoriteFlashings = [...incomingFlashings]
    if (!isFavorite) {
      await ref.delete()
      favoriteFlashings.splice(favoriteFlashings.indexOf(flashing), 1)
    } else {
      await ref.set({
        uid: flashing,
        dateAdded: firebase.firestore.FieldValue.serverTimestamp(),
      })
      favoriteFlashings.push(flashing)
    }
    return favoriteFlashings
  } catch (error) {
    throw errorHandler(error)
  }
}

const getArrayOfFavorites = async (userId, startAfter = undefined) => {
  let storedFlashings
  if (!userId) {
    const storedFlashingsArray = storage.getItem(FAVORITES_STORAGE_KEY)
      ? JSON.parse(storage.getItem(FAVORITES_STORAGE_KEY))
      : []
    if (startAfter) {
      const lastItemIndex = storedFlashingsArray.findIndex(
        (item) => item.uid === startAfter
      )
      storedFlashings = storedFlashingsArray.slice(
        lastItemIndex + 1,
        ITEMS_PER_FETCH + lastItemIndex
      )
    } else {
      storedFlashings = storedFlashingsArray.slice(0, ITEMS_PER_FETCH)
    }
  } else {
    const ref = firebase
      .firestore()
      .collection(USERS_CALCULATOR_COLLECTION_NAME)
      .doc(userId)
      .collection(FAVORITE_FLASHINGS_SUBCOLLECTION_NAME)
      .orderBy('dateAdded', 'desc')
    if (startAfter) {
      storedFlashings = await ref
        .startAfter(startAfter)
        .limit(ITEMS_PER_FETCH)
        .get()
    } else {
      storedFlashings = await ref.limit(ITEMS_PER_FETCH).get()
    }
  }
  const favorites = []
  storedFlashings.forEach((flash) =>
    favorites.push(!userId ? flash.uid : flash.data().uid)
  )
  return !favorites.length
    ? { favorites }
    : {
        favorites,
        lastFavorite: !userId
          ? storedFlashings[storedFlashings.length - 1].uid
          : storedFlashings.docs[storedFlashings.docs.length - 1],
      }
}

export const syncFavorites = async (uid) => {
  const items = JSON.parse(storage.getItem(FAVORITES_STORAGE_KEY))
  if (!items) return
  try {
    const { favorites } = await getArrayOfFavorites(uid)
    const differentFlashings = items.filter(
      (flashing) => !favorites.includes(flashing.uid)
    )
    const batch = firebase.firestore().batch()
    await differentFlashings.forEach((favorite) => {
      const ref = firebase
        .firestore()
        .collection(USERS_CALCULATOR_COLLECTION_NAME)
        .doc(uid)
        .collection(FAVORITE_FLASHINGS_SUBCOLLECTION_NAME)
        .doc(favorite.uid)
      batch.set(ref, {
        uid: favorite.uid,
        dateAdded: firebase.firestore.Timestamp.fromDate(
          new Date(favorite.dateAdded)
        ),
      })
    })
    await batch.commit()
  } catch (error) {
    throw errorHandler(error)
  }
}

export const fetchFavorites = async (userId, startAfter) => {
  try {
    const { favorites, lastFavorite } = await getArrayOfFavorites(
      userId,
      startAfter
    )
    if (!favorites.length) return { results: [], lastFavorite: undefined }
    const results = []
    const basis = Math.ceil(favorites.length / 10)
    /* eslint-disable no-await-in-loop */
    for (let index = 1; index <= basis; index++) {
      const divisor = 10 * index
      const step = 10 * (index - 1)
      const sliced = favorites.slice(step, divisor)
      const flashings = await firebase
        .firestore()
        .collection(FLASHINGS_COLLECTION_NAME)
        .where('uid', 'in', sliced)
        .get()
      flashings.forEach((flashing) => results.push(flashing.data()))
    }
    results.sort((a, b) => favorites.indexOf(a.uid) - favorites.indexOf(b.uid))
    return { results, lastFavorite }
  } catch (error) {
    throw errorHandler(error)
  }
}

export const fetchAllFavoritesUid = async (userId) => {
  try {
    const flashings = await firebase
      .firestore()
      .collection(USERS_CALCULATOR_COLLECTION_NAME)
      .doc(userId)
      .collection(FAVORITE_FLASHINGS_SUBCOLLECTION_NAME)
      .get()
    const result = []
    flashings.forEach((flashing) => result.push(flashing.data().uid))
    return result
  } catch (error) {
    throw errorHandler(error)
  }
}
