import React, { useState } from 'react'
import { FiUser } from 'react-icons/fi'
import Base from '../Base'
import Input from '../../Input'
import Loading from '../../Loading'

import { store } from '../../../store'
import { i18n } from '../../../i18n'
import { updateProfileValue } from '../../../services/firebase/firestore'
import { showErrorMessage } from '../../../utils'

const NameLine = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [newName, setNewName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentName = store.getState().currentUser.name

  const handleClose = () => {
    setNewName('')
    setIsEditing(false)
  }

  const handleUpdate = async () => {
    setIsLoading(true)
    try {
      await updateProfileValue({ name: newName })
      setIsEditing(false)
    } catch (error) {
      showErrorMessage(error)
    }
    setIsLoading(false)
  }

  const handleEnter = () => {
    currentName && setNewName(currentName)
    setIsEditing(true)
  }

  return (
    <>
      {isLoading && <Loading showBackground />}
      <Base
        icon={<FiUser />}
        label={i18n().profile.name}
        isEditing={isEditing}
        handleUpdate={handleUpdate}
        handleClose={handleClose}
        handleEnter={handleEnter}
        disabled={!newName.length || newName === currentName}
      >
        {isEditing ? (
          <Input
            type='text'
            value={newName}
            sizeAnchor={
              currentName !== newName ? newName.length : currentName.length
            }
            onChange={(e) => setNewName(e.target.value)}
            disabled={isLoading}
          />
        ) : (
          <span>{currentName || i18n().profile.noName}</span>
        )}
      </Base>
    </>
  )
}

export default NameLine
