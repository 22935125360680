import React, { useState, useEffect } from 'react'
import { FiHeart } from 'react-icons/fi'
import {
  ResultCard,
  ImageWrapper,
  InfoWrapper,
  CardTitle,
  CardSKU,
} from './styles'
import DetailedResultCard from './DetailedResultCard'

import { store } from '../../store'
import { setErrorMessage } from '../../actions'

import { useFavorites } from '../../contexts/FavoritesContext'

import config from '../../rpc.config'

const { FAVORITES_STORAGE_KEY } = config

const ResultCardComponent = ({ data }) => {
  const [isShowingDetails, setShowingDetails] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const { favoriteFlashingsUidList, saveSingleFavorite } = useFavorites()

  const { imageUrl, name, sku, uid, objectID } = data
  const id = uid || objectID
  const { currentUser } = store.getState()

  useEffect(() => {
    const favorites = currentUser
      ? favoriteFlashingsUidList
      : localStorage.getItem(FAVORITES_STORAGE_KEY)
    if (favorites) {
      const isItFavorite = favorites.includes(id)
      setIsFavorite(isItFavorite)
    }
  }, [id, favoriteFlashingsUidList])

  const handleClickFavorite = async (e) => {
    e.stopPropagation()
    const userId = currentUser ? currentUser.uid : null
    try {
      setIsFavorite(!isFavorite)
      // Must send the inverted value if isFavorte to saveSingleFavorite as hooks state changes are asynchronous
      await saveSingleFavorite(userId, id, !isFavorite)
    } catch (error) {
      store.dispatch(setErrorMessage(error))
    }
  }

  return (
    <ResultCard isFavorite={isFavorite} onClick={() => setShowingDetails(true)}>
      {store.getState().deviceType === 'DESKTOP' && (
        <FiHeart onClick={handleClickFavorite} />
      )}
      <ImageWrapper>
        <img src={imageUrl} alt={name} />
      </ImageWrapper>
      <InfoWrapper>
        <CardTitle title={name}>{name}</CardTitle>
        <CardSKU title={sku}>{sku}</CardSKU>
      </InfoWrapper>
      <DetailedResultCard
        data={data}
        show={isShowingDetails}
        toggleHandler={setShowingDetails}
        isFavorite={isFavorite}
        favoriteHanlder={handleClickFavorite}
      />
    </ResultCard>
  )
}

export default ResultCardComponent
