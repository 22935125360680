import React, { useEffect, useState } from 'react'
import Generic from '../Generic'
import { PasswordChange } from './styles'
import Input from '../../Input'

import { i18n } from '../../../i18n'

const PassowrdChangeComponent = ({ show, toggle, onSubmit }) => {
  const [currentPassword, setCurrentPassword] = useState('')

  const [newPassword, setNewPassword] = useState('')
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('')

  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
  const [passwordRepeatErrorMsg, setPasswordRepeatErrorMsg] = useState('')

  useEffect(() => {
    if (newPassword) {
      if (newPassword.length < 6) {
        setPasswordErrorMsg(i18n().login.errorMessages.password)
      } else if (newPassword === currentPassword) {
        setPasswordErrorMsg(i18n().profile.passwordUpdate.passwordsAreEqual)
      } else {
        setPasswordErrorMsg('')
      }
    }
    if (newPasswordRepeat)
      setPasswordRepeatErrorMsg(
        newPasswordRepeat !== newPassword
          ? i18n().login.errorMessages.passwordRepeat
          : ''
      )
  }, [newPassword, newPasswordRepeat, currentPassword])

  const handleClose = () => {
    setCurrentPassword('')
    setNewPassword('')
    setPasswordErrorMsg('')
    setNewPasswordRepeat('')
    setPasswordRepeatErrorMsg('')
  }

  const disabledRule =
    !currentPassword.length ||
    newPassword.length < 6 ||
    currentPassword === newPassword ||
    newPassword !== newPasswordRepeat

  return (
    <Generic
      show={show}
      onExited={handleClose}
      closable
      toggle={toggle}
      onSubmit={() => onSubmit(currentPassword, newPassword)}
      disabled={disabledRule}
    >
      <PasswordChange>
        <Input
          id='current-password'
          label={i18n().profile.passwordUpdate.currentPassword}
          type='password'
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <Input
          id='new-password'
          label={i18n().profile.passwordUpdate.newPassword}
          type='password'
          onChange={(e) => setNewPassword(e.target.value)}
          error={passwordErrorMsg}
        />
        <Input
          id='new-password-repeat'
          label={i18n().profile.passwordUpdate.newPasswordRepeat}
          type='password'
          onChange={(e) => setNewPasswordRepeat(e.target.value)}
          error={passwordRepeatErrorMsg}
        />
      </PasswordChange>
    </Generic>
  )
}

export default PassowrdChangeComponent
