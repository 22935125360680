import firebase from 'firebase/app'
import 'firebase/functions'
import { result } from '../../firebase-config.json'

// Initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(result.sdkConfig)
} else {
  firebase.app()
}

export const sendEmail = async (values, url) => {
  const sendEmailCloudFunction = firebase.functions().httpsCallable('sendEmail')
  await sendEmailCloudFunction({ values, url })
}
