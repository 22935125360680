import React from 'react'
import { TransitionGroup } from 'react-transition-group'
import { Wrapper } from './styles'
import Tip from '../Tooltips/Tip'
import { store } from '../../store'
import { i18n } from '../../i18n'
import { useHelpTips } from '../../contexts/HelpTipsContext'

import tips from './config.json'

const TipsArea = () => {
  const { pageIndex } = store.getState()
  const { hiddenTips } = useHelpTips()

  return (
    <Wrapper>
      <TransitionGroup className='animation-wrapper'>
        <div className='tip-wrapper'>
          {tips.map((item) => (
            <Tip
              id={item.id}
              text={i18n().tips[item.textRef]}
              show={
                item.pages.includes(pageIndex) && !hiddenTips.includes(item.id)
              }
            />
          ))}
        </div>
      </TransitionGroup>
    </Wrapper>
  )
}

export default TipsArea
