import React, { useEffect, useState, useRef } from 'react'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import {
  About,
  AboutModalWrapper,
  MenuItemWrapper,
  SecondContentWrapper,
  MenuContentTitle,
  SecondContentText,
  AppVersionModalWrapper,
  AppVersionMenu,
  VersionWrapper,
} from './styles'
import InfoModal from '../../InfoModal'
import { i18n } from '../../../i18n'

const AppVersionModal = () => {
  const [shouldShowModal, toggleModal] = useState(false)
  const [currentPanel, setCurrentPanel] = useState('MENU')
  const [componentHeight, setComponentHeight] = useState(0)

  const textRef = useRef()

  useEffect(() => {
    if (!shouldShowModal) setCurrentPanel('MENU')
    if (textRef.current)
      return textRef.current.innerText.length > 52
        ? setComponentHeight(126)
        : setComponentHeight(114)
  }, [shouldShowModal])

  return (
    <About active={shouldShowModal}>
      <span onClick={() => toggleModal(!shouldShowModal)}>
        {i18n().navbar.about}
      </span>
      <InfoModal trigger={shouldShowModal} handler={toggleModal}>
        <AboutModalWrapper>
          <AppVersionModalWrapper
            className={`showing-${currentPanel}`}
            height={componentHeight}
          >
            <AppVersionMenu isOpen={currentPanel === 'MENU'}>
              <MenuItem
                title={i18n().aboutModal.privacyPolicy.title}
                clickEvent={() => setCurrentPanel('POLICY')}
              />
              <MenuItem
                title={i18n().aboutModal.contactUs.title}
                clickEvent={() => setCurrentPanel('CONTACT')}
              />
              <VersionWrapper className='version-wrapper'>
                <p ref={textRef}>
                  <a
                    href='https://sauvara.com/'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Sauvara Inc.
                  </a>
                </p>
                <p>
                  <span>
                    {`  ${i18n().aboutModal.versionText}${
                      process.env.REACT_APP_VERSION
                    }`}
                  </span>
                </p>
              </VersionWrapper>
            </AppVersionMenu>
            <SecondContent
              title={i18n().aboutModal.privacyPolicy.title}
              style={{ position: 'absolute' }}
              clickEvent={() => setCurrentPanel('MENU')}
              trigger={currentPanel === 'POLICY'}
            >
              {i18n().aboutModal.privacyPolicy.content.map((line, i) => (
                <p key={`pp_line_${i + 1}`}>{line}</p>
              ))}
            </SecondContent>
            <SecondContent
              title={i18n().aboutModal.contactUs.title}
              clickEvent={() => setCurrentPanel('MENU')}
              trigger={currentPanel === 'CONTACT'}
            >
              {i18n().aboutModal.contactUs.content.map((line, i) => (
                <p key={`contact_line_${i + 1}`}>{line}</p>
              ))}
            </SecondContent>
          </AppVersionModalWrapper>
        </AboutModalWrapper>
      </InfoModal>
    </About>
  )
}

const MenuItem = ({ title, clickEvent }) => (
  <MenuItemWrapper onClick={clickEvent}>
    <span>{title}</span>
    <FiChevronRight />
  </MenuItemWrapper>
)

const SecondContent = ({ children, title, clickEvent, trigger, ...rest }) => (
  <SecondContentWrapper isShowing={trigger} {...rest}>
    <MenuContentTitle onClick={clickEvent}>
      <FiChevronLeft style={{ marginRight: '10px' }} />
      <span>{title}</span>
    </MenuContentTitle>
    <SecondContentText>{children}</SecondContentText>
  </SecondContentWrapper>
)

export default AppVersionModal
