import React, { useState } from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import PhoneInput from 'react-phone-input-2'
import { InputWrapper, Label, InputField, PhoneInputWrapper } from './styles'
import 'react-phone-input-2/lib/style.css'
import { i18n } from '../../i18n'

const InputComponent = ({
  label,
  id,
  type,
  placeholder,
  onChange,
  onBlur,
  error,
  sizeAnchor = false,
  optional = false,
  ...rest
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  return (
    <InputWrapper inputType={type}>
      {label && (
        <Label htmlFor={id}>
          {label}
          <span>{optional && ` (${i18n().inputTexts.generic.optional})`}</span>
        </Label>
      )}
      {type === 'tel' ? (
        <PhoneInputWrapper>
          <PhoneInput
            country='us'
            {...rest}
            inputProps={{ name: 'phone' }}
            onChange={onChange}
          />
        </PhoneInputWrapper>
      ) : (
        <InputField
          id={id}
          type={
            type === 'password'
              ? isPasswordVisible
                ? 'text'
                : 'password'
              : type
          }
          sizeAnchor={sizeAnchor}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          hasError={!!error}
          {...rest}
        />
      )}
      {type === 'password' &&
        (isPasswordVisible ? (
          <FiEye onClick={() => setPasswordVisible(false)} />
        ) : (
          <FiEyeOff onClick={() => setPasswordVisible(true)} />
        ))}
      {error && <span>{error}</span>}
    </InputWrapper>
  )
}

export default InputComponent
