// This component renders some text that, when hovered over or cliked, displays the Helper.js component. Both
// Tooltip.js and Helper.js must be used together to achieve the desired result. The content passed as attribute
// to this component is used by Helper.js. On desktop view the interaction to make the tooltip appear is hover
// the cursor over the text. On mobile view the trigger is to click the text.

import React, { Component } from 'react'
import './Tooltip.css'

import { store } from '../../store'
import {
  setCurrentHelper,
  getHelperCoordinate,
  shouldRenderHelper,
} from '../../actions'

import Helper from './Helper'

class Tooltip extends Component {
  ref = React.createRef()

  componentDidMount() {
    const width = this.ref.current.getBoundingClientRect().left
    store.dispatch(getHelperCoordinate(width))
    window.addEventListener('resize', this.getCoordinate)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getCoordinate)
  }

  getCoordinate = () => {
    const width = this.ref.current.getBoundingClientRect().left
    store.dispatch(getHelperCoordinate(width))
  }

  handleMouseEnter = () => {
    store.dispatch(setCurrentHelper(this.ref.current.id))
  }

  handleMouseOut = () => {
    store.dispatch(setCurrentHelper(null))
  }

  handleMouseClick = () => {
    store.dispatch(shouldRenderHelper())
    store.dispatch(setCurrentHelper(this.ref.current.id))
  }

  render() {
    const { content, id, children } = this.props
    return (
      <span className='helper' ref={this.ref} id={id}>
        <span
          onPointerOver={
            store.getState().deviceWidth > 768 ? this.handleMouseEnter : null
          }
          onPointerOut={
            store.getState().deviceWidth > 768 ? this.handleMouseOut : null
          }
          onClick={
            store.getState().deviceWidth > 768 ? null : this.handleMouseClick
          }
          className='helper-text'
        >
          {children}
        </span>
        <Helper content={content} id={id} />
      </span>
    )
  }
}

export default Tooltip
