import React, { useRef, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'

const InfoModal = ({ children, trigger, handler }) => {
  const ref = useRef()

  const handleClickOutside = useCallback(
    (e) => {
      if (!ref.current.contains(e.target)) {
        handler(false)
        document.removeEventListener('click', handleClickOutside)
      }
    },
    [handler]
  )

  useEffect(() => {
    if (trigger) document.addEventListener('click', handleClickOutside)
  }, [trigger, handleClickOutside])

  useEffect(() => {
    handler(false)
    document.removeEventListener('click', handleClickOutside)
  }, [handler, handleClickOutside])

  return (
    <div ref={ref}>
      <CSSTransition
        in={trigger}
        mountOnEnter
        unmountOnExit
        timeout={500}
        classNames='tool'
      >
        {children}
      </CSSTransition>
    </div>
  )
}

export default InfoModal
