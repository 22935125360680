import React from 'react'
import { ListItem, Text, Circle, Dot } from './styles'
import { store } from '../../../../store'
import { clickToGoToPage } from '../../../../actions'
import { isQueryEqual } from '../../../../utils'

const Item = ({ text, position }) => {
  const currentPage = store.getState().pageIndex

  const queryValues = []
  for (const prop in store.getState().query) {
    queryValues.push(prop)
  }

  const handleNavigation = () => {
    if (
      currentPage === position ||
      (position === 7 && !isQueryEqual()) ||
      !store.getState().query[queryValues[position]]
    )
      return
    store.dispatch(clickToGoToPage(position))
  }

  const isFilled = () => {
    if (position === 7)
      return Object.entries(store.getState().result).length > 0
    return store.getState().query[queryValues[position]]
  }

  return (
    <ListItem
      onClick={handleNavigation}
      isClickable={position === currentPage || isFilled()}
    >
      <Text isActive={position === currentPage}>{text}</Text>
      <Circle isActive={position === currentPage}>{position}</Circle>
      <Dot isFilled={isFilled()} />
    </ListItem>
  )
}

export default Item
