import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Nav, NavbarWrapper, AlertWrapper } from './styles'
import SearchBox from './SearchBox'
import AuthArea from './AuthArea'
import About from './About'
import { i18n } from '../../i18n'

import BellIcon from '../CustomIcons/Bell'
import LanguageSelector from './LanguageSelector'

const Navbar = ({ companyLogo }) => {
  const location = useLocation()
  return (
    <Nav>
      <NavbarWrapper>
        <NavLink to='/'>
          <img
            src={companyLogo}
            title='Roof Pitch Calculator'
            alt='Roof Pitch Calculator logo'
          />
        </NavLink>
        <ul>
          <li>
            <NavLink to='/history' activeClassName='active-link'>
              {i18n().navbar.history}
            </NavLink>
          </li>
          <li>
            <NavLink to='/favorites' activeClassName='active-link'>
              {i18n().navbar.favorties}
            </NavLink>
          </li>
          <li>
            <About />
          </li>
        </ul>
        <SearchBox />
        <NavLink to='/alerts'>
          <AlertWrapper>
            <BellIcon
              color={location.pathname === '/alerts' ? '#CFB26C' : '#E5E5E5'}
            />
          </AlertWrapper>
        </NavLink>
        <LanguageSelector />
        <AuthArea />
      </NavbarWrapper>
    </Nav>
  )
}

export default Navbar
