import React, { useState } from 'react'
import { Selector, Options } from './styles'
import { i18n } from '../../../i18n'
import InfoModal from '../../InfoModal'
import { changeLanguage } from '../../../utils'
import Translate from '../../CustomIcons/Translate'
import { store } from '../../../store'

const LanguageSelector = () => {
  const [isPopupOpen, togglePopup] = useState(false)

  return (
    <Selector>
      <span onClick={() => togglePopup(!isPopupOpen)}>
        <Translate color={isPopupOpen ? '#CFB26C' : '#E5E5E5'} />
      </span>
      <InfoModal trigger={isPopupOpen} handler={togglePopup}>
        <Options>
          <p
            className={store.getState().lang === 'en' ? 'active' : ''}
            onClick={() => changeLanguage('en')}
          >
            {i18n().languages.en}
          </p>
          <p
            className={store.getState().lang === 'es' ? 'active' : ''}
            onClick={() => changeLanguage('es')}
          >
            {i18n().languages.es}
          </p>
          <p
            className={store.getState().lang === 'pl' ? 'active' : ''}
            onClick={() => changeLanguage('pl')}
          >
            {i18n().languages.pl}
          </p>
        </Options>
      </InfoModal>
    </Selector>
  )
}

export default LanguageSelector
