import React from 'react'
import {
  Card,
  ImageWrapper,
  TextWrapper,
} from '../../../../components/PageCard/styles'

import { store } from '../../../../store'
import { setValue, setDropdownScreen, clickNext } from '../../../../actions'

const PageCard = ({ img, label, name, value }) => {
  const { images, pageIndex } = store.getState()
  const isActive = store.getState().query[name] === value

  const handleClick = () => {
    store.dispatch(setValue(name, value))
    store.dispatch(
      value !== '0/12' ? setDropdownScreen(true) : clickNext(pageIndex)
    )
  }

  return (
    <Card active={isActive} onClick={handleClick}>
      <ImageWrapper>
        <img src={images[`${img}.png`]} alt={name} />
      </ImageWrapper>
      <TextWrapper>
        <span>{label}</span>
      </TextWrapper>
    </Card>
  )
}

export default PageCard
