import styled from 'styled-components'

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* background-color: ${({ isClickable }) =>
    isClickable ? 'pink' : 'red'}; */

  @media screen and (max-width: 1280px) {
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    /* cursor: pointer; */
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'not-allowed')};
  }
`

export const Text = styled.span`
  font-weight: 500;
  font-size: 15px;
  line-height: 1.1;
  color: ${({ isActive }) =>
    isActive ? '#E5E5E5' : 'rgba(229, 229, 229, 0.21)'};
  margin-right: 30px;
  transition: color 0.2s ease-in;
  flex: 1 0;

  @media screen and (max-width: 1280px) {
    font-size: 11px;
    margin-right: 24px;
  }
`

export const Circle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#949494' : '#575656')};
  font-weight: 500;
  font-size: 18px;
  color: ${({ isActive }) => (isActive ? '#575656' : '#949494')};
  transition: background-color 0.2s ease-in, color 0.2s ease-in;

  @media screen and (max-width: 1280px) {
    width: 32px;
    height: 32px;
    font-size: 14px;
  }
`

export const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ isFilled }) => (isFilled ? '#949494' : '#343434')};
  border: 1px solid #575757;
  transition: background-color 0.2s ease-in;
  margin-left: 14px;
  border-radius: 50%;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    width: 7px;
    height: 7px;
    margin-left: 10px;
  }
`
