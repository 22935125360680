import React from 'react'
import Lottie from 'react-lottie'
import { Loading } from './styles'
import animationData from './animation.json'

const LoadingComponent = ({ showBackground, full = true }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  }

  return (
    <Loading showBackground={showBackground} full={full}>
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
        isStopped={false}
        isPaused={false}
      />
    </Loading>
  )
}

export default LoadingComponent
