const reducers = (state, action) => {
  switch (action.type) {
    case 'CLICK_TO_GO_TO_PAGE':
    case 'CLICK_NEXT':
    case 'CLICK_PREVIOUS':
      return {
        ...state,
        pageIndex: action.newIndex,
      }
    case 'SET_PAGES_LENGTH':
      return {
        ...state,
        pagesLength: action.pagesLength,
      }
    case 'CLICK_RESET':
      return {
        ...state,
        pageIndex: action.payload.newIndex,
        pagesLength: 6,
        query: action.payload.query,
        roofPitchScreen: 1,
        roofPitchDropdown: {
          ...state.roofPitchDropdown,
          currentValue: action.payload.roofPitchDropdownValue,
        },
        showDropdownScreen: false,
        result: action.payload.result,
      }
    case 'SET_VALUE':
      const { name, value } = action.payload
      return {
        ...state,
        query: {
          ...state.query,
          [name]: value,
        },
      }
    case 'SET_PIPE_SIZE':
      return {
        ...state,
        query: {
          ...state.query,
          pipeSizeIn: action.payload.pipeSizeIn,
          pipeSizeMm: action.payload.pipeSizeMm,
        },
        pipeSizeInput: {
          ...state.pipeSizeInput,
          pipeSizeIn: {
            ...state.pipeSizeInput.pipeSizeIn,
            isValid: true,
          },
          pipeSizeMm: {
            ...state.pipeSizeInput.pipeSizeMm,
            isValid: true,
          },
        },
      }
    case 'SET_DROPDOWN':
      const { isOpen, currentValue } = action.payload
      const dbValue = currentValue.split(' ')[0]
      return {
        ...state,
        roofPitchDropdown: {
          ...state.roofPitchDropdown,
          isOpen,
          currentValue,
        },
        query: {
          ...state.query,
          roofPitch: dbValue,
        },
      }
    case 'TOGGLE_DROPDOWN':
      return {
        ...state,
        roofPitchDropdown: {
          ...state.roofPitchDropdown,
          isOpen: action.isOpen,
        },
      }
    case 'QUERY_RESULTS':
      // console.log(Object.keys(state.result[action.result.sku]).length)
      return {
        ...state,
        result: [...state.result, action.result],
        // result: {
        //   ...state.result,
        //   [action.result.sku]: {
        //     ...state.result[action.result.sku],
        //     // [state.result[action.result.sku].length]: action.result
        //     // [action.result.uid]: action.result
        //     [state.result[action.result.sku]
        //       ? Object.keys(state.result[action.result.sku]).length
        //       : '0']: action.result,
        //   },
        // },
      }
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case 'SAVE_CURRENT':
      return {
        ...state,
        currentQuery: action.currentQuery,
      }
    case 'CLEAR_RESULTS':
      return {
        ...state,
        result: action.result,
      }
    case 'SET_RESULT_SCROLL':
      return {
        ...state,
        resultScroll: action.resultScroll,
      }
    case 'UPDATE_RESULTS':
      const { flashing, position } = action.payload
      const newResult = [...state.result]
      newResult.splice(position, 1, flashing)
      return {
        ...state,
        result: newResult,
      }
    case 'REMOVE_FLASHING':
      const resultAfterRemoval = [...state.result]
      resultAfterRemoval.splice(action.id, 1)
      return {
        ...state,
        result: resultAfterRemoval,
      }
    case 'RENDER_POPUP':
      return {
        ...state,
        renderPopup: action.renderPopup,
      }
    case 'CHECK_LOGIN':
      return {
        ...state,
        isUserLoggedIn: action.isUserLoggedIn,
      }
    case 'SET_ACTIVE_INPUT':
    case 'CLEAR_ACTIVE_INPUT':
      const { input, isActive } = action.payload
      return {
        ...state,
        pipeSizeInput: {
          ...state.pipeSizeInput,
          [input]: {
            ...state.pipeSizeInput[input],
            isActive,
          },
        },
      }
    case 'VALIDATE_ACTIVE_INPUT':
      const { isValid } = action.payload
      return {
        ...state,
        pipeSizeInput: {
          ...state.pipeSizeInput,
          [action.payload.input]: {
            ...state.pipeSizeInput[action.payload.input],
            isValid,
          },
        },
      }
    case 'CLOSE_TOOLTIP':
      return {
        ...state,
        pipeSizeInput: {
          ...state.pipeSizeInput,
          [action.input]: {
            ...state.pipeSizeInput[action.input],
            isValid: null,
          },
        },
      }
    case 'SET_CURRENT_VALUE_WARNING': {
      return {
        ...state,
        pipeSizeInput: {
          ...state.pipeSizeInput,
          [action.payload.input]: {
            ...state.pipeSizeInput[action.payload.input],
            currentValue: action.payload.value,
          },
        },
      }
    }
    case 'SET_CURRENT_HELPER':
      return {
        ...state,
        helper: {
          ...state.helper,
          current: action.id,
        },
      }
    case 'SHOULD_RENDER_HELPER':
      return {
        ...state,
        helper: {
          ...state.helper,
          shouldRender: !state.helper.shouldRender,
        },
      }
    case 'GET_PARENT_COORDINATE':
      return {
        ...state,
        helper: {
          ...state.helper,
          parentPosition: action.position,
        },
      }
    case 'GET_HELPER_COORDINATE':
      return {
        ...state,
        helper: {
          ...state.helper,
          helperPosition: action.position,
        },
      }
    case 'GET_DEVICE_WIDTH':
      return {
        ...state,
        deviceWidth: action.width,
      }
    case 'SHOW_DROPDOWN_SCREEN':
      return {
        ...state,
        showDropdownScreen: action.payload,
      }
    case 'ADD_IMG_TO_STORE':
      return {
        ...state,
        images: action.incomingImg,
      }
    case 'INCREASE_IMG_COUNT':
      return {
        ...state,
        imagesCount: action.count,
      }
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.data,
        },
      }
    case 'CLEAR_CURRENT_USER':
      return {
        ...state,
        currentUser: action.data,
      }
    case 'SET_LANG':
      return {
        ...state,
        lang: action.lang,
      }
    case 'SET_SEARCH_TEXT':
      return {
        ...state,
        searchText: action.searchText,
      }
    case 'SET_SEARCH_RESULT':
      return {
        ...state,
        searchResult: [...state.searchResult, action.searchResult],
      }
    case 'CLEAR_SEARCH_RESULT':
      return {
        ...state,
        searchResult: [],
      }
    case 'SET_CHIP':
      const chipToAdd = state.availableChips.find(
        (_chip) => _chip.value === action.chip
      )
      const updatedAvailableChipsArray = state.availableChips.filter(
        (_chip) => _chip.value !== action.chip
      )
      return {
        ...state,
        availableChips: updatedAvailableChipsArray,
        selectedChips: [...state.selectedChips, chipToAdd],
      }
    case 'REMOVE_CHIP':
      const chipToRemove = state.selectedChips.find(
        (_chip) => _chip.value === action.chip
      )
      const updatedSelectedChipsArray = state.selectedChips.filter(
        (_chip) => _chip.value !== action.chip
      )
      return {
        ...state,
        availableChips: [...state.availableChips, chipToRemove],
        selectedChips: updatedSelectedChipsArray,
      }
    case 'RESET_CHIPS':
      return {
        ...state,
        availableChips: [...state.availableChips, ...state.selectedChips],
        selectedChips: [],
      }
    case 'SET_FAVORITES':
      return {
        ...state,
        favoriteFlashings: action.flashings,
      }
    case 'SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.message,
      }
    case 'SET_SEACH_STATE':
      return {
        ...state,
        isRepeatingSearch: action.state,
      }
    case 'SET_LOG_CARD_ID':
      return {
        ...state,
        logCardId: action.id,
      }
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        lang: action.lang,
      }
    case 'SET_CHIPS_ON_PAGE_LOAD':
      return {
        ...state,
        availableChips: action.chips,
      }
    case 'SET_DEVICE_TYPE':
      return {
        ...state,
        deviceType: action.deviceType,
      }
    default:
      return state
  }
}

export default reducers
