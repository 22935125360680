const re = /^((\d+-)?\d+\/\d*)|\d+$/gi

const filterInputCharacter = (value) => {
  if (value.match(re)) return value
  return value[value.length - 1] === ' '
    ? 'Blank space'
    : value[value.length - 1]
}

export default filterInputCharacter
