import React, { useEffect, useRef, useState } from 'react'
import { FiHeart, FiX } from 'react-icons/fi'
import { CSSTransition } from 'react-transition-group'
import { DetailedResultCard, Upper, Under } from './styles'
import { Wrapper } from '../../Modals/Base/styles'
import { i18n } from '../../../i18n'
import { store } from '../../../store'
import config from '../../../rpc.config'

const { TIMEOUT_VALUES } = config

const DetailedResultCardComponent = ({
  data,
  show,
  toggleHandler,
  isFavorite,
  favoriteHanlder,
}) => {
  const [displayData, setDisplayData] = useState({})

  useEffect(() => {
    const preData = data
    for (const key in preData) {
      if (preData[key] === null) preData[key] = i18n().common.notApplicable
    }
    setDisplayData(preData)
  }, [data])

  const cardRef = useRef()

  const clickOutsideHandler = (e) => {
    if (e.target.id !== 'close' && cardRef.current.contains(e.target)) return
    toggleHandler(false)
    document.removeEventListener('click', clickOutsideHandler)
  }

  const {
    name,
    sku,
    pipeSizeIn,
    pipeSizeMm,
    baseIn,
    baseMm,
    heightIn,
    heightMm,
    roofPitch,
    siliconeColors,
    epdmColors,
    isRound,
    isSquare,
    isMetal,
    isNonMetal,
    isStandard,
    isRetrofit,
    isEpdm,
    isSilicone,
  } = i18n().flashingProperties

  const { yes, no } = i18n().common

  const convertQueryValues = (input) => (!input ? no : yes)

  return (
    <CSSTransition
      in={show}
      timeout={TIMEOUT_VALUES}
      mountOnEnter
      unmountOnExit
      classNames='modal-dissolve'
      onEntered={() => document.addEventListener('click', clickOutsideHandler)}
    >
      <Wrapper>
        <DetailedResultCard ref={cardRef}>
          <button id='close' onClick={() => toggleHandler(false)} type='button'>
            <FiX color='#C4C4C4' size={30} />
          </button>
          <Upper isFavorite={isFavorite}>
            <div id='image-wrapper'>
              <img src={displayData.imageUrl} alt={displayData.name} />
            </div>
            <div id='title-sku'>
              <div id='title'>
                <span>{displayData.name}</span>
                {store.getState().deviceType === 'DESKTOP' && (
                  <FiHeart onClick={favoriteHanlder} />
                )}
              </div>
              <span id='sku'>{displayData.sku}</span>
            </div>
          </Upper>
          <Under>
            <p>
              <strong>{name}</strong>: {displayData.name}
            </p>
            <p>
              <strong>{sku}</strong>: {displayData.sku}
            </p>
            <p>
              <strong>{pipeSizeIn}</strong>: {displayData.minPipeSizeIn}” -{' '}
              {displayData.maxPipeSizeIn}”
            </p>
            <p>
              <strong>{pipeSizeMm}</strong>: {displayData.minPipeSizeMm}mm -{' '}
              {displayData.maxPipeSizeMm}mm
            </p>
            <p>
              <strong>{baseIn}</strong>:{' '}
              {`${displayData.baseInWidth}” x ${displayData.baseInLength}”`}
            </p>
            <p>
              <strong>{baseMm}</strong>:{' '}
              {`${displayData.baseMmWidth}mm x ${displayData.baseMmLength}mm`}
            </p>
            <p>
              <strong>{heightIn}</strong>: {displayData.totalHeightIn}
            </p>
            <p>
              <strong>{heightMm}</strong>: {displayData.totalHeightMm}mm
            </p>
            <p>
              <strong>{roofPitch}</strong>: {displayData.roofPitch}
            </p>
            <p>
              <strong>{siliconeColors}</strong>: {displayData.siliconeColors}
            </p>
            <p>
              <strong>{epdmColors}</strong>: {displayData.epdmColors}
            </p>
            <div>
              <p>
                <strong>{isRound}</strong>:{' '}
                {convertQueryValues(displayData.isRound)}
              </p>
              <p>
                <strong>{isSquare}</strong>: {convertQueryValues(data.isSquare)}
              </p>
              <p>
                <strong>{isMetal}</strong>:{' '}
                {convertQueryValues(displayData.isMetal)}
              </p>
              <p>
                <strong>{isNonMetal}</strong>:{' '}
                {convertQueryValues(displayData.isNonMetal)}
              </p>
              <p>
                <strong>{isStandard}</strong>:{' '}
                {convertQueryValues(displayData.isStandard)}
              </p>
              <p>
                <strong>{isRetrofit}</strong>:{' '}
                {convertQueryValues(displayData.isRetrofit)}
              </p>
              <p>
                <strong>{isEpdm}</strong>:{' '}
                {convertQueryValues(displayData.isEpdm)}
              </p>
              <p>
                <strong>{isSilicone}</strong>:{' '}
                {convertQueryValues(displayData.isSilicone)}
              </p>
            </div>
          </Under>
        </DetailedResultCard>
      </Wrapper>
    </CSSTransition>
  )
}

export default DetailedResultCardComponent
