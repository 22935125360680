import React from 'react'
import PipeSizeInput from '../../../components/PipeSizeInput'
import { store } from '../../../store'
import { PipeSizeInputWrapper, PipeSizeSpecificP } from './styles'
import Tooltip from '../../../components/Tooltips/Tooltip'
import ComponentWrapper from '../../../components/PageComponent/ComponentWrapper'
import { i18n } from '../../../i18n'

const PipeSizeScreen = () => {
  const currentMmValue = store.getState().pipeSizeInput.pipeSizeMm.currentValue
  const currentInValue = store.getState().pipeSizeInput.pipeSizeIn.currentValue

  const { title, pageText, labelIn, labelMm, errorIn, errorMm } =
    i18n().pipeSize

  const returnPageText = () =>
    pageText.map((line, i) => {
      const keyValue = `pipeSize_line_${i + 1}`

      if (i === 1) {
        if (store.getState().deviceType === 'MOBILE_APPLE') {
          return (
            <PipeSizeSpecificP key={keyValue}>
              {`${line.match(/^([^.]+)/)[0]}`}.
            </PipeSizeSpecificP>
          )
        }
        // targets the second line of the text block
        return (
          <PipeSizeSpecificP key={keyValue}>
            {`${line} `}
            <Tooltip
              content={i18n().pipeSize.instructions.text}
              id='read-instructions'
            >
              {i18n().pipeSize.instructions.readTheInstructions}
            </Tooltip>
            .
          </PipeSizeSpecificP>
        )
      }
      return <PipeSizeSpecificP key={keyValue}>{line}</PipeSizeSpecificP>
    })

  return (
    <ComponentWrapper title={title}>
      <div>{returnPageText()}</div>
      <PipeSizeInputWrapper>
        <PipeSizeInput
          label={labelIn}
          name='pipeSizeIn'
          id='in'
          dialog={
            <p>
              <strong>{currentInValue}</strong> {errorIn}
            </p>
          }
        />
        <PipeSizeInput
          label={labelMm}
          name='pipeSizeMm'
          id='mm'
          dialog={
            <p>
              <strong>{currentMmValue}</strong> {errorMm}
            </p>
          }
        />
      </PipeSizeInputWrapper>
    </ComponentWrapper>
  )
}

export default PipeSizeScreen
