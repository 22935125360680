import React, { useState } from 'react'
import { Page } from '../../components/PageComponent/styles'
import {
  ProfilePage,
  PictureArea,
  PictureWrapper,
  TextArea,
  InfoCard,
} from './styles'
import Loading from '../../components/Loading'
import BlankAvatar from '../../components/BlankAvatar'

import NameLine from '../../components/ProfileLine/Variants/Name'
import EmailLine from '../../components/ProfileLine/Variants/Email'
import PasswordLine from '../../components/ProfileLine/Variants/Password'

import { uploadProfilePicture } from '../../services/firebase/firestore'

import { store } from '../../store'
import { i18n } from '../../i18n'
import { fixPhotoUrlSize, showErrorMessage } from '../../utils'

const Profile = () => {
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const { accountDetails } = i18n().profile

  const handleImageUpload = async (e) => {
    setIsUploadingImage(true)
    try {
      const file = e.target.files[0]
      await uploadProfilePicture(file, store.getState().currentUser.uid)
    } catch (error) {
      showErrorMessage(error)
    }
    setIsUploadingImage(false)
  }

  const returnAvatar = () => {
    if (!store.getState().currentUser.photoUrl) {
      return (
        <BlankAvatar
          username={
            store.getState().currentUser.name ||
            store.getState().currentUser.email
          }
        />
      )
    }
    return (
      <img
        src={fixPhotoUrlSize(store.getState().currentUser.photoUrl)}
        alt=''
      />
    )
  }

  return (
    <Page>
      <ProfilePage>
        <PictureArea>
          <PictureWrapper>
            {isUploadingImage ? (
              <Loading />
            ) : (
              <>
                <label htmlFor='img-upload'>
                  <img src={store.getState().images['upload.svg']} alt='' />
                  <span>Upload</span>
                </label>
                <input
                  id='img-upload'
                  type='file'
                  onChange={handleImageUpload}
                  accept='.png, .jpg, .jpeg'
                />
              </>
            )}
            {returnAvatar()}
          </PictureWrapper>
          <TextArea>
            {store.getState().currentUser.name ||
              store.getState().currentUser.email}
          </TextArea>
        </PictureArea>
        <InfoCard>
          <p>{accountDetails}</p>
          <NameLine />
          <EmailLine />
          <PasswordLine />
        </InfoCard>
      </ProfilePage>
    </Page>
  )
}

export default Profile
