import { store } from '../store'

const queryValues = []
for (const prop in store.getState().query) {
  queryValues.push(prop)
}

const isNavigationAllowed = (currentPage) => {
  const hasUserSelectedOption =
    currentPage === 1
      ? [
          store.getState().query[queryValues[0]],
          store.getState().query[queryValues[1]],
        ].every((value) => value)
      : Boolean(store.getState().query[queryValues[currentPage]])
  if (hasUserSelectedOption || currentPage === 7) {
    return true
  }
  return false
}

export default isNavigationAllowed
