import { i18n } from '../i18n'

const formatPropertyText = (input) => {
  const text = input.toString()
  if (text.includes('is')) {
    return i18n().flashingProperties[text]
  }
  if (text === '0/12') return 'Flat'
  return text
}

export default formatPropertyText
