import React from 'react'
import { Avatar } from './styles'
import { convertNameToColor } from '../../utils'

const BlankAvatar = ({ username }) => (
  <Avatar className='blank-avatar' bgColor={convertNameToColor(username)}>
    {username.charAt(0)}
  </Avatar>
)

export default BlankAvatar
