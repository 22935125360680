import React, { useState } from 'react'
import { FiMail } from 'react-icons/fi'
import Base from '../Base'
import PasswordConfirmation from '../../Modals/PasswordConfirmation'
import Loading from '../../Loading'
import Input from '../../Input'

import { store } from '../../../store'
import { i18n } from '../../../i18n'
import { updateEmail } from '../../../services/firebase/auth'
import { validateEmail, showErrorMessage } from '../../../utils'

const EmailLine = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [emailErrorMsg, setEmailErrorMsg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const currentEmail = store.getState().currentUser.email

  const handleClose = () => {
    setIsEditing(false)
    setNewEmail('')
    setEmailErrorMsg('')
  }

  const handleUpdate = async (password) => {
    setIsLoading(true)
    try {
      await updateEmail(password, newEmail)
      setIsEditing(false)
    } catch (error) {
      showErrorMessage(error)
    }
    setIsLoading(false)
    setShowConfirmPassword(false)
  }

  const handleEnter = async () => {
    if (!store.getState().currentUser.providers.includes('password')) {
      showErrorMessage(i18n().profile.noPassword)
    } else {
      setNewEmail(currentEmail)
      setIsEditing(true)
    }
  }

  const handleChange = (e) => {
    setEmailErrorMsg('')
    if (e.type === 'blur' && !validateEmail(newEmail))
      setEmailErrorMsg(i18n().login.errorMessages.email)
    if (e.type === 'change') setNewEmail(e.target.value)
  }

  return (
    <>
      {isLoading && <Loading showBackground />}
      <PasswordConfirmation
        show={showConfirmPassword}
        toggle={setShowConfirmPassword}
        onSubmit={handleUpdate}
        text={i18n().modals.passwordConfirmation}
      />
      <Base
        icon={<FiMail />}
        label={i18n().profile.email}
        isEditing={isEditing}
        handleUpdate={() => setShowConfirmPassword(true)}
        handleClose={handleClose}
        handleEnter={handleEnter}
        disabled={
          !newEmail.length ||
          newEmail === currentEmail ||
          !validateEmail(newEmail)
        }
      >
        {isEditing ? (
          <Input
            type='email'
            value={newEmail}
            sizeAnchor={
              currentEmail !== newEmail ? newEmail.length : currentEmail.length
            }
            onChange={handleChange}
            onBlur={handleChange}
            disabled={isLoading}
            error={emailErrorMsg}
          />
        ) : (
          <span>{currentEmail}</span>
        )}
      </Base>
    </>
  )
}

export default EmailLine
