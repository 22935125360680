import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { FiChevronDown, FiUser, FiSettings, FiLogOut } from 'react-icons/fi'
import { Area, AuthWrapper } from './styles'
import BlankAvatar from '../../BlankAvatar'

import { store } from '../../../store'
import { userSignOut } from '../../../services/firebase/auth'
import { i18n } from '../../../i18n'

import useLoginState from '../../../hooks/useLoginState'

const InnerAuthArea = () => {
  const [isBoxOpen, toggleBox] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const ref = useRef()

  const { setisOnLoginScreen } = useLoginState()

  const handleLogOut = async () => {
    await userSignOut()
    toggleBox(false)
    history.push('/signin')
  }

  const handleGoToLogin = (boolean) => {
    if (location.pathname !== '/signin') history.push('/signin')
    setisOnLoginScreen(boolean)
  }

  const returnAvatar = () => {
    if (!store.getState().currentUser.photoUrl) {
      return (
        <BlankAvatar
          username={
            store.getState().currentUser.name ||
            store.getState().currentUser.email
          }
        />
      )
    }
    return (
      <img
        src={store.getState().currentUser.photoUrl}
        alt={i18n().navbar.profile}
      />
    )
  }

  const handleClickOutside = useCallback((e) => {
    if (!ref.current.contains(e.target) || e.target.id === 'logout') {
      toggleBox(false)
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isBoxOpen) document.addEventListener('click', handleClickOutside)
  }, [isBoxOpen, handleClickOutside])

  useEffect(() => {
    toggleBox(false)
    document.removeEventListener('click', handleClickOutside)
  }, [location, handleClickOutside])

  if (store.getState().currentUser) {
    return (
      <Area
        ref={ref}
        isLoggedIn
        active={isBoxOpen}
        onClick={() => toggleBox(true)}
      >
        <div>
          <div id='upper'>
            <div>{returnAvatar()}</div>
            <span>
              {store.getState().currentUser.name ||
                store.getState().currentUser.email}
            </span>
            <FiChevronDown className='caret' />
          </div>
          <div id='lower'>
            <hr />
            <NavLink to='/profile' activeClassName='active-menu'>
              <FiUser />
              <span>{i18n().navbar.profile}</span>
            </NavLink>
            <NavLink to='/settings' activeClassName='active-menu'>
              <FiSettings />
              <span>{i18n().navbar.settings}</span>
            </NavLink>
            <button type='button' id='logout' onClick={handleLogOut}>
              <FiLogOut />
              <span>{i18n().navbar.logOut}</span>
            </button>
          </div>
        </div>
      </Area>
    )
  }
  return (
    <Area isLoggedIn={false}>
      <button
        type='button'
        className='signin-button'
        onClick={() => handleGoToLogin(true)}
      >
        {i18n().navbar.signIn}
      </button>
      <button
        type='button'
        className='signin-button'
        onClick={() => handleGoToLogin(false)}
      >
        {i18n().navbar.signUp}
      </button>
    </Area>
  )
}

const AuthArea = () => (
  <AuthWrapper isLoggedIn={store.getState().currentUser}>
    {/* <div> */}
    <InnerAuthArea />
    {/* </div> */}
  </AuthWrapper>
)

export default AuthArea
