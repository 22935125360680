import styled from 'styled-components'

export const Line = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
  position: relative;
  height: 35px;

  @media screen and (max-width: 1280px) {
    margin-top: 21px;
    height: 26px;
  }

  svg {
    height: 25px;
    width: 25px;

    @media screen and (max-width: 1280px) {
      height: 18.75px;
      width: 18.75px;
    }
  }

  span {
    font-size: 25px;
    flex: 1 0;

    @media screen and (max-width: 1280px) {
      font-size: 18.75px;
    }

    &:nth-child(2) {
      margin-left: 36px;
      font-weight: 500;

      @media screen and (max-width: 1280px) {
        margin-left: 27px;
      }
    }
  }
`
export const Content = styled.div`
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  cursor: pointer;

  span {
    margin-right: 30px;

    @media screen and (max-width: 1280px) {
      margin-right: 22.5px;
    }
  }

  div {
    margin: 0;
    margin-right: ${(props) => (props.isEditing ? '24px' : '30px')};

    @media screen and (max-width: 1280px) {
      margin-right: ${(props) => (props.isEditing ? '18px' : '22.5px')};
    }
  }
`

export const Button = styled.button`
  color: inherit;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: inherit;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
