import styled from 'styled-components'

export const PasswordConfirmation = styled.div`
  & > p {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.035em;
    margin: 30px 0 14px;
  }

  & > div {
    width: 100%;
    margin-bottom: 0;
    height: 30px;

    svg {
      bottom: 6px;
    }
  }
`
