import React from 'react'
import { Wrapper, List, Item } from './styles'
import { changeLanguage } from '../../../utils'
import GenericModal from '../Generic'
import { i18n } from '../../../i18n'
import { store } from '../../../store'

const LanguageChange = ({ show, toggle }) => {
  const handleLanguageChange = (language) => {
    toggle(false)
    changeLanguage(language)
  }

  return (
    <GenericModal show={show} toggle={toggle} closable>
      <Wrapper>
        <p>{i18n().profile.language.title}</p>
        <p>{i18n().profile.language.text}</p>
        <List>
          <Item
            active={store.getState().lang === 'en'}
            onClick={() => handleLanguageChange('en')}
          >
            {i18n().languages.en}
          </Item>
          <Item
            active={store.getState().lang === 'es'}
            onClick={() => handleLanguageChange('es')}
          >
            {i18n().languages.es}
          </Item>
          <Item
            active={store.getState().lang === 'pl'}
            onClick={() => handleLanguageChange('pl')}
          >
            {i18n().languages.pl}
          </Item>
        </List>
      </Wrapper>
    </GenericModal>
  )
}

export default LanguageChange
