import React from 'react'
import { ComponentWrapper, TextLine } from './styles'

const Wrapper = ({ title, textLine, children }) => (
  <ComponentWrapper>
    <h2>{title}</h2>
    {textLine && <TextLine>{textLine}</TextLine>}
    {children}
  </ComponentWrapper>
)

export default Wrapper
