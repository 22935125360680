// These are the names that are different on Firebase than on RPC:
const NAMEKEYS = { name: 'displayName', photoUrl: 'photoURL' }

const renameKey = (object) => {
  const key = Object.keys(object)[0]
  if (!Object.keys(NAMEKEYS).includes(key)) return object
  const copy = { ...object }
  const newKey = copy[key]
  const newKeyName = NAMEKEYS[key]
  delete copy[key]
  copy[newKeyName] = newKey
  return copy
}

export default renameKey
