import React, { useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { FiX, FiCheck } from 'react-icons/fi'
import Base from '../Base'
import { ForgotPassword } from './styles'
import FormInput from '../../Input'
import Button from '../../Button'
import Loading from '../../Loading'

import { i18n } from '../../../i18n'
import { store } from '../../../store'
import { passwordReset } from '../../../services/firebase/auth'
import { validateEmail } from '../../../utils'

const ForgotPasswordModal = ({ show, toggle }) => {
  const [passwordRecoveryEmail, setPasswordRecoveryEmail] = useState('')
  const [warning, setWarning] = useState('')
  const [hasRecoverySucceeded, setRecoverySuccess] = useState(null)
  const [isHanging, setIsHanging] = useState(false)

  const handlePasswordRecovery = async (e) => {
    e.preventDefault()
    setIsHanging(true)
    if (!validateEmail(passwordRecoveryEmail)) return
    try {
      await passwordReset(passwordRecoveryEmail)
      setRecoverySuccess(true)
    } catch (error) {
      setRecoverySuccess(false)
    }
    setIsHanging(false)
  }

  const handlePasswordRecoveryEmail = (e) => {
    setWarning('')
    if (e.type === 'blur' && !validateEmail(passwordRecoveryEmail))
      setWarning(i18n().login.errorMessages.email)
    if (e.type === 'change') setPasswordRecoveryEmail(e.target.value)
  }

  const passwordRecoveryImage =
    store.getState().images['password-recovery-img.svg']

  const { title, dontWorry, enterTheEmail, send } =
    i18n().login.passwordRecovery

  const returnComponent = () => {
    if (hasRecoverySucceeded === null) {
      return (
        <div>
          <p>{title}</p>
          <p>{dontWorry}</p>
          <p>{enterTheEmail}</p>
          <form onSubmit={handlePasswordRecovery}>
            <FormInput
              label={i18n().inputTexts.email.label}
              id='recover-password-email'
              placeholder={i18n().inputTexts.email.placeholder}
              type='email'
              onChange={handlePasswordRecoveryEmail}
              onBlur={handlePasswordRecoveryEmail}
              error={warning}
            />
            <>
              <Button
                type='submit'
                id='close'
                disabled={
                  !(
                    validateEmail(passwordRecoveryEmail) &&
                    passwordRecoveryEmail.length
                  )
                }
              >
                {send}
              </Button>
              {isHanging && <Loading />}
            </>
          </form>
        </div>
      )
    }
    return (
      <div className='recovery-response'>
        <p>
          {hasRecoverySucceeded
            ? i18n().login.passwordRecovery.success.title
            : i18n().login.passwordRecovery.fail.oops}
          {hasRecoverySucceeded ? (
            <FiCheck color='#76C439' />
          ) : (
            <FiX color='#EE4F4F' />
          )}
        </p>
        <p>
          {hasRecoverySucceeded
            ? i18n().login.passwordRecovery.success.youCanCheck
            : i18n().login.passwordRecovery.fail.somethingWentWrong}
        </p>
        <p>
          {hasRecoverySucceeded
            ? i18n().login.passwordRecovery.success.enter
            : i18n().login.passwordRecovery.fail.reEnter}
        </p>
        <Button
          type='button'
          onClick={
            hasRecoverySucceeded
              ? () => toggle(false)
              : () => setRecoverySuccess(null)
          }
          className={
            hasRecoverySucceeded ? 'recovery-success' : 'recovery-fail'
          }
        >
          {i18n().common.ok}
        </Button>
      </div>
    )
  }

  const timeoutValues = { appear: 1000, enter: 1000, exit: 850 }

  const handleCloseModal = () => {
    setRecoverySuccess(null)
    setWarning('')
  }

  return (
    <Base show={show} onExited={handleCloseModal}>
      <ForgotPassword>
        <TransitionGroup className='animation-wrapper'>
          <CSSTransition
            key={`recovery_${hasRecoverySucceeded}`}
            timeout={timeoutValues}
            classNames='dissolve'
            unmountOnExit
          >
            {returnComponent()}
          </CSSTransition>
        </TransitionGroup>
        <div>
          <FiX color='#C4C4C4' size={30} onClick={() => toggle(false)} />
          <img src={passwordRecoveryImage} alt='' />
        </div>
      </ForgotPassword>
    </Base>
  )
}

export default ForgotPasswordModal
