import styled from 'styled-components'

export const Nav = styled.nav`
  height: 75px;
  background-color: #414141;
  color: #e5e5e5;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1280px) {
    height: 56px;
    padding: 0 30px;
  }
`
export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  @media screen and (max-width: 1280px) {
  }
  a {
    text-decoration: none;
    outline: none;
    &:nth-child(1) {
      width: auto;
      img {
        height: 34px;
        @media screen and (max-width: 1280px) {
          height: 22px;
        }
      }
    }
    &.active-link {
      color: #cfb26c;
    }
  }
  > ul {
    display: flex;
    margin-left: 80px;
    font-weight: 400;
    list-style: none;
    font-size: 16px;
    line-height: 1.1;
    @media screen and (max-width: 1280px) {
      margin-left: 40px;
      font-size: 12px;
    }
    li {
      a {
        color: inherit;
      }
      &:nth-child(2) {
        margin: 0 35px;
        @media screen and (max-width: 1280px) {
          margin: 0 17px;
        }
      }
    }
  }
`
export const AlertWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  @media screen and (max-width: 1280px) {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
`
