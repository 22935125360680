import styled from 'styled-components'

export const PasswordChange = styled.div`
  margin-top: 16px;
  width: 100%;

  & > div {
    margin-bottom: 21px;
    height: 53px;

    label {
      margin-bottom: 5px;
      font-size: 16px;
      text-align: left;
    }

    svg {
      bottom: 6px;
    }
  }
`
