import config from '../rpc.config'

const { RECENT_SEARCHES_STORAGE_KEY } = config

const saveSearchQueryOnLocalStorage = (term) => {
  if (localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)) {
    const previousSearches =
      JSON.parse(localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)) ||
      localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)
    if (previousSearches.includes(term))
      previousSearches.splice(previousSearches.indexOf(term), 1)
    const newValue = [term, ...previousSearches]
    if (newValue.length === 6) newValue.pop()
    localStorage.setItem(RECENT_SEARCHES_STORAGE_KEY, JSON.stringify(newValue))
  } else {
    localStorage.setItem(RECENT_SEARCHES_STORAGE_KEY, JSON.stringify([term]))
  }
}

export default saveSearchQueryOnLocalStorage
