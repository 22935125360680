import React from 'react'
import { CardWrapper } from '../../../components/PageCard/styles'
import PageCard from '../../../components/PageCard'
import ComponentWrapper from '../../../components/PageComponent/ComponentWrapper'
import { i18n } from '../../../i18n'

const PipeTypeScreen = () => {
  const { title, text, labelRound, labelSquare } = i18n().pipeType

  return (
    <ComponentWrapper title={title} textLine={text}>
      <CardWrapper>
        <PageCard
          img='type-round'
          label={labelRound}
          name='pipeType'
          value='isRound'
        />
        <PageCard
          img='type-square'
          label={labelSquare}
          name='pipeType'
          value='isSquare'
        />
      </CardWrapper>
    </ComponentWrapper>
  )
}

export default PipeTypeScreen
