import React from 'react'
import { Line, Content } from './styles'
import ButtonsArea from './ButtonsArea'

const ProfileLine = ({
  icon,
  label,
  isEditing,
  handleUpdate,
  handleClose,
  handleEnter,
  disabled,
  children,
}) => (
  <Line>
    {icon}
    <span>{label}</span>
    <Content
      onClick={!isEditing ? handleEnter : undefined}
      isEditing={isEditing}
    >
      {children}
      <ButtonsArea
        isEditing={isEditing}
        handleUpdate={handleUpdate}
        handleClose={handleClose}
        disabled={disabled}
      />
    </Content>
  </Line>
)

export default ProfileLine
