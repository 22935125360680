import styled from 'styled-components'
import { InputWrapper } from '../../../components/PipeSizeInput/styles'

export const PipeSizeInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 411px;
  margin: 24px auto 0;
  position: relative;

  @media screen and (max-width: 1280px) {
    max-width: 308px;
    margin: 18px auto 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    max-width: 100%;

    ${InputWrapper} {
      margin-top: 15px;
      width: 100%;

      & > input {
        width: inherit;
      }
    }
  }
`

export const PipeSizeSpecificP = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 1.1;
  color: #e5e5e5;
  margin: 18px 0;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
    margin: 14px 0;
  }
`
