import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { result } from '../../firebase-config.json'
import { store } from '../../store'
import { setCurrentUser, clearCurrentUser, checkLogIn } from '../../actions'
import {
  retrieveUser,
  updateUserInfoOnFirestore,
  syncFavorites,
} from './firestore'
import { errorHandler, getStorageLocation } from '../../utils'

import config from '../../rpc.config'

const {
  STORAGE_KEY_NAME,
  USERS_CALCULATOR_COLLECTION_NAME,
  PROVIDER_STORAGE_KEY,
  FIRESTORE_PROFILE_IMAGES_PATH,
} = config
const storage = getStorageLocation()

// Initialize firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(result.sdkConfig)
} else {
  firebase.app()
}

// Sign in annonymously (every user will do that upon entering RPC)
const userSignIn = () => {
  try {
    firebase.auth().signInAnonymously()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const setCurrentUserData = async (user, isUpdate = false) => {
  const id = user.uid
  await syncFavorites(id)

  const userOnLocalStorage = localStorage.getItem(STORAGE_KEY_NAME)

  const checkIfUserIsOnSessionStorage = Object.keys(sessionStorage).some(
    (key) => key.includes('firebase:authUser')
  )
  const userOnSessionStorage =
    checkIfUserIsOnSessionStorage && sessionStorage.getItem(STORAGE_KEY_NAME)

  const { uid, email, name, photoUrl } = isUpdate
    ? await retrieveUser(id)
    : JSON.parse(userOnLocalStorage || userOnSessionStorage) ||
      (await retrieveUser(id))
  const userObj = { uid, email, name, photoUrl }
  userObj.providers = user.providerData.map((provider) => provider.providerId)

  if (isUpdate) {
    if (userOnLocalStorage)
      localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(userObj))
    if (userOnSessionStorage)
      sessionStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(userObj))
  }

  storage.setItem(STORAGE_KEY_NAME, JSON.stringify(userObj))

  store.dispatch(setCurrentUser(userObj))
  store.dispatch(checkLogIn(true))
}

const saveCredential = (provider, credential, shouldRememberUser = true) => {
  if (!shouldRememberUser) {
    sessionStorage.setItem(
      PROVIDER_STORAGE_KEY,
      JSON.stringify({ provider, credential })
    )
  } else {
    localStorage.setItem(
      PROVIDER_STORAGE_KEY,
      JSON.stringify({ provider, credential })
    )
  }
}

export const checkIfUserIsLoggedIn = () => {
  firebase.auth().onAuthStateChanged(async (user) => {
    try {
      if (user) {
        if (user.isAnonymous) {
          store.dispatch(checkLogIn(false))
        } else {
          const ref = firebase
            .firestore()
            .collection(USERS_CALCULATOR_COLLECTION_NAME)
            .doc(user.uid)
          const userOnFirestore = await ref.get()
          if (!userOnFirestore.exists) {
            await addUserToFirestore(user)
          }
          await setCurrentUserData(user)
        }
      } else {
        userSignIn()
      }
    } catch (error) {
      throw errorHandler(error)
    }
  })
}

const addUserToFirestore = async (user) => {
  try {
    const { uid, displayName, email, photoURL } = user
    await firebase
      .firestore()
      .collection(USERS_CALCULATOR_COLLECTION_NAME)
      .doc(uid)
      .set({ uid, name: displayName, email, photoUrl: photoURL })
  } catch (error) {
    throw errorHandler(error)
  }
}

// Create user with email (and logs in afterwards)
export const signUpWithEmail = async (
  userEmail,
  password,
  isUpdatingPassword = false
) => {
  try {
    const credential = firebase.auth.EmailAuthProvider.credential(
      userEmail,
      password
    )
    const user = await firebase
      .auth()
      .currentUser.linkWithCredential(credential)
    if (!isUpdatingPassword) await addUserToFirestore(user.user)
    await setCurrentUserData(user.user, true)
  } catch (error) {
    throw errorHandler(error)
  }
}

// Email login
export const loginWithEmail = async (email, password, shouldRememberUser) => {
  try {
    const persistence = shouldRememberUser
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION
    await firebase.auth().setPersistence(persistence)
    await firebase.auth().signInWithEmailAndPassword(email, password)
    saveCredential('password', null, shouldRememberUser)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const googleLogin = async () => {
  try {
    const provider = new firebase.auth.GoogleAuthProvider()
    const user = await firebase.auth().signInWithPopup(provider)
    saveCredential('google', user.credential.idToken)
    await addUserToFirestore(user.user)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const facebookLogin = async () => {
  // error if facebook container is on
  try {
    const provider = new firebase.auth.FacebookAuthProvider()
    const user = await firebase.auth().signInWithPopup(provider)
    saveCredential('facebook', user.credential.accessToken)
    await addUserToFirestore(user.user)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const userSignOut = async () => {
  try {
    const { currentUser } = firebase.auth()
    if (currentUser.isAnonymous) return
    await firebase.auth().signOut()
    store.dispatch(clearCurrentUser())
    localStorage.removeItem(STORAGE_KEY_NAME) ||
      sessionStorage.removeItem(STORAGE_KEY_NAME)
    localStorage.removeItem(PROVIDER_STORAGE_KEY) ||
      sessionStorage.removeItem(PROVIDER_STORAGE_KEY)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const passwordReset = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
  } catch (error) {
    throw errorHandler(error)
  }
}

export const updatePassword = async (currentPassword, newPassword) => {
  try {
    const user = await reauthenticateUser(currentPassword)
    await user.updatePassword(newPassword)
  } catch (error) {
    throw errorHandler(error)
  }
}

const reauthenticateUser = async (password) => {
  const { currentUser } = firebase.auth()
  const credential = firebase.auth.EmailAuthProvider.credential(
    currentUser.email,
    password
  )
  await currentUser.reauthenticateWithCredential(credential)
  return currentUser
}

export const updateEmail = async (password, newEmail) => {
  // Can't use updateProfileValue here; the apps logs out. That doesn't happen if made this way:
  try {
    const user = await reauthenticateUser(password)
    await user.updateEmail(newEmail)
    await updateUserInfoOnFirestore(user.uid, { email: newEmail })
  } catch (error) {
    throw errorHandler(error)
  }
}

export const setPasswordToExistingAccount = async (password) => {
  try {
    const user = firebase.auth().currentUser
    const { provider, credential } = JSON.parse(
      localStorage.getItem(PROVIDER_STORAGE_KEY) ||
        sessionStorage.getItem(PROVIDER_STORAGE_KEY)
    )
    const prov = getProvider(provider)
    const cred = firebase.auth[prov].credential(credential)
    await user.reauthenticateWithCredential(cred)
    await signUpWithEmail(user.email, password, true)
  } catch (error) {
    throw errorHandler(error)
  }
}

const getProvider = (provider) => {
  switch (provider) {
    case 'facebook':
      return 'FacebookAuthProvider'
    case 'google':
      return 'GoogleAuthProvider'
    default:
      break
  }
}

export const deleteProfile = async (password) => {
  try {
    const user = await reauthenticateUser(password)
    await firebase
      .firestore()
      .collection(USERS_CALCULATOR_COLLECTION_NAME)
      .doc(user.uid)
      .delete()
    await firebase
      .storage()
      .ref(FIRESTORE_PROFILE_IMAGES_PATH + user.uid)
      .delete()
    await user.delete()
    store.dispatch(clearCurrentUser())
    localStorage.removeItem(STORAGE_KEY_NAME) ||
      sessionStorage.removeItem(STORAGE_KEY_NAME)
    localStorage.removeItem(PROVIDER_STORAGE_KEY) ||
      sessionStorage.removeItem(PROVIDER_STORAGE_KEY)
  } catch (error) {
    throw errorHandler(error)
  }
}
