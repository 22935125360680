import React from 'react'
import Lottie from 'react-lottie'
import FadeTransition from '../../../components/FadeTransition'
import { CardWrapper } from '../../../components/PageCard/styles'
import FlashingTypeSpecificCard from './FlashingTypeSpecificCard'
import { RoofPitchScreenWrapper, RoofPitchScreenContent } from './styles'
import Dropdown from '../../../components/Dropdown'
import { store } from '../../../store'
import { setDropdownScreen, selectDropdown } from '../../../actions'
import RoofPitchPageWrapper from '../../../components/PageComponent/RoofPitchPageWrapper'
import { i18n } from '../../../i18n'
import homeAnimation from './homeAnimation.json'

const RoofPitchScreen = () => {
  const { title, text, labelSloped, labelFlat } = i18n().roofPitch

  const content = () => {
    const contentArr = []
    const degrees = [
      '56.31°',
      '54.78°',
      '53.13°',
      '51.34°',
      '49.40°',
      '47.29°',
      '45°',
      '42.51°',
      '39.81°',
      '36.87°',
      '33.69°',
      '30.26°',
      '26.57°',
      '22.62°',
      '18.43°',
      '14.04°',
      '9.46°',
      '4.76°',
    ]
    degrees.reverse()
    let num = 1
    while (num <= 18) {
      contentArr.push(`${num}/12 ${i18n().common.or} ${degrees[num - 1]}`)
      num++
    }
    return contentArr
  }

  const handleClickBack = () => {
    store.dispatch(setDropdownScreen(false))
    store.dispatch(selectDropdown(''))
  }

  const isShowingDropdownScreen = store.getState().showDropdownScreen

  const lottieDefaultOptions = {
    loop: false,
    animationData: homeAnimation,
  }

  const returnScreen = () => {
    if (!isShowingDropdownScreen) {
      return (
        <RoofPitchPageWrapper title={title} textLine={text} step={1}>
          <CardWrapper>
            <FlashingTypeSpecificCard
              img='roof-pitch'
              label={labelSloped}
              name='roofPitch'
              value=''
            />
            <FlashingTypeSpecificCard
              img='roof-flat'
              label={labelFlat}
              name='roofPitch'
              value='0/12'
            />
          </CardWrapper>
        </RoofPitchPageWrapper>
      )
    }
    return (
      <RoofPitchPageWrapper
        title={title}
        hasButton
        onClick={handleClickBack}
        step={2}
      >
        <RoofPitchScreenContent>
          <Lottie options={lottieDefaultOptions} speed={1.5} />
          <Dropdown content={content()} />
        </RoofPitchScreenContent>
      </RoofPitchPageWrapper>
    )
  }

  return (
    <FadeTransition index={isShowingDropdownScreen ? 1 : 0}>
      <RoofPitchScreenWrapper>{returnScreen()}</RoofPitchScreenWrapper>
    </FadeTransition>
  )
}

export default RoofPitchScreen
