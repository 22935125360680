import styled from 'styled-components'

const searchBoxHeight = 34

export const Search = styled.div`
  position: relative;
  flex: 1 0;
  height: ${searchBoxHeight}px;
  margin: 0 20px 0 48px;

  @media screen and (max-width: 1280px) {
    margin: 0 15px 0 20px;
    height: ${searchBoxHeight - 4}px;
  }
`

export const SearchBox = styled.div`
  box-shadow: ${(props) =>
    props.focus ? '0px 0px 9px rgba(0, 0, 0, 0.25)' : 'none'};
  border-radius: 4px;
  padding: ${(props) => (props.open ? '0 15px' : '0')};
  cursor: ${(props) => (props.open ? 'auto' : 'pointer')};
  background-color: #343434;
  position: absolute;
  right: 0;
  width: ${(props) => (props.open ? '100%' : '0')};
  max-width: 600px;
  min-height: ${searchBoxHeight}px;
  z-index: 111;
  font-size: 12px;
  line-height: 14px;
  transition: ${(props) => `width ${props.duration - 50}ms ease-in`};
  // z-index fix on Safari:
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  @media screen and (max-width: 1280px) {
    font-size: 9px;
    min-height: ${searchBoxHeight - 4}px;
  }
`

export const Input = styled.div`
  display: flex;
  align-items: center;
  height: ${searchBoxHeight}px;

  button#clear-input {
    background-color: transparent;
    color: inherit;
    border: 0;
    height: 18px;
    width: 18px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 7px;

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  & > svg {
    &:nth-child(1) {
      //magnifying glass
      height: ${(props) => (props.condition ? '18px' : '24px')};
      width: ${(props) => (props.condition ? '18px' : '24px')};
      flex-shrink: 0;
      transition: height 0.15s linear, width 0.15s linear;
      position: absolute;
      left: ${(props) => (props.condition ? '15px' : '-20px')};
      transition: left 0.15s ease-in;

      @media screen and (max-width: 1280px) {
        height: ${(props) => (props.condition ? '12px' : '18px')};
        width: ${(props) => (props.condition ? '12px' : '18px')};
      }
    }
  }

  & > div {
    overflow: hidden;
    margin-left: 30px;
    flex: 1 0;
    padding-right: ${(props) => (props.open ? '40px' : '0')};

    input {
      width: 100%;
      pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
      background-color: transparent;
      border: 0;
      color: inherit;
      outline: none;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      &::placeholder {
        color: rgba(229, 229, 229, 0.15);
      }
    }
  }
`

export const OpenSearchComponent = styled.div`
  background-color: #343434;
  border-top: 1px solid rgba(229, 229, 229, 0.1);
  padding: 20px 15px;

  @media screen and (max-width: 1280px) {
    padding: 15px 11.25px;
  }

  p {
    font-weight: 400;

    &.clearSelection {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    @media screen and (max-width: 1280px) {
      margin-top: 11.25px;
    }
  }
`

export const RecentSearches = styled.div`
  flex-direction: column;
  margin-top: 15px;

  li {
    display: block;
    margin-top: 16px;

    div {
      display: inline-flex;
      align-items: center;
      color: rgba(229, 229, 229, 0.5);

      &:hover {
        color: #f3f3f3;
      }

      span {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    margin-top: 11.25px;
  }
`

export const Chip = styled.div`
  background: #5a5959;
  border-radius: 4px;
  color: rgba(229, 229, 229, 0.75);
  padding: 0 9px;
  height: 23px;
  margin-right: 9px;
  margin-bottom: ${(props) => (props.selected ? '5.5px' : '7px')};
  cursor: pointer;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1280px) {
    border-radius: 4px;
    height: 17.25px;
    padding: 0 6.75px;
    margin-right: 6.75px;
    margin-bottom: ${(props) => (props.selected ? '4px' : '5.25px')};
  }

  * {
    pointer-events: none;
  }
`

export const ChipsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5.5px;
`
