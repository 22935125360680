import React from 'react'
import { FiChevronRight, FiCheck, FiX } from 'react-icons/fi'
import { Button } from './styles'

const ButtonsArea = ({ isEditing, handleUpdate, handleClose, disabled }) => {
  if (isEditing) {
    return (
      <>
        <Button onClick={handleUpdate} disabled={disabled}>
          <FiCheck style={{ marginRight: 8 }} />
        </Button>
        <Button onClick={handleClose}>
          <FiX />
        </Button>
      </>
    )
  }
  return (
    <Button>
      <FiChevronRight />
    </Button>
  )
}

export default ButtonsArea
