import React, { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import Base from '../Base'
import { Generic, Wrapper, ButtonsWrapper, Button } from './styles'

import { i18n } from '../../../i18n'

const GenericModal = ({
  show,
  text,
  toggle,
  onExited,
  onSubmit,
  disabled,
  closable,
  children,
  style,
  danger,
}) => {
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    setIsShowing(show)
  }, [show])

  const handleClose = () => {
    if (toggle) toggle(false)
    setIsShowing(false)
  }

  const returnComponents = () => {
    if (closable) {
      return (
        <>
          <FiX onClick={handleClose} />
          <Wrapper style={style}>{children}</Wrapper>
          <ButtonsWrapper>
            <Button danger={danger} onClick={onSubmit} disabled={disabled}>
              {danger ? i18n().common.delete : i18n().common.ok.toUpperCase()}
            </Button>
            <Button onClick={handleClose}>{i18n().common.cancel}</Button>
          </ButtonsWrapper>
        </>
      )
    }
    return (
      <>
        <Wrapper style={style}>
          <p>{text}</p>
        </Wrapper>
        <Button onClick={handleClose}>{i18n().common.ok.toUpperCase()}</Button>
      </>
    )
  }

  return (
    <Base show={isShowing} onExited={onExited}>
      <Generic closable={closable}>{returnComponents()}</Generic>
    </Base>
  )
}

export default GenericModal
