import React, { useState, useRef, useEffect } from 'react'
import { FiSearch, FiX, FiClock } from 'react-icons/fi'
import { useLocation, useHistory } from 'react-router-dom'
import { useDetectClickOutside } from 'react-detect-click-outside'
import useDebounce from '../../../hooks/useDebounce'
import {
  Search,
  SearchBox,
  ChipsArea,
  Input,
  OpenSearchComponent,
  RecentSearches,
  Chip as ChipStyles,
} from './styles'
import Chip from './Chip'
import { i18n } from '../../../i18n'
import config from '../../../rpc.config'
import { useAlgolia } from '../../../contexts/AlgoliaSearchContext'

const SearchBoxComponent = () => {
  const [isSearchBoxOpen, toggleSearchBox] = useState(false)
  const [shouldShowUnderBox, setShouldShowUnderBox] = useState(false)
  const [searchTextDisplayValue, setSearchTextDisplayValue] = useState('')
  const [openBoxCondition, setOpenBoxCondition] = useState(false)

  const inputRef = useRef()
  const location = useLocation()
  const history = useHistory()

  const {
    selectedChips,
    availableChips,
    handleTextSearch,
    resetChips,
    clearSearchBox,
    addChip,
    removeChip,
  } = useAlgolia()

  const debounceChange = useDebounce(handleTextSearch)

  const delay = selectedChips.length ? 0 : 200

  useEffect(() => {
    if (isSearchBoxOpen) {
      inputRef.current && inputRef.current.focus()
    }
  }, [isSearchBoxOpen])

  useEffect(() => {
    if (location.pathname !== '/search') {
      toggleSearchBox(false)
      clearSearchBox()
      resetChips()
      setSearchTextDisplayValue('')
    }
  }, [location])

  const closeBoxAndRedirect = (e) => {
    e.stopPropagation()
    history.goBack()
    clearSearchBox()
    toggleSearchBox(false)
    setOpenBoxCondition(false)
  }

  const handleInputChange = (e) => {
    const { value } = e.target
    setSearchTextDisplayValue(value)
    debounceChange(value)
  }

  const handleClickHistory = (term) => {
    if (selectedChips.length) resetChips()
    setSearchTextDisplayValue(term)
    handleTextSearch(term)
  }

  const handleAddChip = (e, value) => {
    e.stopPropagation()
    addChip(value)
  }

  const handleRemoveChip = (e, value) => {
    e.stopPropagation()
    removeChip(value)
  }

  useEffect(() => {
    if (
      location.pathname === '/search' &&
      !selectedChips.length &&
      !searchTextDisplayValue.length
    ) {
      history.replace('/')
    }
  }, [selectedChips, location])

  useEffect(() => {
    if (selectedChips.length && searchTextDisplayValue.length)
      setSearchTextDisplayValue('')
  }, [selectedChips])

  const returnChips = () => {
    const MAX_LENGTH = 3
    if (!isSearchBoxOpen) {
      const displayChipsArray = selectedChips.filter(
        (chip, i) => i < MAX_LENGTH
      )
      if (selectedChips.length > MAX_LENGTH) displayChipsArray.push(null)
      return displayChipsArray.map((chip) => {
        if (chip === null) {
          return (
            <Chip
              key='chip_rest'
              name={`+${selectedChips.length - MAX_LENGTH}`}
              dead
            />
          )
        }
        return (
          <Chip
            key={`chip_${chip.name}`}
            name={chip.name}
            selected
            handler={(e) => handleRemoveChip(e, chip.value)}
          />
        )
      })
    }
    return selectedChips.map((chip) => (
      <Chip
        key={`chip_${chip.name}`}
        name={chip.name}
        selected
        handler={(e) => handleRemoveChip(e, chip.value)}
      />
    ))
  }

  useEffect(() => {
    let timeout = 0
    if (isSearchBoxOpen) {
      timeout = setTimeout(() => {
        setShouldShowUnderBox(true)
      }, delay)
    } else {
      setShouldShowUnderBox(false)
    }
    return () => clearTimeout(timeout)
  }, [isSearchBoxOpen, delay])

  useEffect(() => {
    setOpenBoxCondition(
      searchTextDisplayValue.length > 0 || selectedChips.length > 0
        ? true
        : isSearchBoxOpen
    )
  }, [selectedChips, searchTextDisplayValue, isSearchBoxOpen])

  useEffect(() => {
    if (!openBoxCondition) setSearchTextDisplayValue('')
  }, [openBoxCondition])

  const returnRecentSearches = () => {
    const recentSearches = localStorage.getItem(
      config.RECENT_SEARCHES_STORAGE_KEY
    )
    if (recentSearches) {
      return (
        <>
          <RecentSearches>
            <p>{i18n().search.recent}</p>
            <ul>
              {JSON.parse(recentSearches).map((term, i) => (
                <li key={`term_${i}`} onClick={() => handleClickHistory(term)}>
                  <div>
                    <FiClock style={{ marginRight: 10 }} />
                    <span>{term}</span>
                  </div>
                </li>
              ))}
            </ul>
          </RecentSearches>
        </>
      )
    }
  }

  const closeModal = (e) => {
    if (
      typeof e.target.className.indexOf !== 'function' ||
      e.target.className.includes('chip')
    )
      return
    toggleSearchBox(false)
  }

  const ref = useDetectClickOutside({
    onTriggered: closeModal,
  })

  return (
    <Search>
      <SearchBox
        open={openBoxCondition}
        focus={isSearchBoxOpen}
        duration={delay}
        onClick={() => toggleSearchBox(true)}
        ref={ref}
      >
        {selectedChips.length > 0 ? (
          <ChipsArea>{returnChips()}</ChipsArea>
        ) : (
          <Input open={isSearchBoxOpen} condition={openBoxCondition}>
            <FiSearch onClick={() => toggleSearchBox(true)} />
            <div>
              <input
                ref={inputRef}
                type='text'
                onChange={handleInputChange}
                value={searchTextDisplayValue}
              />
              {searchTextDisplayValue.length ? (
                <button
                  id='clear-input'
                  onClick={closeBoxAndRedirect}
                  type='button'
                >
                  <FiX />
                </button>
              ) : null}
            </div>
          </Input>
        )}
        {shouldShowUnderBox && (
          <OpenSearchComponent>
            {availableChips.length === 0 ? (
              <ChipStyles
                as='p'
                className='chip'
                onClick={() => resetChips()}
                style={{ display: 'inline-flex', margin: 0 }}
              >
                <span>{i18n().search.clearSelection}</span>
                <FiX size={10} style={{ marginLeft: 4 }} />
              </ChipStyles>
            ) : (
              <p>{i18n().search.lookingFor}…</p>
            )}
            {availableChips.length > 0 && (
              <div>
                {availableChips
                  .sort((a, b) => a.index - b.index)
                  .map((chip) => (
                    <Chip
                      key={`chip_${chip.name}`}
                      name={chip.name}
                      handler={(e) => handleAddChip(e, chip.value)}
                    />
                  ))}
              </div>
            )}
            {returnRecentSearches()}
          </OpenSearchComponent>
        )}
      </SearchBox>
    </Search>
  )
}

export default SearchBoxComponent
