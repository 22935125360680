import React, { useState } from 'react'
import { FiKey } from 'react-icons/fi'
import Base from '../Base'
import PasswordChange from '../../Modals/PasswordChange'
import PasswordConfirmation from '../../Modals/PasswordConfirmation'
import Loading from '../../Loading'

import { store } from '../../../store'
import { i18n } from '../../../i18n'
import {
  updatePassword,
  setPasswordToExistingAccount,
} from '../../../services/firebase/auth'
import { showErrorMessage } from '../../../utils'

const PasswordLine = () => {
  const [isChangingPassword, setIsChangingPassword] = useState(false)
  const [isSettingPassword, setIsSettingPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleUpdatePassword = async (currentPassword, newPassword) => {
    // Once the user have a password, they can enter here
    setIsLoading(true)
    try {
      await updatePassword(currentPassword, newPassword)
    } catch (error) {
      showErrorMessage(error)
    }
    setIsChangingPassword(false)
    setIsLoading(false)
  }

  const handleSetPassword = async (password) => {
    setIsLoading(true)
    try {
      await setPasswordToExistingAccount(password)
    } catch (error) {
      showErrorMessage(error)
    }
    setIsSettingPassword(false)
    setIsLoading(false)
  }

  const handleEnter = async () => {
    if (!store.getState().currentUser.providers.includes('password')) {
      // If the user has no password to update, tell them to create one
      setIsSettingPassword(true)
    } else {
      setIsChangingPassword(true)
    }
  }

  return (
    <>
      {isLoading && <Loading showBackground />}
      <Base
        icon={<FiKey />}
        label={i18n().profile.password}
        handleEnter={handleEnter}
      >
        <span
          style={{
            alignSelf: !store
              .getState()
              .currentUser.providers.includes('password')
              ? 'flex-start'
              : 'center',
          }}
        >
          {!store.getState().currentUser.providers.includes('password')
            ? i18n().profile.noPasswordRegistered
            : '***************'}
        </span>
      </Base>
      <PasswordChange
        show={isChangingPassword}
        toggle={setIsChangingPassword}
        onSubmit={handleUpdatePassword}
      />
      {/* Reusing the password confirmation here is fine */}
      <PasswordConfirmation
        show={isSettingPassword}
        toggle={() => setIsSettingPassword(false)}
        onSubmit={handleSetPassword}
        text={i18n().profile.setPassword}
      />
    </>
  )
}

export default PasswordLine
