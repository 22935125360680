import styled, { css } from 'styled-components'

export const Results = styled.div`
  position: relative;
  flex: 1 0;
  display: flex;
  flex-direction: column;
`

export const ResultsWrapper = styled.div``

export const TitleArea = styled.div`
  position: sticky;
  top: 61px;
  background-color: #343434;
  padding: 0 0 35px;
  box-shadow: ${(props) =>
    props.isScrolling ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
  transition: box-shadow 150ms ease-in-out;
  z-index: 1;
  color: #e5e5e5;

  @media screen and (max-width: 1280px) {
    top: 36.75px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  &::before {
    content: '';
    background: inherit;
    height: 61px;
    width: 100%;
    position: absolute;
    top: -61px;
    left: 0;

    @media screen and (max-width: 1280px) {
      height: 36.75px;
      top: -36.75px;
    }

    @media screen and (max-width: 768px) {
      height: 50px;
      top: -50px;
    }
  }

  @media screen and (min-width: 1440px) {
    top: 110px;

    &::before {
      height: 110px;
      top: -110px;
    }
  }

  p:nth-child(1) {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.1;
    text-transform: uppercase;

    @media screen and (max-width: 1280px) {
      font-size: 22px;
    }
  }

  p:nth-child(2) {
    font-weight: 300;
    font-size: 18px;
    line-height: 1.1;
    margin-top: 20px;

    @media screen and (max-width: 1280px) {
      font-size: 13px;
      margin-top: 15px;
    }
  }
`

const resultsAreaModifier = {
  withResults: () => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-template-rows: repeat(auto-fill, 250px);
    gap: 32px 21px;
    padding-bottom: 70px;

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(auto-fill, 187px);
      grid-template-rows: repeat(auto-fill, 187px);
      gap: 24px 15.75px;
      padding-bottom: 52px;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 20px;
    }
  `,
  noResults: () => css`
    display: block;
  `,
}

export const ResultsArea = styled.div`
  ${({ isEmpty }) => css`
    justify-content: center;
    position: relative;
    flex: 1 0;
    max-width: 1068px;
    width: 100%;
    margin: 0 auto;

    ${isEmpty
      ? resultsAreaModifier.noResults()
      : resultsAreaModifier.withResults()}
  `}
`

export const ButtonsArea = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
  background: linear-gradient(
    0deg,
    rgba(52, 52, 52, 1) 29%,
    rgba(255, 255, 255, 0) 85%
  );
  max-width: 1068px;
  width: 100%;
  margin: 0 auto;

  & > button {
    &:last-child {
      margin-left: 10px;
    }
  }
`

export const NoResults = styled.div`
  color: #e5e5e5;
  font-size: 18px;
  margin-top: -60px;

  @media screen and (max-width: 1280px) {
    font-size: 13px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: -110px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }

  div.textWrapper,
  div.formWrapper {
    height: calc(100vh - 75px);

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }

  div.textWrapper {
    display: flex;
    align-items: center;

    div.leftContent {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto 1fr auto;
      column-gap: 50px;
      row-gap: 28px;

      @media screen and (max-width: 768px) {
        margin: 0;
        text-align: center;
        grid-template-rows: auto 1fr;
      }

      p {
        &:nth-child(1) {
          grid-column: 1/2;
          grid-row: 1/2;

          @media screen and (max-width: 768px) {
            grid-column: 1/-1;
          }
        }
        &:nth-child(2) {
          grid-column: 1/2;
          grid-row: 2/3;

          @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 28px;
            margin-bottom: 60px;
          }
        }
      }

      .contactButton {
        width: 269px;
        height: 50px;
        background-color: #2b2b2b;
        color: #fff;
        font-size: 18px;
        border: 0;
        margin-top: 7px;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        grid-column: 1/2;
        grid-row: 3/4;

        > svg {
          margin-left: 5px;
        }

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      > img {
        grid-column: 2/3;
        grid-row: 1/-1;

        @media screen and (max-width: 768px) {
          grid-row: 2/3;
          height: 150px;
          align-self: center;
        }
      }
    }
    }
  }

  div.formWrapper {
    padding: 59px 0 56px 40px;
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 0 0 40px;
    }

    div.formArea {
      max-width: 393px;
      width: 100%;

      p {
        &:nth-child(2) {
          font-size: 15px;
        }

        @media screen and (max-width: 768px) {
          &:nth-child(1) {
            text-align: center;
          }
        }
      }

      form {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        button.submitForm {
          align-self: flex-start;
          padding: 0 20px;
          font-size: 16px;
          margin-top: 25px;

          @media screen and (min-width: 1440px) {
            padding: 0 40px;
            font-size: 25px;
          }

          @media screen and (max-width: 768px) {
            height: 40px;
          }
        }
      }
    }
  }

  div.leftContent,
  div.formWrapper {
    p {
      &:nth-child(1) {
        font-size: 32px;
        text-transform: uppercase;
        margin-bottom: 28px;
        font-weight: 500;

        @media screen and (max-width: 768px) {
          font-size: 28px;
        }
      }
    }
  }

  button.scrollTop {
    background-color: #404040;
    width: 49px;
    height: 44px;
    color: #fff;
    border: 0;
    cursor: pointer;
    position: absolute;
    right: -60px;
    bottom: 60px;
    z-index: 13;

    @media screen and (max-width: 768px) {
      bottom: 40px;
      right: 0;
    }
  }
`
