import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 25px;
  right: 0;
  left: 0;
  margin-left: 347px;

  .tip-wrapper {
    position: absolute;
    bottom: 0;
    min-height: 35px;
    max-height: 45px;
    height: 100%;
  }

  .tip-animation-area {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .tip {
    padding: 10px 20px 10px 15px;
    border-radius: 18px;
    position: relative;
    color: rgba(229, 229, 229, 0.7);
    max-width: 428px;
    text-align: center;
  }

  .tip-close {
    position: absolute;
    background-color: #c4c4c4;
    border-radius: 50%;
    color: rgba(33, 33, 33, 0.8);
    font-size: 15px;
    height: 15px;
    width: 15px;
    top: -3px;
    right: -3px;
    cursor: pointer;
  }
`
