import React from 'react'
import { CardWrapper } from '../../../components/PageCard/styles'
import PageCard from '../../../components/PageCard'
import ComponentWrapper from '../../../components/PageComponent/ComponentWrapper'
import { i18n } from '../../../i18n'

const ApplicationTypeScreen = () => {
  const { title, text, labelStandard, labelRetrofit } = i18n().applicationType

  return (
    <ComponentWrapper title={title} textLine={text}>
      <CardWrapper>
        <PageCard
          img='application-standard'
          label={labelStandard}
          name='applicationType'
          value='isStandard'
        />
        <PageCard
          img='application-retrofit'
          label={labelRetrofit}
          name='applicationType'
          value='isRetrofit'
        />
      </CardWrapper>
    </ComponentWrapper>
  )
}

export default ApplicationTypeScreen
