import React, { createContext, useContext, useEffect, useState } from 'react'

const HelpTipsContext = createContext({})

export const HelpTipsContextProvider = ({ children }) => {
  const [hiddenTips, setHiddenTips] = useState([])

  const setVisibleTipsAtPageLoad = () => {
    const storedTips = window.localStorage.getItem('rpcHiddenTips')
    if (storedTips) {
      const parsedState = JSON.parse(storedTips)
      setHiddenTips(parsedState)
    }
  }

  const getStateOfTip = (tip) => !hiddenTips.includes(tip)

  const hideTip = (tip) => setHiddenTips([...hiddenTips, tip])

  useEffect(() => {
    if (hiddenTips.length) {
      window.localStorage.setItem('rpcHiddenTips', JSON.stringify(hiddenTips))
    }
  }, [hiddenTips])

  return (
    <HelpTipsContext.Provider
      value={{
        hiddenTips,
        setVisibleTipsAtPageLoad,
        hideTip,
        getStateOfTip,
      }}
    >
      {children}
    </HelpTipsContext.Provider>
  )
}

export const useHelpTips = () => {
  const contextFunctions = useContext(HelpTipsContext)
  return { ...contextFunctions }
}
