// This component is used when we need short 1-2 line messages, like warnings and quick info. For larger
// pieces of text, it is recommended to use the Tooltip.js component

import React from 'react'
import './Tooltip.css'
import { CSSTransition } from 'react-transition-group'

const Dialog = ({ relative, content, trigger }) => (
  <div className='bubble-wrapper'>
    <CSSTransition
      in={trigger}
      mountOnEnter
      unmountOnExit
      timeout={500}
      classNames='tool'
    >
      <div className={`bubble dialog-bubble bubble-${relative}`}>{content}</div>
    </CSSTransition>
  </div>
)

export default Dialog
