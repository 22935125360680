import { FiX as IconClose } from 'react-icons/fi'
import React from 'react'
import { Chip } from './styles'

const ChipComponent = ({ name, selected, dead, handler }) => (
  <Chip onClick={handler} selected={selected || dead} className='chip'>
    <span>{name}</span>
    {selected && <IconClose size={10} style={{ marginLeft: 4 }} />}
  </Chip>
)

export default ChipComponent
