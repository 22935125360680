import React, { useState, useRef, useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import scrollIntoView from 'scroll-into-view'
import { setErrorMessage } from '../../../actions'
import { store } from '../../../store'
import Loading from '../../../components/Loading'
import { i18n } from '../../../i18n'
import { NoResults } from './styles'
import Button from '../../../components/Button'
import Input from '../../../components/Input'
import imageContact from '../../../images/search-contact.svg'
import GenericModal from '../../../components/Modals/Generic'
import { sendEmail } from '../../../services/firebase/functions'

const NoResultsComponent = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const { sorry, noProductFound, scrollDown, form, imgAltText } =
    i18n().results.noResultsForm
  const { generic, name, email, phone } = i18n().inputTexts

  const topRef = useRef()
  const bottomRef = useRef()

  const handlePhoneInput = (_, __, ___, formattedValue) => {
    formik.setFieldValue('phone', formattedValue)
  }

  const formSchema = yup.object().shape({
    name: yup.string().required(generic.required),
    email: yup.string().email(email.validation).required(generic.required),
    phone: yup.string(),
  })

  const handleSubmit = async (values) => {
    setIsLoading(true)
    try {
      const urlPath = window.location.origin
      await sendEmail({ ...values }, urlPath)
      setHasSent(true)
      formik.resetForm()
    } catch (error) {
      store.dispatch(setErrorMessage(error.message))
    }
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
    },
    validationSchema: formSchema,
    onSubmit: (values) => handleSubmit(values),
  })

  const { lang } = store.getState()

  useEffect(() => {
    Object.keys(formik.errors).forEach((fieldName) => {
      formik.touched[fieldName] && formik.setFieldTouched(fieldName)
    })
  }, [lang])

  return (
    <NoResults>
      <GenericModal
        show={hasSent}
        text={form.sent}
        toggle={() => setHasSent(false)}
      />
      {isLoading && <Loading showBackground />}
      <div className='textWrapper' ref={topRef}>
        <div className='leftContent'>
          <p>{sorry}</p>
          <p>{noProductFound}</p>
          <button
            type='button'
            className='contactButton'
            onClick={() => scrollIntoView(bottomRef.current)}
          >
            {scrollDown} <FiChevronDown />
          </button>
          <img src={imageContact} alt={imgAltText} />
        </div>
      </div>
      <div className='formWrapper' ref={bottomRef}>
        <div className='formArea'>
          <p>{form.title}</p>
          <p>{form.subtitle}</p>
          <form onSubmit={formik.handleSubmit}>
            <Input
              name='name'
              label={name.label}
              type='text'
              placeholder={name.placeholder}
              value={formik.values.name}
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name}
            />
            <Input
              name='email'
              label={email.label}
              type='email'
              placeholder={email.placeholder}
              value={formik.values.email}
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />
            <Input
              name='phone'
              label={phone.label}
              type='tel'
              placeholder={phone.placeholder}
              optional
              value={formik.values.phone}
              onChange={handlePhoneInput}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && formik.errors.phone}
            />
            <Button
              type='submit'
              className='submitForm'
              disabled={!(formik.isValid && formik.dirty)}
            >
              {i18n().common.send}
            </Button>
          </form>
        </div>
        <button
          type='button'
          className='scrollTop'
          onClick={() => scrollIntoView(topRef.current)}
        >
          <FiChevronUp size={22} />
        </button>
      </div>
    </NoResults>
  )
}

export default NoResultsComponent
