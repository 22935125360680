import styled from 'styled-components'

export const History = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 4px;
  background-color: #414141;
  padding: 0 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    padding: 0 25px;
  }

  & > p {
    line-height: 1.1;
    color: rgba(229, 229, 229, 0.7);

    &:nth-child(1) {
      font-weight: 700;
      font-size: 18px;

      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 15px;
      margin-top: 5px;

      @media screen and (max-width: 1280px) {
        font-size: 11.25px;
      }
    }
  }
`

export const Empty = styled.p`
  grid-column: 1 / -1;
  text-align: center;
  color: #e5e5e5;

  & > a {
    color: inherit;
  }
`
