import React from 'react'

const Bell = ({ color }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M22.2344 10.7499C21.8204 10.7499 21.4844 10.4139 21.4844 9.99994C21.4844 7.12793 20.3665 4.42896 18.3364 2.39795C18.0435 2.10498 18.0435 1.63 18.3364 1.33704C18.6294 1.04407 19.1044 1.04407 19.3975 1.33704C21.7105 3.65094 22.9844 6.72803 22.9844 9.99994C22.9844 10.4139 22.6484 10.7499 22.2344 10.7499Z'
      fill={color}
    />
    <path
      d='M1.73438 10.75C1.32037 10.75 0.984375 10.414 0.984375 10C0.984375 6.72809 2.25842 3.651 4.57233 1.33801C4.8653 1.04504 5.34045 1.04504 5.63342 1.33801C5.92639 1.63098 5.92639 2.10614 5.63342 2.39911C3.60242 4.42902 2.48438 7.12799 2.48438 10C2.48438 10.414 2.14838 10.75 1.73438 10.75Z'
      fill={color}
    />
    <path
      d='M11.9844 24C9.91638 24 8.23438 22.318 8.23438 20.25C8.23438 19.836 8.57037 19.5 8.98438 19.5C9.39838 19.5 9.73438 19.836 9.73438 20.25C9.73438 21.4911 10.7433 22.5 11.9844 22.5C13.2253 22.5 14.2344 21.4911 14.2344 20.25C14.2344 19.836 14.5704 19.5 14.9844 19.5C15.3984 19.5 15.7344 19.836 15.7344 20.25C15.7344 22.318 14.0524 24 11.9844 24Z'
      fill={color}
    />
    <path
      d='M20.2345 21H3.7345C2.76935 21 1.98438 20.215 1.98438 19.2501C1.98438 18.7379 2.2074 18.2531 2.5965 17.92C4.11737 16.6349 4.98438 14.7671 4.98438 12.7881V9.99994C4.98438 6.14008 8.12445 3 11.9845 3C15.8444 3 18.9844 6.14008 18.9844 9.99994V12.7881C18.9844 14.7671 19.8514 16.6349 21.3624 17.913C21.7614 18.2531 21.9844 18.7379 21.9844 19.2501C21.9844 20.215 21.1995 21 20.2345 21ZM11.9845 4.5C8.95135 4.5 6.48438 6.96698 6.48438 9.99994V12.7881C6.48438 15.2089 5.42346 17.495 3.57446 19.058C3.53949 19.088 3.48438 19.1501 3.48438 19.2501C3.48438 19.3859 3.59845 19.5 3.7345 19.5H20.2345C20.3704 19.5 20.4844 19.3859 20.4844 19.2501C20.4844 19.1501 20.4295 19.088 20.3964 19.06C18.5453 17.495 17.4844 15.2089 17.4844 12.7881V9.99994C17.4844 6.96698 15.0175 4.5 11.9845 4.5Z'
      fill={color}
    />
    <path
      d='M11.9844 4.5C11.5704 4.5 11.2344 4.164 11.2344 3.75V0.75C11.2344 0.335999 11.5704 0 11.9844 0C12.3984 0 12.7344 0.335999 12.7344 0.75V3.75C12.7344 4.164 12.3984 4.5 11.9844 4.5Z'
      fill={color}
    />
  </svg>
)

export default Bell
