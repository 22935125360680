import styled from 'styled-components'

export const Card = styled.div`
  width: 253px;
  height: 308px;
  background-color: ${(props) => (props.active ? '#2E2E2E' : '#414141')};
  transition: background-color 0.15s ease-in;
  border-radius: 4px;
  padding: 18px 20px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 1280px) {
    width: 190px;
    height: 230px;
  }

  @media screen and (max-width: 768px) {
    &:last-child {
      margin-top: 15px;
    }
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 214px;
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 1280px) {
    height: 160px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export const TextWrapper = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;

  & > span {
    font-size: 22px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #e5e5e5;
    font-weight: 500;

    @media screen and (max-width: 1280px) {
      font-size: 16px;
    }
  }
`

export const CardWrapper = styled.div`
  max-width: 554px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    max-width: 415px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    column-gap: 15px;
  }
`
