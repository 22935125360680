import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import config from '../../rpc.config'

const { TIMEOUT_VALUES } = config

const FadeTransition = ({ index, children }) => (
  <TransitionGroup className='animation-wrapper'>
    <CSSTransition
      key={index}
      timeout={TIMEOUT_VALUES}
      classNames='dissolve'
      unmountOnExit
    >
      {children}
    </CSSTransition>
  </TransitionGroup>
)

export default FadeTransition
