import { i18n } from '../i18n'

const errorHandler = (error) => {
  switch (error.code) {
    case 'auth/user-not-found':
      return i18n().errorHandlerMessages.userNotFound
    case 'auth/wrong-password':
      return i18n().errorHandlerMessages.wrongPassword
    case 'auth/user-token-expired':
      return i18n().errorHandlerMessages.userTokenExpired
    case 'auth/email-already-in-use':
      return i18n().errorHandlerMessages.emailAlreadyInUse
    case 'auth/account-exists-with-different-credential':
      return i18n().errorHandlerMessages.accountExistsWithDifferentCredential
    case 'auth/requires-recent-login':
      return i18n().errorHandlerMessages.requiresRecentAuth
    case 'auth/network-request-failed':
      return i18n().errorHandlerMessages.networkError
    case 'auth/too-many-requests':
      return i18n().errorHandlerMessages.tooManyAttempts
    default:
      return `${error.code}: ${error.message}`
  }
}

export default errorHandler
