const convertNameToColor = (username) => {
  let hash = 0
  for (let i = 0; i < username.length; i++) {
    hash = username.charCodeAt(i) + ((hash << 5) - hash)
  }

  return `hsl(${hash % 360}, 75%, 50%)`
}

export default convertNameToColor
