import React from 'react'
import { MobileMainScreen, Badge } from './styles'
import { store } from '../../../store'
import { i18n } from '../../../i18n'

const SimpleText = ({ imagePath, android, children }) => (
  <MobileMainScreen>
    {imagePath && <img src={imagePath} alt='' />}
    <p>{children}</p>
    {android && (
      <Badge>
        <a href='https://play.google.com/store/apps/details?id=com.sauvara.roofpitchcalculator'>
          <img
            alt={i18n().main.getItOnGooglePlay}
            src={`https://play.google.com/intl/pt-BR/badges/static/images/badges/${
              store.getState().lang
            }_badge_web_generic.png`}
          />
        </a>
      </Badge>
    )}
  </MobileMainScreen>
)

export default SimpleText
