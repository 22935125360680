import React from 'react'
import { FiCheck } from 'react-icons/fi'
import { Checkbox, Label } from './styles'

const CheckboxComponent = ({ id, name, ...rest }) => (
  <Label htmlFor={id}>
    <input type='checkbox' id={id} {...rest} />
    <Checkbox>
      <FiCheck />
    </Checkbox>
    <span>{name}</span>
  </Label>
)

export default CheckboxComponent
