import React, { useState } from 'react'
import { Page } from '../../components/PageComponent/styles'
import { ProfilePage, InfoCard, NotificationsSwitcher } from '../Profile/styles'
// import Loading from '../../components/Loading'
import LanguageLine from '../../components/ProfileLine/Variants/Language'
import { Line } from '../../components/ProfileLine/Base/styles'
// import DeleteAccount from '../../components/Modals/DeleteAccount'

// import { deleteProfile } from '../../services/firebase/auth'

import { store } from '../../store'
import { i18n } from '../../i18n'

// import { FiTrash2 } from 'react-icons/fi'

const Settings = () => {
  const [isNotificationsOn, setNotifications] = useState(true)
  // const [isDeleting, setIsDeleting] = useState(false)

  // const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { settings, notifications } = i18n().profile

  // const handleAccountDelete = async password => {
  //     setIsDeleting(true)
  //     try {
  //         await deleteProfile(password)
  //     } catch (error) {
  //         showErrorMessage(error)
  //     }
  //     setIsDeleting(false)
  //     setShowConfirmPassword(false)
  // }

  return (
    <Page>
      {/* {isDeleting && <Loading showBackground />} */}
      <ProfilePage>
        <InfoCard>
          <p>{settings}</p>
          <LanguageLine />
          <Line>
            <img src={store.getState().images['bell-settings.svg']} alt='' />
            <span>{notifications}</span>
            <NotificationsSwitcher
              active={isNotificationsOn}
              onClick={() => setNotifications(!isNotificationsOn)}
            />
          </Line>
        </InfoCard>
        {/* <>
                    <DeleteAccount show={showConfirmPassword} toggle={setShowConfirmPassword} onSubmit={handleAccountDelete} />
                    <DeleteProfileButton onClick={() => setShowConfirmPassword(true)}><FiTrash2 />{i18n().profile.deleteAccount.title}</DeleteProfileButton>
                </> */}
      </ProfilePage>
    </Page>
  )
}

export default Settings
