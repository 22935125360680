import React from 'react'

import PageComponent from '../../components/PageComponent'
import { HomeWrapper, HomeInner } from './styles'

const Home = () => (
  <HomeWrapper>
    <HomeInner>
      <PageComponent />
    </HomeInner>
  </HomeWrapper>
)

export default Home
