import React from 'react'

import { Page } from '../../components/PageComponent/styles'
import { Wrapper, Inner } from './styles'
import { i18n } from '../../i18n'

const Alerts = () => {
  const { title, noAlerts } = i18n().alerts
  return (
    <Page>
      <Wrapper>
        <h2>{title}</h2>
        <Inner>
          <p>{noAlerts}</p>
        </Inner>
      </Wrapper>
    </Page>
  )
}

export default Alerts
