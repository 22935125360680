import styled from 'styled-components'

export const Page = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const Container = styled.div`
  flex: 1 1;
  position: relative;
  width: 100%;
  height: 495px;
  margin-left: 347px;
  margin-top: 60px;

  @media screen and (min-width: 1440px) {
    margin-top: 110px;
  }

  @media screen and (max-width: 1280px) {
    margin-top: 40px;
    margin-left: 240px;
  }

  @media screen and (max-width: 768px) {
    margin: 50px 0;
  }
`

export const PageWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  /* Transition animations: */
  .input-screen {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }
`

export const ComponentWrapper = styled.div`
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 1.1;
    color: #e5e5e5;
    margin-bottom: 28px;
    text-transform: uppercase;

    @media screen and (max-width: 1280px) {
      font-size: 24px;
      margin-bottom: 21px;
    }

    @media screen and (max-width: 768px) {
      text-align: center;
      font-size: 26px;
    }
  }
`

export const TextLine = styled.p`
  font-size: 18px;
  line-height: 1.1;
  color: #e5e5e5;
  margin: 28px 0 45px;
  font-weight: 300;

  @media screen and (max-width: 1280px) {
    font-size: 14px;
    margin: 21px 0 34px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const RoofPitchTitle = styled.div`
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  button {
    width: 38px;
    height: 38px;
    border: 0;
    background-color: #414141;
    cursor: pointer;
    border-radius: 4px;
    color: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;

    @media screen and (max-width: 1280px) {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      width: 38px;
      height: 38px;
      margin-right: 14px;
    }

    svg {
      width: 24px;
      height: auto;

      @media screen and (max-width: 1280px) {
        width: 16px;
      }

      @media screen and (max-width: 768px) {
        width: 24px;
      }
    }
  }

  div {
    display: flex;

    @media screen and (max-width: 768px) {
      margin: 0 auto 21px;
    }

    h2 {
      margin: 0;
    }
  }

  span {
    font-weight: 300;
    font-size: 13px;
    line-height: 2;
    color: #999999;
    align-self: flex-end;
    margin-left: 14px;
  }
`
