import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Main from './Main'
import SimpleText from './Main/SimpleText'
import { store } from '../store'
import {
  addImageToStore,
  increaseImgCount,
  changeLanguage,
  setDeviceTypeRedux,
} from '../actions'
import { checkIfUserIsLoggedIn } from '../services/firebase/auth'
import { i18n } from '../i18n'
import { useAlgolia } from '../contexts/AlgoliaSearchContext'
import { useHelpTips } from '../contexts/HelpTipsContext'
import { useFavorites } from '../contexts/FavoritesContext'
import logo from '../images/logo.svg'

const PreMain = () => {
  const [deviceType, setDeviceType] = useState('')
  const { setChipsOnPageLoad } = useAlgolia()
  const { setVisibleTipsAtPageLoad } = useHelpTips()
  const { syncFavoritesWithDB, clearFavorites } = useFavorites()

  const loadImages = () => {
    const images = {}
    let i = 0
    const requiredImages = require.context(
      '../images',
      false,
      /\.(png|jpe?g|svg)$/
    )
    requiredImages.keys().forEach((item) => {
      const image = requiredImages(item)
      images[item.replace('./', '')] = image
      const img = new Image()
      img.onload = () => {
        i++
        store.dispatch(increaseImgCount(i))
      }
      img.src = image
    })
    store.dispatch(addImageToStore(images))
  }

  useEffect(() => {
    if (localStorage.getItem('rpcPreferredLanguage'))
      store.dispatch(
        changeLanguage(localStorage.getItem('rpcPreferredLanguage'))
      )
    const agent = navigator.userAgent
    const regexAndroid = /android/gi
    const regexiPhone = /iPhone|iPad/gi
    if (regexAndroid.test(agent)) {
      setDeviceType('MOBILE')
      store.dispatch(setDeviceTypeRedux('MOBILE'))
    } else if (regexiPhone.test(agent)) {
      setDeviceType('MOBILE_APPLE')
      store.dispatch(setDeviceTypeRedux('MOBILE_APPLE'))
    } else {
      setDeviceType('DESKTOP')
      store.dispatch(setDeviceTypeRedux('DESKTOP'))
    }
  }, [])

  const currentLanguage = store.getState().lang

  useEffect(() => {
    const listOfChips = [
      { name: i18n().search.chips.round, value: 'isRound', index: 0 },
      { name: i18n().search.chips.square, value: 'isSquare', index: 1 },
      { name: i18n().search.chips.standard, value: 'isStandard', index: 2 },
      { name: i18n().search.chips.retrofit, value: 'isRetrofit', index: 3 },
      { name: i18n().search.chips.epdm, value: 'isEpdm', index: 4 },
      { name: i18n().search.chips.silicone, value: 'isSilicone', index: 5 },
      { name: i18n().search.chips.residential, value: 'isNonMetal', index: 6 },
      { name: i18n().search.chips.metal, value: 'isMetal', index: 7 },
      { name: i18n().search.chips.sloped, value: '-0/12', index: 8 },
      { name: i18n().search.chips.flat, value: '0/12', index: 9 },
    ]
    setChipsOnPageLoad(listOfChips)
  }, [currentLanguage])

  useEffect(() => {
    if (deviceType === 'DESKTOP' || deviceType === 'MOBILE_APPLE') {
      checkIfUserIsLoggedIn()
      loadImages()
      setVisibleTipsAtPageLoad()
    }
  }, [deviceType])

  const userLoggedIn = store.getState().isUserLoggedIn

  useEffect(() => {
    if (userLoggedIn) {
      syncFavoritesWithDB(store.getState().currentUser.uid)
    } else {
      clearFavorites()
    }
  }, [userLoggedIn])

  const componentReturn = () => {
    if (deviceType === 'MOBILE') {
      return (
        <SimpleText imagePath={logo} android>
          {i18n().main.mobile}
        </SimpleText>
      )
    }
    const isLoadingImages =
      Object.keys(store.getState().images).length !==
        store.getState().imagesCount || store.getState().imagesCount < 1
    const isHanging =
      isLoadingImages || store.getState().isUserLoggedIn === null

    return (
      <Router>
        {isHanging ? (
          <SimpleText>{i18n().main.loading}</SimpleText>
        ) : (
          <Main deviceType={deviceType} />
        )}
      </Router>
    )
  }

  return <>{componentReturn()}</>
}

export default PreMain
