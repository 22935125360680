import React, { useEffect } from 'react'
import PageWithResultsScroll from '../../components/PageWithResultsScroll'
import { store } from '../../store'
import { setErrorMessage, setLoading } from '../../actions'
import { i18n } from '../../i18n'
import { useFavorites } from '../../contexts/FavoritesContext'

const Favorites = () => {
  const { favoriteFlashings, getFavorites, getNextFavorites, hasMoreToShow } =
    useFavorites()

  useEffect(() => {
    getFlashings()
  }, [])

  const getFlashings = async () => {
    store.dispatch(setLoading(true))
    try {
      await getFavorites()
    } catch (error) {
      store.dispatch(setErrorMessage(error))
    }
    store.dispatch(setLoading(false))
  }

  const texts = {
    title: i18n().favorites.title,
    empty: i18n().favorites.noFavorites,
    end: i18n().favorites.end,
  }

  const favoritesConfig = {
    results: favoriteFlashings,
    next: getNextFavorites,
    hasMore: hasMoreToShow,
    endMessage: texts.end,
    emptyMessage: texts.empty,
  }

  return <PageWithResultsScroll title={texts.title} config={favoritesConfig} />
}

export default Favorites
