import styled from 'styled-components'

export const Selector = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;

  & > span {
    display: block;
    height: inherit;
    width: inherit;
  }
`

export const Options = styled.div`
  min-width: 130px;
  position: absolute;
  left: -42px;
  top: 36px;
  background-color: #414141;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 14px 26px;
  cursor: default;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.1;
  color: #e5e5e5;
  z-index: 10;

  p {
    padding: 12px 0;
    text-align: center;
    cursor: pointer;

    &.active {
      color: #cfb26c;
    }

    & + p {
      border-top: 1px solid rgba(229, 229, 229, 0.3);
    }
  }
`
