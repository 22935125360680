import { createGlobalStyle } from 'styled-components'
import config from './rpc.config'

const { appear, enter, exit } = config.TIMEOUT_VALUES

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        scrollbar-color: #2E2E2E #3B3B3B;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #3B3B3B;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #2E2E2E;
        }
    }

    html, body, #root {
        height: 100%;
    }

    #root > div {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    body {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #343434;
    }

    // Transitions for modals:
    .modal-dissolve-appear {
        opacity: 0;
    }

    .modal-dissolve-appear.modal-dissolve-appear-active {
        opacity: 1;
        transition: ${`opacity ${appear}ms ease-in`};
    }

    .modal-dissolve-exit {
        opacity: 1;
    }

    .modal-dissolve-exit.modal-dissolve-exit-active {
        opacity: 0;
        transition: ${`opacity ${exit + 15}ms ease-in`};
    }

    .modal-dissolve-exit-done {
        opacity: 0;
    }

    .modal-dissolve-enter {
        opacity: 0;
    }

    .modal-dissolve-enter.modal-dissolve-enter-active {
        opacity: 1;
        transition: ${`opacity ${enter}ms ease-in`};
    }

    .modal-dissolve-enter-done {
        opacity: 1;
    }

    // Transitions for wrapped components:
    .animation-wrapper {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;

        & > div {
            width: 100%;
        }
    }

    .dissolve-appear {
        opacity: 0;
    }

    .dissolve-appear.dissolve-appear-active {
        opacity: 1;
        transition: ${`opacity ${appear}ms ease-in`};
    }

    .dissolve-exit {
        opacity: 1;
    }

    .dissolve-exit.dissolve-exit-active {
        opacity: 0;
        transition: ${`opacity ${exit + 15}ms ease-in`};
        position: absolute;
        top: 0;
        bottom: 0;
    }

    .dissolve-exit-done {
        opacity: 0;
    }

    .dissolve-enter {
        opacity: 0;
    }

    .dissolve-enter.dissolve-enter-active {
        opacity: 1;
        transition: ${`opacity ${enter}ms ease-in`};
        position: absolute;
        top: 0;
        bottom: 0;
    }

    .dissolve-enter-done {
        opacity: 1;
    }

    /* Pages animation */
    .page-dissolve-appear {
        opacity: 0;
    }

    .page-dissolve-appear.page-dissolve-appear-active {
        opacity: 1;
        transition: ${`opacity ${appear}ms ease-in`};
    }

    .page-dissolve-exit {
        opacity: 1;
    }

    .page-dissolve-exit.page-dissolve-exit-active {
        opacity: 0;
        transition: ${`opacity ${exit + 15}ms ease-in`};
        position: absolute;
        top: 0;
        bottom: 0;

        left: 0;
        right: 0;
    }

    .page-dissolve-exit-done {
        opacity: 0;
    }

    .page-dissolve-enter {
        opacity: 0;
    }

    .page-dissolve-enter.page-dissolve-enter-active {
        opacity: 1;
        transition: ${`opacity ${enter}ms ease-in`};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .page-dissolve-enter-done {
        opacity: 1;
    }
`

export default GlobalStyle
