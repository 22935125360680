import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { FiChevronDown } from 'react-icons/fi'
import { Dropdown, DropdownWrapper, List, ListItem } from './styles'
import { store } from '../../store'
import { toggleDropdown, selectDropdown, clickNext } from '../../actions'

class DropdownComponent extends Component {
  toggle = () => {
    const { isOpen } = store.getState().roofPitchDropdown
    store.dispatch(toggleDropdown(isOpen))
  }

  handleSelect = (e) => {
    store.dispatch(selectDropdown(e.target.dataset.value))
    store.dispatch(clickNext(store.getState().pageIndex))
  }

  handleClickOutside = () => {
    store.dispatch(toggleDropdown(true))
  }

  render() {
    const { content } = this.props
    const { isOpen, currentValue } = store.getState().roofPitchDropdown
    return (
      <DropdownWrapper>
        <Dropdown open={isOpen} onClick={this.toggle}>
          <span>
            {!currentValue || currentValue === '0/12' ? '' : currentValue}
          </span>
          <FiChevronDown />
        </Dropdown>
        {isOpen && (
          <List>
            <ul>
              {content.map((item) => (
                <ListItem
                  key={item}
                  data-value={item}
                  onClick={this.handleSelect}
                  className={`dropdown-item${
                    currentValue === item ? 'dropdown-selected' : ''
                  }`}
                >
                  {item}
                </ListItem>
              ))}
            </ul>
          </List>
        )}
      </DropdownWrapper>
    )
  }
}

export default onClickOutside(DropdownComponent)
