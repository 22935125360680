import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Page } from '../PageComponent/styles'
import Loading from '../Loading'
import { NoMoreResults, Wrapper } from './styles'
import ResultCard from '../ResultCard'
import { store } from '../../store'

const PageWithResultsScroll = ({ title, config }) => {
  const { results, next, hasMore, endMessage, emptyMessage } = config
  const loading = store.getState().isLoading

  return (
    <Page>
      <Wrapper>
        <h2>{title}</h2>
        <div id='scrollableDiv'>
          {loading ? (
            <Loading />
          ) : (
            <InfiniteScroll
              dataLength={results.length}
              next={next}
              hasMore={hasMore}
              scrollableTarget='scrollableDiv'
              loader={<Loading />}
              endMessage={
                <NoMoreResults>
                  {!results.length ? emptyMessage : endMessage}
                </NoMoreResults>
              }
              className='listArea'
              style={{ overflow: 'hidden' }}
            >
              {results.map((flashing) => (
                <ResultCard
                  key={flashing.objectID || flashing.uid}
                  data={flashing}
                />
              ))}
            </InfiniteScroll>
          )}
        </div>
      </Wrapper>
    </Page>
  )
}

export default PageWithResultsScroll
