import { store } from '../store'
import en from './en'
import es from './es'
import pl from './pl'

export const i18n = () => {
  switch (store.getState().lang) {
    case 'es':
      return es
    case 'pl':
      return pl
    default:
      return en
  }
}
