import { createNullCache } from '@algolia/cache-common'
import { result as algoliaConfig } from '../../algolia-config.json'
import { errorHandler } from '../../utils'

const algoliasearch = require('algoliasearch')

const ALGOLIA_ID = algoliaConfig.app_id
const ALGOLIA_SEARCH_KEY = algoliaConfig.search_key

const algoliaClient = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY, {
  responsesCache: createNullCache(),
  requestsCache: createNullCache(),
})

const indexValue = process.env.REACT_APP_ALGOLIA_INDEX_ENV || 'sandbox'
const index = algoliaClient.initIndex(`${indexValue}_FLASHINGS`)

export const textSearch = async (text, page) => {
  try {
    const { hits } = await index.search(text, {
      filters: `isDraft:false`,
      page,
      hitsPerPage: 20,
    })
    if (hits.length) {
      return hits
    }
  } catch (error) {
    throw errorHandler(error)
  }
}

export const chipsSearch = async (selectedChips, page) => {
  try {
    const formattedChips = selectedChips.map(({ value }) =>
      value.includes('0/12') ? `roofPitch:${value}` : `${value}:true`
    )
    if (formattedChips.length > 0) {
      const { hits } = await index.search('', {
        filters: `isDraft:false`,
        facetFilters: formattedChips,
        page,
        hitsPerPage: 20,
      })
      if (hits.length) {
        return hits
      }
    }
  } catch (error) {
    throw errorHandler(error)
  }
}
