import styled from 'styled-components'

export const MobileMainScreen = styled.div`
  justify-content: center;
  align-items: center;
  color: #e5e5e5;
  font-weight: 300;
  padding: 0 30px;
  text-align: center;

  img {
    margin-bottom: 20px;
  }
`

export const Badge = styled.div`
  max-width: 200px;

  img {
    width: 100%;
  }
`
