import styled, { css } from 'styled-components'

const loadingModifiers = {
  full: () => css`
    bottom: 0;
  `,
}

export const Loading = styled.div`
  ${({ showBackground, full }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${showBackground ? 'fixed' : 'absolute'};
    top: 0;
    right: 0;
    left: 0;
    background-color: ${showBackground
      ? 'rgba(222, 222, 222, 0.5)'
      : 'transparent'};
    z-index: 100;

    ${full && loadingModifiers.full()}
  `}
`
