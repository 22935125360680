import React, { useState } from 'react'
import Base from '../Base'
import { store } from '../../../store'
import { i18n } from '../../../i18n'

import Translate from '../../CustomIcons/Translate'
import LanguageChange from '../../Modals/LanguageChange'

const NameLine = () => {
  const [isShowingModal, toggleModal] = useState(false)

  return (
    <>
      <Base
        icon={<Translate />}
        label={i18n().profile.language.title}
        handleEnter={() => toggleModal(true)}
      >
        <span>{i18n().languages[store.getState().lang]}</span>
      </Base>
      <LanguageChange show={isShowingModal} toggle={toggleModal} />
    </>
  )
}

export default NameLine
