import React, { useEffect } from 'react'
import { Page, PageWrapper, Container } from './styles'
import Navigation from './Navigation'
import { store } from '../../store'
import { clickPrevious, clickNext } from '../../actions'
import PipeSizeScreen from '../../screens/Home/PipeSizeScreen'
import PipeTypeScreen from '../../screens/Home/PipeTypeScreen'
import RoofTypeScreen from '../../screens/Home/RoofTypeScreen'
import RoofPitchScreen from '../../screens/Home/RoofPitchScreen'
import WeatherProtectionScreen from '../../screens/Home/WeatherProtectionScreen'
import ApplicationTypeScreen from '../../screens/Home/ApplicationTypeScreen'
import ResultsScreen from '../../screens/Home/ResultsScreen'
import FadeTransition from '../FadeTransition'
import TipsArea from '../TipsArea'
import { isNavigationAllowed } from '../../utils'

const PageComponent = () => {
  const currentPage = store.getState().pageIndex

  const components = {
    1: <PipeSizeScreen />,
    2: <PipeTypeScreen />,
    3: <RoofTypeScreen />,
    4: <RoofPitchScreen />,
    5: <WeatherProtectionScreen />,
    6: <ApplicationTypeScreen />,
    7: <ResultsScreen />,
  }

  useEffect(() => {
    const handleHitNext = (e) => {
      if (e.altKey && e.key === 'ArrowLeft') {
        e.preventDefault()
        store.dispatch(clickPrevious(currentPage))
      }
      if (
        e.altKey &&
        e.key === 'ArrowRight' &&
        isNavigationAllowed(currentPage) &&
        currentPage < 7
      ) {
        e.preventDefault()
        store.dispatch(clickNext(currentPage))
      }
    }

    window.addEventListener('keydown', handleHitNext)
    return () => {
      window.removeEventListener('keydown', handleHitNext)
    }
  }, [currentPage])

  return (
    <Page>
      <Navigation />
      <Container>
        <PageWrapper>
          <FadeTransition index={currentPage}>
            {components[currentPage]}
          </FadeTransition>
        </PageWrapper>
      </Container>
      {store.getState().deviceType === 'DESKTOP' && <TipsArea />}
    </Page>
  )
}

export default PageComponent
