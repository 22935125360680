const texts = {
  common: {
    or: 'or',
    ok: 'OK',
    cancel: 'Cancel',
    delete: 'Delete',
    notApplicable: 'N/A',
    yes: 'Yes',
    no: 'No',
    send: 'Send',
  },
  modals: {
    passwordConfirmation: 'Please provide your password to proceed.',
  },
  main: {
    loading: 'Loading app...',
    mobile: 'Roof Pitch Calculator is available on Google Play store.',
    getItOnGooglePlay: 'Get it on Google Play',
  },
  navbar: {
    history: 'History',
    favorties: 'Favorites',
    about: 'About',
    signIn: 'Sign in',
    signUp: 'Sign up',
    profile: 'Profile',
    settings: 'Settings',
    logOut: 'Log out',
  },
  search: {
    lookingFor: "I'm looking for flashings that support",
    recent: 'Recent searches',
    title: 'Search',
    noResults: 'There are no flashings matching your search.',
    noMoreResults: 'No more flashings to show.',
    chips: {
      round: 'Round pipes',
      square: 'Square pipes',
      standard: 'Standard application',
      retrofit: 'Retrofit application',
      epdm: 'EPDM protection',
      silicone: 'Silicone protection',
      residential: 'Residental roofs',
      metal: 'Metal roofs',
      sloped: 'Sloped flashings',
      flat: 'Flat flashings',
    },
    clearSelection: 'Clear selection',
  },
  favorites: {
    title: 'Favorites',
    noFavorites: 'There are no favorite flashings to be displayed yet.',
    end: 'No more flashings to show.',
  },
  aboutModal: {
    versionText: 'v',
    developedBy: 'Proudly developed by',
    privacyPolicy: {
      title: 'Privacy Policy',
      content: [
        "Your privacy is important to us. It is Sauvara's policy to respect your privacy regarding any information we may collect from you across our website and other sites we own and operate.",
        "We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we're collecting it and how it will be used.",
        "We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we'll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.",
        "We don't share any personally identifying information publicly or with third-parties, except when required to by law.",
        'Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.',
        'You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.',
        'Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. Feel free to contact us at support@sauvara.com if you have any questions about how we handle user data and personal information.',
        'This policy is effective as of 10 February 2020.',
      ],
    },
    contactUs: {
      title: 'Contact us',
      content: [
        'You can contact us any time at support@sauvara.com if you have any suggestions or need assistance regarding the use of the Roof Pitch Calculator.',
      ],
    },
  },
  alerts: {
    title: 'Alerts',
    noAlerts: 'There are no alerts to be displayed yet.',
  },
  languages: {
    en: 'English',
    es: 'Spanish',
    pl: 'Polish',
  },
  navigation: {
    next: 'Next',
    back: 'Back',
    newSearch: 'New search',
  },
  tips: {
    pressAltRightToGoNext:
      'After entering the required information, press alt (or option on Mac) + right arrow or click on the next button to go to the next screen',
    pressAltLeftToGoBack:
      'Press alt (or option on Mac) + left arrow or click on the back button to go back to the previous screen',
  },
  pipeSize: {
    title: 'Pipe size',
    pageText: [
      'Please, enter the pipe size in inches or millimeters and our tool will take care of converting the value just for your reference.',
      'For pipes with specifications in inches you can enter any value from 1/16 to 39-1/2. In this case you should',
      'For pipes with specifications in millimeters you can enter any numeric value from 1.5875 to 1003.3.',
    ],
    instructions: {
      readTheInstructions: 'read the instructions',
      text: [
        'Fractional inches are acceptable in the following format 1/16 or 2-1/16 but only the following fractions are allowed: 1/16, 1/8, 3/16, 1/4, 5/16, 3/8, 7/16, 1/2, 9/16, 5/8, 11/16, 3/4, 13/16, 7/8 and 15/16.',
        ' You can even insert decimal inches like 0.0625 or 2.0625 (the same as 1/16 and 2-1/16 respectively).',
      ],
    },
    labelIn: 'Inches',
    labelMm: 'Millimeters',
    errorIn:
      'is not a valid value. Please enter a valid value. Read the instructions above for help.',
    errorMm:
      'is not a valid value. Please enter a value between 1.5875 and 1003.3.',
  },
  pipeType: {
    title: 'Pipe type',
    text: 'Is the pipe round or square? Pick the correct one below.',
    labelRound: 'Round',
    labelSquare: 'Square',
  },
  roofType: {
    title: 'Roof type',
    text: 'What is the type of roof? Choose the correct one below.',
    labelMetal: 'Metal',
    labelResidential: 'Residential',
  },
  roofPitch: {
    title: 'Roof pitch',
    text: 'Is the roof sloped or flat? Pick the correct one below.',
    labelSloped: 'Sloped',
    labelFlat: 'Flat',
    step: 'Step',
  },
  weatherProtection: {
    title: 'Weather protection',
    text: 'Made of EPDM or High Temp Red Silicone.',
    labelEPDM: 'EPDM',
    labelSilicone: 'Silicone',
  },
  applicationType: {
    title: 'Application type',
    text: 'Is this for existing or a retrofit installation? Choose the correct application below.',
    labelStandard: 'Standard',
    labelRetrofit: 'Retrofit',
  },
  results: {
    results: 'Results',
    title: 'Here is the list of products we recommend for you',
    success: 'Click on any product below to see its full information.',
    noResultsForm: {
      sorry: 'We are sorry',
      noProductFound:
        'It seems like your request might need some non-standard solution, and we would like to take this challenge! Please, enter your contact information below. A sales representative will be contacting you soon regarding your needs.',
      scrollDown: 'Scroll down to contact us',
      imgAltText: 'Hand holding an email envelope inside a tablet',
      form: {
        title: 'Contact us',
        subtitle: 'Please, enter your information below.',
        sent: 'Successfully sent',
      },
    },
  },
  inputTexts: {
    email: {
      label: 'Email',
      placeholder: 'Enter your email',
      validation: 'Please insert a valid email',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter your Password',
      repeatYourPassword: 'Repeat your password',
    },
    name: {
      label: 'Name',
      placeholder: 'Enter your name',
    },
    phone: {
      label: 'Phone',
      placeholder: 'Enter your phone number',
    },
    generic: {
      optional: 'optional',
      required: 'This field is required',
    },
  },
  login: {
    welcome: 'Hello! Welcome to Roof Pitch Calculator',
    loginWith: 'Login with',
    loginForm: {
      text: 'Enter your email and password to sign into your account.',
      rememberMe: 'Remember me',
      signIn: 'Sign In',
      dontHaveAnAccount: 'Don’t have an account?',
      forgotPassword: 'Forgot Password?',
    },
    signupForm: {
      signUp: 'Sign up',
      text: 'Create an account in just a few steps with your email and password.',
    },
    passwordRecovery: {
      title: 'Forgot your password?',
      dontWorry: "Don't worry, we got you.",
      enterTheEmail: 'Enter the email address associated with your account.',
      success: {
        title: 'Gotcha!',
        youCanCheck: 'You can check your inbox now.',
        enter:
          'Please, follow the instructions we just sent to you in order to reset your password.',
      },
      fail: {
        oops: 'Oops!',
        somethingWentWrong: 'Something went wrong.',
        reEnter: 'Re-enter your email and please try again.',
      },
    },
    orContinueWith: 'or continue with',
    errorMessages: {
      email: 'E-mail is not valid.',
      password: 'Password must be at least 6 characters long.',
      passwordRepeat: "The passwords don't match.",
    },
  },
  profile: {
    accountDetails: 'Account Details',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    settings: 'Settings',
    language: {
      title: 'Language',
      text: 'Choose your preferred language:',
    },
    notifications: 'Notifications',
    noName: 'No name registered yet.',
    noPassword:
      "You don't have a password. You can update your email once you create one.",
    noPasswordRegistered: 'No password registered yet.',
    setPassword: "You don't have a password. Please create a new one.",
    deleteAccount: {
      title: 'Delete Account',
      text: 'Are you sure you want to delete your Roof Pitch Calculator account?',
      confirm: 'I confirm that I want to delete my account.',
    },
    passwordUpdate: {
      currentPassword: 'Current password',
      newPassword: 'New password',
      newPasswordRepeat: 'Re-enter new password',
      passwordsAreEqual: 'Passwords are equal.',
    },
  },
  flashingProperties: {
    name: 'Name',
    sku: 'SKU',
    pipeSizeIn: 'Min/Max pipe size in inches',
    pipeSizeMm: 'Min/Max pipe size in milimeters',
    baseIn: 'Base in inches',
    baseMm: 'Base in milimeters',
    heightIn: 'Height in inches',
    heightMm: 'Height in milimeters',
    roofPitch: 'Roof pitch',
    siliconeColors: 'Available silicone color(s)',
    epdmColors: 'Available EPDM color(s)',
    isRound: 'Round',
    isSquare: 'Square',
    isMetal: 'Metal',
    isNonMetal: 'Non-metal',
    isStandard: 'Standard',
    isRetrofit: 'Retrofit',
    isEpdm: 'EPDM',
    isSilicone: 'Silicone',
  },
  history: {
    title: 'History',
    noUser: [
      'This feature is available for registered users only. Click',
      'here',
      'to log in.',
    ],
    empty:
      'There is no log to be displayed yet. Start searching for flashings, and your searches will be displayed here.',
  },
  errorHandlerMessages: {
    documentDoesntExit: 'Document does not exist!',
    userNotFound:
      'There is no user record corresponding to this identifier. The user may have been deleted.',
    wrongPassword:
      'The password is invalid or the user does not have a password.',
    userTokenExpired:
      "The user's credential is no longer valid. The user must sign in again.",
    emailAlreadyInUse:
      'The email address is already in use by another account.',
    accountExistsWithDifferentCredential:
      'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.',
    requiresRecentAuth:
      'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
    networkError:
      'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',
    tooManyAttempts:
      'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
  },
}

export default texts
