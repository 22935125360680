import React, { useState } from 'react'
import Generic from '../Generic'
import { PasswordConfirmation } from './styles'
import Input from '../../Input'

const PasswordConfirmationModal = ({ show, toggle, onSubmit, text }) => {
  const [password, setPassword] = useState('')

  return (
    <Generic
      show={show}
      onExited={() => setPassword('')}
      closable
      toggle={toggle}
      onSubmit={() => onSubmit(password)}
      disabled={password.length < 6}
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      <PasswordConfirmation>
        <p>{text}</p>
        <Input
          id='password-confirm'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
        />
      </PasswordConfirmation>
    </Generic>
  )
}

export default PasswordConfirmationModal
