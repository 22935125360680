import styled from 'styled-components'

export const DetailedResultCard = styled.div`
  background-color: #414141;
  border-radius: 4px;
  padding: 24px;
  position: relative;
  max-width: 552px;
  width: 100%;

  @media screen and (min-width: 1440px) {
    max-width: 654px;
    padding: 30px;
  }

  & > button {
    background-color: transparent;
    border: 0;
    user-select: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 24px;

    @media screen and (min-width: 1440px) {
      right: 30px;
    }

    * {
      pointer-events: none;
    }
  }
`

export const Upper = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  padding: 0 12px 18px;

  @media screen and (min-width: 1440px) {
    padding: 0 15px 24px;
  }

  div {
    display: flex;

    &#image-wrapper {
      align-items: center;
      overflow: hidden;
      border-radius: 4px;
      width: 120px;
      height: 112px;
      margin-right: 18px;

      @media screen and (min-width: 1440px) {
        width: 134px;
        height: 125px;
      }

      img {
        width: 100%;
      }
    }

    &#title-sku {
      flex-direction: column;
      justify-content: center;

      #title {
        margin-right: 20px;
        align-items: center;

        svg {
          color: #c4c4c4;
          width: 25px;
          height: 25px;
          fill: ${(props) => (props.isFavorite ? '#C4C4C4' : 'none')};
          cursor: pointer;
        }
      }
    }

    span {
      line-height: 1.1;
      color: #e5e5e5;

      &:nth-child(1) {
        font-weight: 500;
        font-size: 26px;
        margin-right: 20px;
        text-transform: uppercase;

        @media screen and (min-width: 1440px) {
          font-size: 30px;
        }
      }

      &:nth-child(2) {
        font-size: 16px;
        line-height: 2;

        @media screen and (min-width: 1440px) {
          font-size: 20px;
        }
      }
    }
  }
`

const setGridItems = () => {
  let styles = ''
  for (let i = 1; i <= 8; i++) {
    styles += `p:nth-child(${i}) {
            grid-row: ${i % 2 === 0 ? '1 / 2' : '2 / 3'};
        }`
  }
  return styles
}

const setGridItemsMobile = () => {
  let styles = ''
  for (let i = 1; i <= 8; i++) {
    let gridRow = ''
    if (i >= 5) {
      gridRow = i % 2 === 0 ? '3 / 4' : '4 / 5'
    } else {
      gridRow = i % 2 === 0 ? '1 / 2' : '2 / 3'
    }
    styles += `p:nth-child(${i}) {
            grid-row: ${gridRow};
            grid-column: ${i <= 2 || i === 5 || i === 6 ? '1 / 2' : '2 / 3'}
        }`
  }
  return styles
}

export const Under = styled.div`
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 1440px) {
    padding: 10px 15px;
  }

  p {
    font-size: 14px;
    line-height: 2;
    color: #c4c4c4;

    @media screen and (min-width: 1440px) {
      font-size: 16px;
    }
  }

  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    ${setGridItems()}

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
      width: 100%;
      ${setGridItemsMobile()}
    }
  }
`
