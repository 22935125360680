import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { History, Empty } from './styles'
import PageWithResults from '../../components/PageWithResults'
import { store } from '../../store'
import {
  setValue,
  clickToGoToPage,
  setRepeatingSearch,
  setLogCardId,
} from '../../actions'
import { getSearchHistory } from '../../services/firebase/firestore'
import {
  formatPropertyText,
  fixNumber,
  returnLanguageString,
} from '../../utils'

import { i18n } from '../../i18n'

const HistoryComponent = () => {
  const [searches, setSearches] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handlers = {
    content: setSearches,
    loading: setIsLoading,
    getter: getSearchHistory,
  }

  const history = useHistory()

  const Card = ({ query, date, uid }) => {
    const queryObject = { ...query }
    for (const key in queryObject) {
      queryObject[key] = formatPropertyText(queryObject[key])
    }
    const {
      pipeSizeIn,
      pipeSizeMm,
      pipeType,
      roofType,
      roofPitch,
      weatherProtection,
      applicationType,
    } = queryObject
    const defineDate = store.getState().currentUser ? date.toDate() : date
    const formattedDate = new Date(defineDate).toLocaleString(
      returnLanguageString(store.getState().lang),
      {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
    )

    const handleClick = () => {
      for (const key in query) {
        store.dispatch(setValue(key, query[key]))
      }
      store.dispatch(setLogCardId(uid))
      store.dispatch(clickToGoToPage(7))
      store.dispatch(setRepeatingSearch())
      history.push('/')
    }

    return (
      <History onClick={handleClick}>
        <p>{`${pipeSizeIn}in-${fixNumber(
          pipeSizeMm
        )}mm / ${pipeType} / ${roofType} / ${
          roofPitch ?? '0°'
        } / ${weatherProtection} / ${applicationType}`}</p>
        <p>{formattedDate.toLocaleString().replace(',', '')}</p>
      </History>
    )
  }

  const returnContent = () => {
    if (!isLoading) {
      if (!store.getState().currentUser) {
        return (
          <Empty>
            {i18n().history.noUser[0]}{' '}
            <Link to='/signin'>{i18n().history.noUser[1]}</Link>{' '}
            {i18n().history.noUser[2]}
          </Empty>
        )
      }
      return !searches.length ? (
        <Empty>{i18n().history.empty}</Empty>
      ) : (
        searches.map((search) => (
          <Card
            key={search.uid}
            query={search.query}
            date={search.dateAdded}
            uid={search.uid}
          />
        ))
      )
    }
  }

  return (
    <PageWithResults
      title={i18n().history.title}
      numOfResults=''
      isLoading={isLoading}
      handlers={handlers}
      content={searches}
    >
      {returnContent()}
    </PageWithResults>
  )
}

export default HistoryComponent
