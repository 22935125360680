import React, { createContext, useState } from 'react'

export const LoginScreenContext = createContext(true)

export const LoginScreenContextProvider = ({ children }) => {
  const [isOnLoginScreen, setisOnLoginScreen] = useState(true)

  return (
    <LoginScreenContext.Provider
      value={{ isOnLoginScreen, setisOnLoginScreen }}
    >
      {children}
    </LoginScreenContext.Provider>
  )
}
