import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Wrapper } from './styles'

const timeoutValues = { appear: 100, enter: 100, exit: 85 }

const Base = ({ show, onExited, children }) => (
  <CSSTransition
    in={show}
    timeout={timeoutValues}
    mountOnEnter
    unmountOnExit
    classNames='modal-dissolve'
    onExited={onExited}
  >
    <Wrapper>{children}</Wrapper>
  </CSSTransition>
)

export default Base
