import React, { useEffect } from 'react'
import PageWithResultsScroll from '../../components/PageWithResultsScroll'
import { store } from '../../store'
import { i18n } from '../../i18n'
import { showErrorMessage } from '../../utils'
import { useAlgolia } from '../../contexts/AlgoliaSearchContext'
import { setLoading } from '../../actions'

const Search = () => {
  const {
    selectedChips,
    doChipsSearch,
    results,
    hasMoreToShow,
    searchText,
    doTextSearch,
    clearResults,
  } = useAlgolia()

  useEffect(() => {
    store.dispatch(setLoading(true))
    try {
      doChipsSearch()
    } catch (error) {
      showErrorMessage(error)
    }
    store.dispatch(setLoading(false))
  }, [selectedChips])

  const performTextSearch = async () => {
    store.dispatch(setLoading(true))
    try {
      await doTextSearch()
    } catch (error) {
      showErrorMessage(error)
    }
    store.dispatch(setLoading(false))
  }

  useEffect(() => {
    searchText.length && performTextSearch()
  }, [searchText])

  // clear the results when the screen changes:
  useEffect(() => () => clearResults(), [])

  const texts = {
    title: i18n().search.title,
    noMoreResults: i18n().search.noMoreResults,
    noResults: i18n().search.noResults,
  }

  const searchConfig = {
    results,
    next: selectedChips.length ? doChipsSearch : doTextSearch,
    hasMore: hasMoreToShow,
    endMessage: texts.noMoreResults,
    emptyMessage: texts.noResults,
  }

  return <PageWithResultsScroll title={texts.title} config={searchConfig} />
}

export default Search
