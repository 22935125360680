import styled, { css } from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;

  & > svg {
    position: absolute;
    right: 15px;
    bottom: 12px;
    color: #4a4a4a;
    cursor: pointer;
    width: 15px;
    height: 15px;

    @media screen and (max-width: 1280px) {
      right: 11.25px;
      bottom: 9px;
      width: 11.25px;
      height: 11.25px;
    }

    @media screen and (min-width: 1440px) {
      bottom: 17.5px;
    }
  }

  & > span {
    position: absolute;
    bottom: -16px;
    right: 0;
    font-size: 12px;
    color: rgba(238, 79, 79, 0.67);

    @media screen and (max-width: 1280px) {
      bottom: -12px;
      font-size: 9px;
    }
  }
`

export const Label = styled.label`
  font-weight: 300;
  font-size: 15px;
  line-height: 1.1;
  color: #e5e5e5;
  margin-bottom: 10px;

  > span {
    font-size: 10px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 11.25px;
    margin-bottom: 7.5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 10px;
  }
`

const inputFieldCommons = css`
  height: 41px;
  background: #343434;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 300;
  font-size: 13px;
  line-height: 1.1.%;
  color: #e5e5e5;
  padding: 0 20px 0 15px;
  outline: none;
  border: 1px solid;
  border-color: ${(props) =>
    props.hasError ? 'rgba(218, 12, 12, 0.76)' : '#414141'};

  @media screen and (min-width: 1440px) {
    height: 50px;
  }

  @media screen and (max-width: 1280px) {
    height: 30px;
    padding: 0 15px 0 11.25px;
    font-size: 9.75px;
  }

  @media screen and (max-width: 768px) {
    height: 41px;
    padding: 0 20px 0 15px;
    font-size: 13px;
  }

  &::placeholder {
    color: inherit;
    opacity: 0.2;
  }

  &[type='password']::-ms-reveal {
    display: none;
  }

  &:invalid {
    border: 1px solid 'rgba(218, 12, 12, 0.76)';
  }
`

export const InputField = styled.input`
  ${inputFieldCommons}
  // 50 is the sum of current horizontal padding values:
  width: ${(props) =>
    props.sizeAnchor ? `${props.sizeAnchor * 8 + 50}px` : 'auto'};

  @media screen and (max-width: 1280px) {
    width: ${(props) =>
      props.sizeAnchor ? `${props.sizeAnchor * 6 + 37.5}px` : 'auto'};
    /* max-width: 294.75px; */
    min-width: 170px;
  }
`

export const PhoneInputWrapper = styled.div`
  .react-tel-input {
    .flag-dropdown {
      background-color: transparent;
      border: 0;

      .selected-flag {
        width: 45px;
        cursor: pointer;
        border: 1px solid #414141;
        padding-left: 10px;

        &:hover,
        &.open {
          background-color: #2e2e2e;
        }
      }
    }

    > input[type='tel'] {
      ${inputFieldCommons}

      width: 100%;
      padding-left: 56px;
    }

    .country-list {
      margin-top: 0;
      background-color: #3c3b3b;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);
      max-height: 130px;

      li {
        border-top: 1px solid rgba(229, 229, 229, 0.2);

        &.highlight,
        &:hover {
          background-color: #767575;
        }

        .dial-code {
          color: rgba(229, 229, 229, 0.5);
        }
      }
    }
  }
`
